import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { ArrayUtil, ConditionGroup, ConditionValidator } from '@kfd/core';
import { ConfigurationStateService } from './configuration-state.service';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class ConfigurationConditionService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private relevanceChange = new EventEmitter<string>();
  private conditionResult = new Map<string, boolean>();

  constructor(
    private configurationStateService: ConfigurationStateService,
    private configurationService: ConfigurationService,
  ) {
    this.configurationService.onInit().subscribe(() => this.init());
  }

  public ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  init() {
    const fieldBasedConditionGroups = this.configurationService.cfgUtil.getEntriesWithConditions();
    this.subscriptions.map((subscription) => subscription.unsubscribe());
    for (const [name, conditionGroups] of fieldBasedConditionGroups) {
      // set default value (true if no conditions else false)
      this.conditionResult.set(name, conditionGroups.length === 0);

      if (conditionGroups.length > 0) {
        this.subscriptions.push(
          this.onConditionGroupsChange(conditionGroups).subscribe((isRelevant) => {
            if (!isRelevant) {
              this.configurationStateService.disable(name);
            } else {
              this.configurationStateService.enable(name);
            }
            this.conditionResult.set(name, isRelevant);
            this.relevanceChange.emit(name);
          }),
        );
      }
    }
  }

  public onEntryRelevanceChange(name: string): Observable<boolean> {
    // entries without conditions always return true
    if (this.configurationService.cfgUtil.getEntryConditions(name).length === 0) {
      return of(true);
    }
    // track entries with conditions
    return new Observable((observer) => {
      if (this.conditionResult.has(name)) {
        observer.next(this.conditionResult.get(name));
      }
      return this.relevanceChange.subscribe((nameOfChanged: string) => {
        if (name === nameOfChanged) {
          observer.next(this.conditionResult.get(name));
        }
      });
    });
  }

  public validateConditionGroups(conditionGroups: ConditionGroup[]): boolean {
    if (conditionGroups.length === 0) {
      return true;
    }
    const fieldNames = this.configurationService.cfgUtil.getFieldNames();
    const valueMap = this.configurationStateService.getValueMap(fieldNames);
    return ConditionValidator.evaluateGroups(conditionGroups, valueMap);
  }

  private onConditionGroupsChange(conditionGroups: ConditionGroup[]): Observable<boolean> {
    const relatedFields: string[] = ArrayUtil.distinct(
      conditionGroups.flatMap((cg) => cg.conditions.map((con) => con.value1.name)),
    );
    if (!relatedFields) {
      return of(true);
    }
    return this.configurationStateService.onFieldsValueChange(relatedFields).pipe(
      map(() => {
        const valueMap = this.configurationStateService.getValueMap(relatedFields);
        return ConditionValidator.evaluateGroups(conditionGroups, valueMap);
      }),
    );
    return of(true);
  }
}
