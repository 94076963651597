<form (ngSubmit)="updateValue()">
  <ng-container *ngIf="editMode">
    <div class="p-inputgroup">
      <span *ngIf="icon" class="p-inputgroup-addon"><i class="pi {{ icon }}"></i></span>
      <input
        (focusout)="updateValue()"
        [(ngModel)]="_value"
        [id]="inputId"
        [maxLength]="maxLength"
        name="inputValue"
        pInputText
        type="text"
        data-automationId="value-field"
      />
      <button
        (click)="updateValue()"
        icon="pi pi-check"
        pButton
        type="button"
        data-automationId="btn-accept-changes"
      ></button>
    </div>
    <small *ngIf="hint">{{ hint }}</small>
  </ng-container>

  <ng-container *ngIf="!editMode">
    <div class="p-inputgroup">
      <span *ngIf="icon" class="p-inputgroup-addon"><i class="pi {{ icon }}"></i></span>
      <input
        [disabled]="true"
        [id]="inputId"
        [maxLength]="maxLength"
        [ngModel]="_value"
        name="inputValue"
        pInputText
        type="text"
        data-automationId="value-field"
      />
      <button
        (click)="changeEditMode(true)"
        class="p-button-outlined input-outline"
        icon="pi pi-pencil"
        pButton
        type="button"
        data-automationId="btn-edit-values"
      ></button>
    </div>
  </ng-container>
</form>
