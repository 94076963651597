import { JSONPath } from '@astronautlabs/jsonpath';

export interface JsonNode<T> {
  path: string[];
  value: T;
}

export type Path = string | string[];

export class Jsonpath {
  /**
   * returns a single node
   */
  public static node<T>(data: object, path: string | Path): JsonNode<T> | undefined {
    const pathExpression = typeof path === 'string' ? path : Jsonpath.pathToExpression(path);
    const res = Jsonpath.nodes<T>(data, pathExpression);
    return res.length > 0 ? res[0] : undefined;
  }

  /**
   * returns all nodes
   */
  public static nodes<T>(data: object, pathExpression: string): JsonNode<T>[] {
    return JSONPath.nodes(data, pathExpression) as JsonNode<T>[];
  }

  public static paths(data: object, pathExpression: string, maxCount = 10): string[][] {
    return JSONPath.paths(data, pathExpression, maxCount);
  }

  public static query<T = string>(data: object, pathExpression: string): T[] {
    return JSONPath.query(data, pathExpression) as T[];
  }

  public static queryFirst<T = string>(data: object, path: Path): T | undefined {
    const res = this.query<T>(data, this.pathToExpression(path));
    return res.length > 0 ? res[0] : undefined;
  }

  public static pathFromString(input: string, separator = '.'): Path {
    if (!input) {
      return [];
    }
    return input.split(separator);
  }

  public static pathToExpression(path: Path): string {
    if (!Array.isArray(path)) {
      return path;
    }
    path = path.filter((p) => p !== '');
    if (path.length === 0) {
      return '$';
    }
    //we need always a $ at the beginning
    if (path[0] !== '$') {
      path.unshift('$');
    }
    return path.join('.');
  }
}
