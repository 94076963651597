@if (requiredUserAction$ | async; as requiredUserAction) {
  @switch (requiredUserAction) {
    @case (RequiredUserAction.EMAIL_ACTIVATION) {
      <kfd-action-message
        text="Ihre E-Mail Adresse wurde noch nicht bestätigt"
        info="Prüfen Sie Ihre E-Mails und folgen Sie den Anweisungen"
        [actionBtnIcon]="ICON.PROFILE"
        [link]="profileLink"
      >
      </kfd-action-message>
    }
    @case (RequiredUserAction.PRIVACY_ACKNOWLEDGE) {
      <kfd-action-message
        severity="warn"
        text="Unsere Datenschutzerklärung wurden aktualisiert"
        info="Bittes lesen Sie die neuen Datenschutzbestimmungen und bestätigen Sie diese."
        [actionBtnIcon]="ICON.PRIVACY"
        [actionBtnLabel]="'Jetzt lesen'"
        (action)="acceptPrivacy()"
      >
      </kfd-action-message>
    }
  }
}
