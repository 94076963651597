import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

export enum ScreenSize {
  XS = 'xs',
  MD = 'md',
  LG = 'lg',
}

/**
 * Contains system (non-user specific) configuration options
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private readonly breakpointObserver?: BreakpointObserver) {
    // this.isMinTablet$ = this.breakpointObserver.observe('(min-width: 768px)');
  }

  private _embedded = false;

  get embedded(): boolean {
    return this._embedded;
  }

  set embedded(value: boolean) {
    this._embedded = value;
  }

  private _privacyLink: string | undefined;

  get privacyLink(): string | undefined {
    return this._privacyLink;
  }

  set privacyLink(value: string | undefined) {
    this._privacyLink = value;
  }

  /**
   * Sets the screen size manually and disables responsiveness
   */
  private _screenSize: ScreenSize | undefined;

  set screenSize(value: ScreenSize | undefined) {
    this._screenSize = value;
  }

  onScreenSizeChange(): Observable<ScreenSize> {
    if (this._screenSize) {
      return of(this._screenSize);
    }
    if (this.breakpointObserver) {
      return this.breakpointObserver.observe(['(min-width: 768px)', '(min-width: 1024px)']).pipe(
        map((result) => {
          if (result.breakpoints['(min-width: 1024px)']) {
            return ScreenSize.MD;
          }
          if (result.breakpoints['(min-width: 768px)']) {
            return ScreenSize.MD;
          }
          return ScreenSize.XS;
        }),
      );
    }
    return of(ScreenSize.XS);
  }
}
