export enum Service {
  CONTENT_SERVICE = 'content_service',
  CMS_SERVICE = 'cms_service',
  AUTH_SERVICE = 'auth_service',
  CFG_SERVICE = 'cfg_service',
}

export enum App {
  WEB = 'web_app',
  CMS = 'cms_app',
  CFG = 'cfg_app',
}

export enum EndpointMethods {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export interface Endpoint<T> {
  service: Service;
  path: string;
  method: EndpointMethods;
  params?: Record<string, string>;
  response?: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetEndpointResponseType<C extends Endpoint<any>> = C extends Endpoint<infer T> ? T : never;

export class GetEndpoint<ResponseType> implements Endpoint<ResponseType> {
  method: EndpointMethods;

  constructor(
    public readonly service: Service,
    public readonly path: string,
    public readonly response: ResponseType,
  ) {
    this.method = EndpointMethods.GET;
  }
}

export class PostEndpoint<BodyType, ResponseType> implements Endpoint<ResponseType> {
  body!: BodyType;
  method: EndpointMethods;

  constructor(
    public readonly service: Service,
    public readonly path: string,
    public readonly requestBody: BodyType,
    public readonly response: ResponseType,
  ) {
    this.method = EndpointMethods.POST;
  }
}

export class PutEndpoint<BodyType, ResponseType> implements Endpoint<ResponseType> {
  body!: BodyType;
  method: EndpointMethods;

  constructor(
    public readonly service: Service,
    public readonly path: string,
    public readonly requestBody: BodyType,
    public readonly response: ResponseType,
  ) {
    this.method = EndpointMethods.PUT;
  }
}

export class DeleteEndpoint<ResponseType> implements Endpoint<ResponseType> {
  method: EndpointMethods;

  constructor(
    public readonly service: Service,
    public readonly path: string,
    public readonly response: ResponseType,
  ) {
    this.method = EndpointMethods.DELETE;
  }
}
