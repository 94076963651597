<ng-container *ngIf="data">
  <ng-content select="[top]"></ng-content>
  <ng-container *ngFor="let entry of data | keyvalue: unsorted">
    <div class="row">
      <label>
        @if (entry.value.label) {
          {{ entry.value.label }}:
        } @else {
          {{ entry.key }}:
        }
      </label>
      <div
        [attr.data-automationId]="entry.value.automationId ? 'value-' + entry.value.automationId : null"
        [attr.data-valuefor]="entry.key"
        class="value"
      >
        <ng-container *ngIf="entry.value?.type === undefined">
          {{ entry.value }}
        </ng-container>
        <ng-container *ngIf="entry.value?.type !== undefined">
          <ng-container [ngTemplateOutletContext]="{ data: entry.value }" [ngTemplateOutlet]="structuredData">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-content></ng-content>

  <ng-template #structuredData let-data="data">
    <ng-container [ngSwitch]="data.type">
      <ng-container *ngSwitchCase="'number'">
        {{ data.value }}
        @if (data.hint) {
          <i class="pi {{ ICON.INFO }}" [pTooltip]="data.hint"></i>
        }
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        {{ data.value }}
      </ng-container>
      <ng-container *ngSwitchCase="'date'">
        {{ data.value | date: data.format ?? 'short' }}
      </ng-container>
      <ng-container *ngSwitchCase="'url'">
        <div class="type-url">
          <i class="pi pi-link"></i>
          {{ data.value }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'link'">
        <div class="type-link">
          <i class="pi pi-link mr-1"></i>
          <a [routerLink]="data.target">{{ data.text }}</a>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'code'">
        <pre><code>{{ data.value }}</code></pre>
      </ng-container>
      <ng-container *ngSwitchCase="'object'">
        <div [innerHTML]="data.value | prettyPrintJson" class="align-left"></div>
      </ng-container>
      <ng-container *ngSwitchCase="'color'">
        <div class="flex justify-content-end">
          <div>{{ data.value }}</div>
          <div class="type-color ml-2" style="background-color: {{ data.value }}"></div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'array'">
        <ng-container *ngFor="let arrayEntry of data.value">
          <div class="row">
            <label>{{ arrayEntry[data.labelField] }}: </label>
            <div class="value">
              {{ arrayEntry[data.valueField] }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
