import { Component, OnDestroy } from '@angular/core';
import { Configuration, ConfigurationValidator } from '@kfd/core';
import { PageInfo, PaginationService } from '../../service/PaginationService';
import { ConfigurationValidationService } from '../../service/configuration-validation.service';
import { ConfigurationService } from '../../service/configuration.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CFG_ICON } from '../../../globals';

@Component({
  selector: 'kfd-cfg-layout-page',
  templateUrl: './cfg-layout-page.component.html',
  styleUrls: ['./cfg-layout-page.component.scss'],
})
export class CfgLayoutPageComponent implements OnDestroy {
  public pageInfo$: Observable<PageInfo | undefined> | undefined;

  protected readonly CFG_ICON = CFG_ICON;

  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly paginationService: PaginationService,
    private readonly configurationValidationService: ConfigurationValidationService,
    readonly configurationService: ConfigurationService,
  ) {
    configurationService
      .onCfgChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((cfgUtil) => {
        this.configuration = cfgUtil.getCfg();
        this.pageInfo$ = this.paginationService.pageChange;
        this.paginationService.next();
      });
  }

  private _configuration: Configuration | undefined;

  get configuration(): Configuration | undefined {
    return this._configuration;
  }

  set configuration(value: Configuration | undefined) {
    this._configuration = value;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  previous() {
    this.paginationService.previous();
  }

  next(pageInfo: PageInfo) {
    if (!this.allFieldsValid(pageInfo)) {
      return;
    }
    this.paginationService.next();
  }

  allFieldsValid(pageInfo: PageInfo): boolean {
    const validationResults = this.configurationValidationService.validatePage(pageInfo.currentPage.name);
    return ConfigurationValidator.errorCount(validationResults) === 0;
  }
}
