import { EventEmitter, Injectable } from '@angular/core';
import { CfgUtil, Configuration } from '@kfd/core';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigurationService {
  private configurationSet = new EventEmitter<Configuration>();

  private _cfgUtil: CfgUtil<Configuration> | undefined;

  public get cfgUtil(): CfgUtil<Configuration> {
    if (this._configuration === undefined) {
      throw new Error('Missing configuration');
    }
    if (!this._cfgUtil) {
      this._cfgUtil = CfgUtil.create<Configuration>(this._configuration);
      this._cfgUtil.initialize();
      return this._cfgUtil;
    }
    this._cfgUtil.updateCfg(this._configuration);
    return this._cfgUtil;
  }

  public set cfgUtil(cfgUtil: CfgUtil | undefined) {
    if (cfgUtil === undefined) {
      return;
    }
    this._cfgUtil = cfgUtil;
    this._configuration = cfgUtil.getCfg();
    this.configurationSet.emit();
  }

  private _configuration: Configuration | undefined;

  public get configuration(): Configuration | undefined {
    return this._configuration;
  }

  public set configuration(value: Configuration | undefined) {
    if (value === undefined) {
      return;
    }
    this._configuration = value;
    this._cfgUtil = CfgUtil.create<Configuration>(this._configuration);
    this._cfgUtil.initialize();
    this.configurationSet.emit();
  }

  public onCfgChange(): Observable<CfgUtil> {
    return new Observable<CfgUtil>((subscriber) => {
      if (this._configuration !== undefined) {
        subscriber.next(this.cfgUtil);
      }
      this.configurationSet.subscribe(() => {
        subscriber.next(this.cfgUtil);
      });
    });
  }

  /**
   * @deprecated use onCfgChange instead
   */
  public onInit(): Observable<Configuration> {
    return new Observable<Configuration>((subscriber) => {
      if (this._configuration !== undefined) {
        subscriber.next(this._configuration);
      }
      this.configurationSet.subscribe(() => {
        subscriber.next(this._configuration);
      });
    });
  }
}
