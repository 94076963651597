<p-button
  (click)="op.toggle($event)"
  [outlined]="true"
  [rounded]="true"
  icon="pi {{ icon.DOCU }}"
  pTooltip="Hilfe / Dokumentation"
></p-button>
<p-overlayPanel #op appendTo="body" styleClass="growable footer large-content">
  <ng-template pTemplate>
    <div>
      <ng-container *ngIf="webPage$ | async as webPage">
        <kfd-page-content [page]="webPage" [sectionId]="sectionId" [showToc]="false"></kfd-page-content>
        <a *ngIf="webPage.id" [href]="webApp + '/support/documentation/' + webPage.id" target="_blank">
          <button
            class="footer-button-right p-button-outlined p-button-rounded"
            icon="pi {{ icon.LINK_EXTERNAL }}"
            pButton
            pTooltip="In Dokumentation anzeigen"
            tooltipPosition="left"
            type="button"
          ></button>
        </a>
      </ng-container>
    </div>
  </ng-template>
</p-overlayPanel>
