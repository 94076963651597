export class Key {
  constructor(...parts: string[]) {
    this._parts = parts;
  }

  private _parts: string[] = [];

  get parts(): string[] {
    return this._parts;
  }

  set parts(value: string[]) {
    this._parts = value;
  }

  public static fromString(value: string | null): Key {
    const parts = value !== null ? value.split('.') : [];
    const key = new Key();
    key.parts = parts;
    return key;
  }

  public match(key: Key): boolean {
    if (this.parts.length < key.parts.length) {
      return false;
    }
    for (const index in key.parts) {
      if (this.parts[index] !== key.parts[index]) {
        return false;
      }
    }

    return true;
  }

  public removeFirst(): Key {
    this._parts.shift();
    return this;
  }

  public prepend(key: Key): Key {
    //avoid adding the key multiple times
    if (this.toString().includes(key.toString())) {
      return this;
    }
    this._parts = key._parts.concat(this._parts);
    return this;
  }

  public toString(): string {
    return this._parts.join('.');
  }
}
