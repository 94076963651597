import {
  Calculation,
  CfgUtil,
  Create,
  DisplayDataValues,
  FieldRef,
  FieldRefResolver,
  FormValue,
  getSelectionDataValue,
  Is,
} from '@kfd/core';
import { ConfigurationStateService } from './configuration-state.service';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class ConfigurationFieldRefResolver implements FieldRefResolver {
  private cfgUtil: CfgUtil | undefined;

  constructor(
    private configurationService: ConfigurationService,
    private stateService: ConfigurationStateService,
  ) {
    this.configurationService.onInit().subscribe(() => this.init());
  }

  private static emptyResult(): Promise<FormValue> {
    return Promise.resolve(Create.emptyFormValue());
  }

  init() {
    this.cfgUtil = this.configurationService.cfgUtil;
  }

  resolve(fieldRef: FieldRef): Promise<FormValue | Calculation> {
    if (!this.stateService) {
      throw new Error('Missing state service');
    }

    if (!this.cfgUtil) {
      throw new Error('Missing configuration');
    }

    const configuratorField = this.cfgUtil.getFieldByName(fieldRef.name);
    if (!configuratorField) {
      return ConfigurationFieldRefResolver.emptyResult();
    }

    if (Is.displayDataField(configuratorField)) {
      const value: DisplayDataValues = configuratorField.config.value;
      if (Is.fieldRef(value)) {
        return this.resolve(value);
      }
      if (Is.inputValue(value)) {
        return Promise.resolve(Create.singleFormValue(value));
      }
      throw new Error(`Unsupported value type for display data "${value.cls}"`);
    }

    if (!this.stateService.hasValue(fieldRef.name)) {
      return ConfigurationFieldRefResolver.emptyResult();
    }

    const formValue = this.stateService.getValue(fieldRef.name);
    if (!formValue) {
      throw new Error('Missing value');
    }

    if (Is.singleSelectionFormValue(formValue) && fieldRef.property) {
      return Promise.resolve(Create.singleFormValue(getSelectionDataValue(formValue.selection, fieldRef.property)));
    }

    return Promise.resolve(formValue);
  }
}
