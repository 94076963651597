import {
  Calculation,
  CfgUtil,
  Configuration,
  Create,
  DisplayDataValues,
  FieldRef,
  FieldRefResolver,
  FormValue,
  FormValueMap,
  Is,
} from '../../index';

export class ConfigurationFieldRefResolver implements FieldRefResolver {
  constructor(
    private cfgUtil: CfgUtil<Configuration>,
    private formValueMap: FormValueMap,
  ) {}

  resolve(fieldRef: FieldRef): Promise<FormValue | Calculation> {
    const field = this.cfgUtil.getFieldByName(fieldRef.name);
    if (!field) {
      throw new Error(`Could not find field ref with name "${fieldRef.name}"`);
    }

    if (Is.displayDataField(field)) {
      const value: DisplayDataValues = field.config.value;
      if (Is.fieldRef(value)) {
        return this.resolve(value);
      }
      if (Is.inputValue(value)) {
        return Promise.resolve(Create.singleFormValue(value));
      }
      if (Is.calculation(value)) {
        return Promise.resolve(value);
      }
      throw new Error(`Unsupported value type for display data`);
    }

    if (!this.formValueMap.has(fieldRef.name)) {
      return Promise.resolve(Create.emptyFormValue());
    }

    const formValue = <FormValue>this.formValueMap.get(fieldRef.name);
    return Promise.resolve(formValue);
    // if (Is.selectField(field)) {
    //     if(Is.multiSelectField(field)) {
    //         if(Is.multiSelectionFormValue(formValue)) {
    //             const data = ArrayUtil.filterUndefined(formValue.selection.map((selectionData) => getSelectionDataValue(selectionData, fieldRef.property)))
    //             const resultFormValue = Create.multiSelectionFormValue(data)
    //             return Promise.resolve();
    //         }
    //     } else {
    //
    //     }
    // }
  }
}
