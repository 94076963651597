@if (editMode) {
  @if (form) {
    <form (keyup.enter)="saveValue()" (ngSubmit)="saveValue()" [formGroup]="form">
      <div
        class="p-inputgroup"
        [ngClass]="{ valid: form.get('value')?.valid, invalid: form.get('value')?.dirty && form.get('value')?.invalid }"
      >
        @if (icon) {
          <div class="p-inputgroup-addon active">
            <i class="pi {{ icon }}"></i>
          </div>
        }
        <p-button
          icon="pi {{ ICON.RESET }}"
          data-automationId="btn-reset-changes"
          styleClass="input-group-button"
          [rounded]="true"
          [outlined]="true"
          [disabled]="form.get('value')?.value === originValue"
          (click)="resetValue()"
        ></p-button>
        <input
          formControlName="value"
          name="inputValue"
          pInputText
          type="text"
          data-automationId="value-field"
          [min]="minLength"
          [max]="maxLength"
        />
        <p-button
          icon="pi {{ ICON.ACCEPT }}"
          data-automationId="btn-accept-changes"
          styleClass="input-group-button-end"
          [rounded]="true"
          [outlined]="true"
          (click)="saveValue()"
        ></p-button>
      </div>
      @if (validatorErrorMsg) {
        <small class="p-error">{{ validatorErrorMsg }}</small>
      } @else if (hint) {
        <small>{{ hint }}</small>
      }
    </form>
  }
} @else {
  <div class="p-inputgroup" [ngClass]="{ invalid: invalid }">
    @if (icon) {
      <div class="p-inputgroup-addon"><i class="pi {{ icon }}"></i></div>
    }
    <input
      [disabled]="true"
      [ngModel]="value"
      name="inputValue"
      pInputText
      type="text"
      data-automationId="value-field-readonly"
    />
    <p-button
      icon="pi {{ ICON.EDIT }}"
      data-automationId="btn-edit-values"
      styleClass="input-group-button-end"
      [rounded]="true"
      [outlined]="true"
      (click)="changeEditMode(true)"
    ></p-button>
  </div>
  @if (validatorErrorMsg) {
    <small class="p-error">{{ validatorErrorMsg }}</small>
  }
}
