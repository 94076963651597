<div *ngIf="fieldConfig" class="input-wrapper">
  <p-selectButton
    (onChange)="onInputValueChange($event)"
    [ngModel]="inputFieldValue"
    [options]="options"
    optionLabel="label"
    optionValue="value"
    styleClass="full-width"
  >
    <ng-template let-item pTemplate>
      <div [attr.data-value]="'bool-value-' + item.value" class="flex-1">
        <i [class]="item.icon"></i>
        <span *ngIf="item.label && item.label.length > 0" class="label ml-2">{{ item.label }}</span>
      </div>
    </ng-template>
  </p-selectButton>
</div>
