@if (isMinTablet$ | async; as isMinTablet) {
  @if (items && items.length > 0) {
    @if (isMinTablet.matches || items.length <= 3) {
      @for (item of items; track item.label) {
        @if (item.separator) {
          <div class="separator ml-2"></div>
        } @else {
          @if (item.routerLink) {
            <ng-container *ngTemplateOutlet="link"></ng-container>
          } @else {
            <ng-container *ngTemplateOutlet="button"></ng-container>
          }
        }

        <ng-template #link>
          <a [routerLink]="item.routerLink">
            <ng-container *ngTemplateOutlet="button"></ng-container>
          </a>
        </ng-template>

        <ng-template #button>
          <p-button
            class="ml-2"
            tooltipPosition="bottom"
            [outlined]="true"
            [rounded]="true"
            [disabled]="item.disabled"
            [icon]="item.icon"
            [pTooltip]="item.tooltip ?? item.label"
            [attr.data-automationId]="item.automationId"
            (click)="item.command ? item.command({ originalEvent: $event, item, index: $index }) : undefined"
          >
          </p-button>
        </ng-template>
      }
      @if (items.length > 0 && endSeparator) {
        <div class="separator ml-2"></div>
      }
    } @else {
      <p-button
        [outlined]="true"
        [rounded]="true"
        (click)="headerMenu.toggle($event)"
        class="ml-2"
        data-automationId="btn-header-toolbar-menu"
        icon="pi {{ ICON.MENU }}"
      ></p-button>
      <p-menu #headerMenu [model]="items" [popup]="true"></p-menu>
    }
  }
}
