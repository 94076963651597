import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICON } from '@kfd/core';

@Component({
  selector: 'kfd-action-message',
  templateUrl: './action-message.component.html',
  styleUrl: './action-message.component.scss',
})
export class ActionMessageComponent {
  @Output()
  public action = new EventEmitter<void>();

  @Input()
  public size: 'small' | 'normal' | 'large' = 'normal';

  @Input()
  public severity: string = 'info';

  @Input()
  public text: string | undefined;

  @Input()
  public info: string | undefined;

  @Input()
  public hideActionBtn = false;

  @Input()
  public actionBtnIcon: ICON = ICON.INFO;

  @Input()
  public actionBtnLabel: string | undefined;

  @Input()
  public actionBtnTooltip: string = '';

  @Input()
  public link: string | undefined;
}
