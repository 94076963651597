export enum CFG_APP_ROUTE_PARAMS {
  PROJECT_ID = 'projectId',
  CONFIGURATION_ID = 'configurationId',
  REQUEST_ID = 'requestId',
  CODE = 'code',
}

export enum CFG_APP_ROUTE_PARTS_ROOT {
  PROJECT = 'project',
}

const PROJECT_ROOT = `/${CFG_APP_ROUTE_PARTS_ROOT.PROJECT}/:${CFG_APP_ROUTE_PARAMS.PROJECT_ID}`;
const CFG_ROOT = `${PROJECT_ROOT}/form`;
const REQUEST_ROOT = `${PROJECT_ROOT}/request`;
export const CFG_APP_ROUTES = {
  root: `/`,
  project: {
    configuration: {
      form: `${CFG_ROOT}/:${CFG_APP_ROUTE_PARAMS.CONFIGURATION_ID}`,
      previewForm: `${CFG_ROOT}/:${CFG_APP_ROUTE_PARAMS.CONFIGURATION_ID}/preview`,
    },
    request: {
      configuration: `${REQUEST_ROOT}/:${CFG_APP_ROUTE_PARAMS.CONFIGURATION_ID}`,
      details: `${REQUEST_ROOT}/:${CFG_APP_ROUTE_PARAMS.CONFIGURATION_ID}/:${CFG_APP_ROUTE_PARAMS.REQUEST_ID}`,
      detailsWithCode: `${REQUEST_ROOT}/:${CFG_APP_ROUTE_PARAMS.CONFIGURATION_ID}/:${CFG_APP_ROUTE_PARAMS.REQUEST_ID}/:${CFG_APP_ROUTE_PARAMS.CODE}`,
    },
  },
};
