import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { DocuStructure, FaqPage, Service, WebPage } from '@kfd/core';
import { BaseHttpService } from './base.http.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private httpService: BaseHttpService;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
    this.httpService = new BaseHttpService(httpClient, this.configService.getService(Service.CONTENT_SERVICE));
  }

  getPage(identifier: string): Observable<WebPage> {
    return this.httpService.get<WebPage>(`/data/page/${identifier}`);
  }

  getDocuStructure(): Observable<DocuStructure> {
    return this.httpService.get<DocuStructure>(`/data/docu/structure`);
  }

  getDocuPage(identifier: string): Observable<WebPage> {
    return this.httpService.get<WebPage>(`/data/docu/page/${identifier}`);
  }

  getFaqPage(): Observable<FaqPage> {
    return this.httpService.get<FaqPage>(`/data/faq`);
  }
}
