import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AuthService } from '../../lib/services';
import { COMMON_WEB_APP_ROUTES, ICON, unvTimeout } from '@kfd/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'kfd-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnDestroy {
  @Output()
  public loginSuccess = new EventEmitter<boolean>();
  @Output()
  public pageChange = new EventEmitter<string>();
  @Input()
  public emitLogin = true;
  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;
  protected readonly ICON = ICON;
  protected loading = false;
  protected signInFailed: 'error' | 'failed' | 'inactive' | undefined;
  protected signInSuccess = false;
  protected email = '';
  protected password = '';
  private destroy$ = new Subject<boolean>();

  constructor(private authService: AuthService) {
    this.authService
      .onSignInChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((signedIn) => {
        this.signInSuccess = signedIn;
        this.loginSuccess.emit(signedIn);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  signIn(): void {
    this.loading = true;
    this.signInFailed = undefined;
    this.signInSuccess = false;
    this.authService.signIn(this.email, this.password, this.emitLogin).subscribe({
      next: () => {
        this.signInSuccess = true;
        this.loading = false;
        unvTimeout(() => {
          this.loginSuccess.emit(true);
        }, 1000);
      },
      error: (e) => {
        if (e.status > 500) {
          this.signInFailed = 'error';
        } else if (e.error?.message === 'User is not activated') {
          this.signInFailed = 'inactive';
        } else {
          this.signInFailed = 'failed';
        }
        this.loading = false;
      },
    });
  }
}
