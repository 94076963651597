export const CONTEXT_SERVICE = 'ContextService';

export interface ContextService {
  set projectId(value: string);

  get projectId(): string;

  set configuratorId(value: string);

  get configuratorId(): string;
}
