@if (fieldConfig) {
  <div class="text-field-wrapper">
    @if (!fieldConfig.multiline) {
      <div class="p-inputgroup">
        <div *ngIf="icon" class="p-inputgroup-addon">
          <i class="pi {{ icon }}"></i>
        </div>
        <input
          #singleLineTextInputField
          (change)="onInputValueChange(singleLineTextInputField.value)"
          [defaultValue]="fieldConfig.default?.value"
          [disabled]="fieldConfig.disabled === true"
          [maxLength]="fieldConfig!.max!.value"
          [minLength]="fieldConfig!.min!.value"
          [ngModel]="inputFieldValue"
          [type]="fieldType"
          pInputText
        />
      </div>
    }
    <kfd-textarea
      (valueChange)="onInputValueChange($event)"
      *ngIf="fieldConfig.multiline"
      [disabled]="fieldConfig.disabled === true"
      [maxLength]="fieldConfig!.max!.value"
      [minLength]="fieldConfig!.min!.value"
      [value]="inputFieldValue"
    >
    </kfd-textarea>
    @if (fieldConfig?.max?.value && fieldConfig!.max!.value < 99999 && inputFieldValue.length > 0) {
      <small class="count"> {{ inputFieldValue.length }} / {{ fieldConfig!.max!.value }} </small>
    }
  </div>
}
