import { ObjectUtil } from '../common/object-util';

export interface JsonSchema {
  bsonType?: string;
  type?: string;
  required?: string[];
  properties?: Record<string, unknown>;
  additionalProperties?: boolean;
  anyOf?: JsonSchema[];
  oneOf?: JsonSchema[];
}

export enum TYPES {
  DOUBLE = 'double',
  STRING = 'string',
  OBJECT = 'object',
  ARRAY = 'array',
  OBJECTID = 'objectId',
  BOOL = 'bool',
  DATE = 'date',
  NULL = 'null',
  REGEX = 'regex',
  INT = 'int',
  NUMBER = 'number',
  TIMESTAMP = 'timestamp',
  LONG = 'long',
  DECIMAL = 'decimal',
  MINKEY = 'minKey',
  MAXKEY = 'maxKey',
}

export type BsonType = {
  bsonType: TYPES | TYPES[];
};
export type Type = {
  type: TYPES | TYPES[];
};
export type Enum = {
  enum: unknown[];
};
export type AnyOf = {
  anyOf: TYPES[] | JsonSchema[];
};
export type Items = Type | BsonType | AnyOf | JsonSchema | Enum;

export class SchemaUtil {
  public static type(type: TYPES | TYPES[]) {
    return {
      bsonType: type,
    };
  }

  public static array(items: Items) {
    return {
      bsonType: TYPES.ARRAY,
      items,
    };
  }

  public static string({ minLength = 0, maxLength = 50, pattern = '' } = {}) {
    return ObjectUtil.filterUnset({
      bsonType: TYPES.STRING,
      minLength,
      maxLength,
      pattern,
    });
  }

  public static bool() {
    return {
      bsonType: TYPES.BOOL,
    };
  }

  public static numeric({ minimum = 0, maximum = 99999 } = {}) {
    return ObjectUtil.filterUnset({
      bsonType: TYPES.NUMBER,
      minimum,
      maximum,
    });
  }

  public static int() {
    return ObjectUtil.filterUnset({
      bsonType: TYPES.INT,
    });
  }

  public static date() {
    return {
      bsonType: TYPES.DATE,
    };
  }

  public static objectId() {
    return {
      bsonType: TYPES.OBJECTID,
    };
  }

  /**
   * value has to be an enum
   */
  public static enum(value: object | string[]): Enum {
    // console.log(typeof value)
    if (typeof value === 'object') {
      return {
        enum: Object.values(value),
      };
    }
    if (Array.isArray(value)) {
      return {
        enum: value,
      };
    }
    throw new Error('Enum value has to be an object or an array');
  }

  public static object(required: string[] | undefined, properties: Record<string, unknown>): JsonSchema {
    let schema = {
      bsonType: TYPES.OBJECT,
      additionalProperties: false,
      properties: ObjectUtil.filterUnset(properties),
    };
    if (required !== undefined) {
      schema = ObjectUtil.setValue(schema, 'required', required);
    }
    return schema;
  }
}
