import {
  AliasValue,
  CalcOperation,
  Calculation,
  CalculationResult,
  CLS,
  DATA_VALUE_TYPE,
  FunctionCommand,
  Is,
  NumericInputValue,
  Value,
  ValueCalcParam,
  ValueCalculation,
} from '../../dtos/index';
import dayjs from 'dayjs';
import { NumberUtil } from '../../common';

const ALLOWED_DATA_VALUE_TYPES = [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE];

/**
 * Class to calculate a calculation
 * For any other calculation related stuff use CalculationHandler
 * Use CalculationResolver to resolve a calculation before using this class
 */
export class Calculator {
  aliasValues: Map<string, number> = new Map();

  /**
   * Calculate a ValueCalculation which means a calculation where all params are already resolved to a value
   */
  public calc(calculation: ValueCalculation): CalculationResult {
    this.validate(calculation);
    const result = this.calculateParams(calculation);

    if (result.value && calculation.fn && Is.calculationFunction(calculation.fn)) {
      switch (calculation.fn.command) {
        case FunctionCommand.ROUND:
          // eslint-disable-next-line
          const digits =
            calculation.fn.params && calculation.fn.params.length > 0
              ? parseInt(calculation.fn.params[0].toString())
              : 0;
          result.value = NumberUtil.round(result.value, digits);
          break;
        case FunctionCommand.FLOOR:
          result.value = Math.floor(result.value);
          break;
        case FunctionCommand.CEIL:
          result.value = Math.ceil(result.value);
          break;
        case FunctionCommand.ABS:
          result.value = Math.abs(result.value);
          break;
        default:
          throw new Error('Unsupported calculation function command ' + calculation.fn.command);
      }
    }
    return result;
  }

  private calculateParams(calculation: ValueCalculation): CalculationResult {
    const calculationResult: CalculationResult = {
      cls: CLS.CALCULATION_RESULT,
      results: [],
      label: undefined,
      value: undefined,
    };

    calculation.params.every((valueCalcParam: ValueCalcParam) => {
      const tmpResult = this.paramToCalculationResult(valueCalcParam);
      //continue for excluded results
      if (tmpResult.exclude) {
        return true;
      }
      //if a value is not set we skip without results
      if (tmpResult.value === undefined) {
        calculationResult.results = [];
        calculationResult.value = undefined;
        return false;
      }
      calculationResult.results.push(tmpResult);

      //the first time result will be used as calculation base
      if (calculationResult.value === undefined) {
        calculationResult.value = tmpResult.value;
        return true;
      }

      switch (calculation.op) {
        case CalcOperation.ADD:
          calculationResult.value += tmpResult.value;
          break;
        case CalcOperation.MUL:
          calculationResult.value *= tmpResult.value;
          break;
        case CalcOperation.SUB:
          calculationResult.value -= tmpResult.value;
          break;
        case CalcOperation.DIV:
          if (tmpResult.value === 0) {
            throw new Error('Division by zero');
          }
          calculationResult.value /= tmpResult.value;
          break;
        case CalcOperation.DATEDIFF:
          if (!tmpResult.value) {
            throw new Error('No date given');
          }
          // eslint-disable-next-line no-case-declarations
          const date1 = dayjs(tmpResult.value);
          // eslint-disable-next-line no-case-declarations
          const date2 = dayjs(calculationResult.value);

          if (!date1.isValid() || !date2.isValid()) {
            throw new Error('Invalid date format');
          }
          // eslint-disable-next-line no-case-declarations
          const diff = date1.diff(date2, 'd');
          calculationResult.value = diff;
          break;
        default:
          throw new Error('Unsupported calculation operation ' + calculation.op);
      }
      return true;
    });

    return calculationResult;
  }

  private paramToCalculationResult(param: ValueCalcParam): CalculationResult {
    let tmpResult: CalculationResult = {
      cls: CLS.CALCULATION_RESULT,
      results: [],
      label: undefined,
      value: undefined,
    };

    if (Is.calculation(param)) {
      const subCalculation = param as Calculation;
      tmpResult = this.calc(subCalculation as ValueCalculation);
      if (subCalculation.alias) {
        if (tmpResult.value !== undefined) {
          this.aliasValues.set(subCalculation.alias, tmpResult.value);
        }
        // exclude alias value from calculation
        if (subCalculation.excludeFromResult) {
          tmpResult.value = undefined;
          tmpResult.exclude = true;
        }
      }

      if (subCalculation.label) {
        tmpResult.label = subCalculation.label;
      }
    }

    if (Is.aliasValue(param)) {
      const aliasValue = param as AliasValue;
      if (!this.aliasValues.has(aliasValue.name)) {
        throw new Error('Alias does not exist: ' + aliasValue.name);
      }
      tmpResult.value = this.aliasValues.get(aliasValue.name);

      if (aliasValue.label) {
        tmpResult.label = aliasValue.label;
      }
    }

    if (Is.value(param)) {
      if (param.type === DATA_VALUE_TYPE.EMPTY) {
        return tmpResult;
      }
      if (ALLOWED_DATA_VALUE_TYPES.indexOf((param as Value).type) === -1) {
        throw new Error(`Invalid value type "${(param as Value).type}"`);
      }
      const dataValue = param as NumericInputValue;
      tmpResult.value = dataValue.value;
      if (dataValue.label) {
        tmpResult.label = dataValue.label;
      }
    }

    return tmpResult;
  }

  /**
   * for outside validation use CalculationHandler instead
   * @param calculation
   */
  private validate(calculation: Calculation) {
    if (!calculation.params || calculation.params.length === 0) {
      throw new Error('Empty params are not allowed');
    }

    for (const param of calculation.params) {
      if (Is.calculation(param)) {
        this.validate(param as Calculation);
        continue;
      }

      if (Is.aliasValue(param)) {
        continue;
      }

      if (!Is.value(param)) {
        throw new TypeError('Unresolved reference ' + JSON.stringify(param));
      }
    }
  }
}
