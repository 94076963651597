import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../lib/services/auth.service';
import { COMMON_WEB_APP_ROUTES, ICON } from '@kfd/core';
import { ClientInputError } from '../../lib/common/exceptions';

@Component({
  selector: 'kfd-send-verification',
  templateUrl: './send-verification.component.html',
  styleUrl: './send-verification.component.scss',
})
export class SendVerificationComponent implements OnInit {
  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;
  protected readonly ICON = ICON;
  protected errorText: string | undefined;
  protected infoText: string | undefined;
  protected success = false;
  protected loading = false;
  protected email: string | undefined;
  protected password: string | undefined;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
  ) {}

  ngOnInit() {
    this.email = this.route.snapshot.paramMap.has('email')
      ? (this.route.snapshot.paramMap.get('email') as string)
      : undefined;
  }

  protected resendActivation() {
    if (!this.email || !this.password) {
      return;
    }
    this.errorText = this.infoText = undefined;
    this.loading = true;
    this.authService.resendActivationMail(this.email, this.password).subscribe({
      next: (success) => {
        if (success) {
          this.infoText = 'Die Bestätigungs-E-Mail wurde versendet';
          this.success = true;
        } else {
          this.errorText = 'Die Bestätigungs E-Mail konnte nicht versendet werden';
        }
        this.loading = false;
      },
      error: (err) => {
        if (err instanceof ClientInputError) {
          this.errorText = 'Bitte prüfen Sie Ihre Eingaben und versuchen Sie es erneut';
          this.loading = false;
          return;
        } else {
          this.errorText = 'Ein unerwarteter Fehler ist aufgetreten';
          this.loading = false;
          throw err;
        }
      },
    });
  }
}
