import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../lib/services';
import { ClientInputError } from '../../lib/common';
import { COMMON_WEB_APP_ROUTES, ICON } from '@kfd/core';

@Component({
  selector: 'kfd-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit {
  protected email: string | undefined;
  protected code: string | undefined;

  protected errorText: string | undefined;
  protected infoText: string | undefined;
  protected success = false;
  protected loading = false;
  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;
  protected readonly ICON = ICON;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
  ) {}

  ngOnInit() {
    this.email = this.route.snapshot.paramMap.has('email')
      ? (this.route.snapshot.paramMap.get('email') as string)
      : undefined;
    this.code = this.route.snapshot.paramMap.has('code')
      ? (this.route.snapshot.paramMap.get('code') as string)
      : undefined;
  }

  protected activate() {
    if (!this.email || !this.code) {
      return;
    }
    this.errorText = this.infoText = undefined;
    this.loading = true;
    this.authService.emailValidate(this.email, this.code).subscribe({
      next: (success) => {
        if (success) {
          this.success = true;
        } else {
          this.errorText = 'Die E-Mail Adresse konnte nicht aktiviert werden';
        }
        this.loading = false;
      },
      error: (error) => {
        if (error instanceof ClientInputError) {
          this.errorText = `Bei der Aktivierung ist ein Fehler aufgetreten: ${error.message}`;
        } else {
          this.errorText = 'Ein unerwarteter Fehler ist aufgetreten';
        }
        this.loading = false;
      },
    });
  }
}
