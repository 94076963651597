import { GetEndpoint, PostEndpoint, Service } from './endpoints';
import {
  InsertResult,
  LoginRequestBody,
  LoginResponseData,
  RefreshTokenResponseData,
  RegisterRequestBody,
  REQ_PARAMS,
  ResendActivationMailRequestBody,
  ScopedUser,
} from '../dtos';

export enum AUTH_CTRL_EP {
  AUTH = 'auth',
}

export const authServiceEP = {
  auth: {
    login: new PostEndpoint<LoginRequestBody, LoginResponseData>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/login`,
      null as unknown as LoginRequestBody,
      null as unknown as LoginResponseData,
    ),
    logout: new PostEndpoint<void, void>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/logout`,
      null as unknown as void,
      null as unknown as void,
    ),
    refresh: new PostEndpoint<void, RefreshTokenResponseData>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/refresh`,
      null as unknown as void,
      null as unknown as RefreshTokenResponseData,
    ),
    userExists: new GetEndpoint<boolean>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/users/exists/:${REQ_PARAMS.EMAIL}`,
      null as unknown as boolean,
    ),
    register: new PostEndpoint<RegisterRequestBody, InsertResult>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/register`,
      null as unknown as RegisterRequestBody,
      null as unknown as InsertResult,
    ),
    resendActivationMail: new PostEndpoint<ResendActivationMailRequestBody, boolean>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/register/resend-activation-mail`,
      null as unknown as ResendActivationMailRequestBody,
      null as unknown as boolean,
    ),
    currentUser: new GetEndpoint<ScopedUser>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/user`,
      null as unknown as ScopedUser,
    ),
    csrfToken: new GetEndpoint<string>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/csrf-token`,
      null as unknown as string,
    ),
    validateEmailByCode: new PostEndpoint<void, boolean>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/validate-email/:${REQ_PARAMS.EMAIL}/:${REQ_PARAMS.KEY}`,
      null as unknown as void,
      null as unknown as boolean,
    ),
  },
};
