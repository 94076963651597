<ng-container *ngIf="page">
  <h1 *ngIf="showTitle && !sectionId">{{ page.title }}</h1>

  <div *ngIf="!sectionId && showToc === true && page.content.length > 3" class="page-structure">
    <div class="ml-2">Auf dieser Seite:</div>
    <ol class="m-2">
      <ng-container *ngFor="let content of page.content">
        <a [fragment]="content.id" [routerLink]="'.'">
          <li *ngIf="content.title">{{ content.title }}</li>
        </a>
      </ng-container>
    </ol>
  </div>
  <div class="page-content">
    <ng-container *ngFor="let content of page.content">
      <ng-container *ngIf="!sectionId || sectionId === content.id">
        <ng-container [ngSwitch]="content.type">
          <ng-template
            *ngSwitchCase="'content_section'"
            [ngTemplateOutletContext]="{ content: content }"
            [ngTemplateOutlet]="contentSection"
          >
          </ng-template>
          <ng-template
            *ngSwitchCase="'html'"
            [ngTemplateOutletContext]="{ content: content }"
            [ngTemplateOutlet]="htmlSection"
          >
          </ng-template>
          <ng-template
            *ngSwitchCase="'cffg'"
            [ngTemplateOutletContext]="{ data: content.data }"
            [ngTemplateOutlet]="tplConfiguration"
          >
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #htmlSection let-content="content">
  <div [innerHTML]="content.data"></div>
</ng-template>
<ng-template #contentSection let-content="content">
  <h2 *ngIf="content.data.title" [attr.id]="content.id">
    <i class="pi pi-chevron-right"></i>
    {{ content.data.title }}
    <a [fragment]="content.id" [routerLink]="'.'"><i class="pi pi-link"></i></a>
  </h2>
  <div [innerHTML]="content.data.content"></div>
  <ng-container *ngIf="content.data.video">
    <video controls>
      <source [src]="content.data.video" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </ng-container>
</ng-template>
<ng-template #tplConfiguration let-data="data">
  <kfd-configuration
    [configuration]="data.configuration"
    [project]="data.mandant"
    [seamless]="data.seamless ? data.seamless : false"
  >
  </kfd-configuration>
</ng-template>
