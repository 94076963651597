import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { COMMON_WEB_APP_ROUTES, User } from '@kfd/core';
import { AuthService } from '../services';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserResolver {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User | undefined> {
    return this._authService.currentUser().pipe(
      catchError((err) => {
        if (err.status === 401 && route.data?.['forceLogin'] === true) {
          this._router.navigate([
            COMMON_WEB_APP_ROUTES.user.login,
            {
              return: state.url,
            },
          ]);
        }
        return of(undefined);
      }),
    );
  }
}
