import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { COMMON_WEB_APP_ROUTES, ICON, User } from '@kfd/core';
import { AuthService } from '../../lib/services/auth.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'kfd-sign-in-wrapper',
  templateUrl: './sign-in-wrapper.component.html',
  styleUrl: './sign-in-wrapper.component.scss',
})
export class SignInWrapperComponent implements OnInit {
  @ContentChild('signedIn', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public signedIn: TemplateRef<any> | null = null;
  protected readonly ICON = ICON;
  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;
  protected isLoggedIn$: Observable<boolean> = of(false);
  protected currentUser$: Observable<User | undefined> = of(undefined);

  constructor(private readonly authService: AuthService) {}

  public ngOnInit() {
    this.isLoggedIn$ = this.authService.onSignInChange();
    this.currentUser$ = this.authService.currentUser(false);
  }

  protected signOut() {
    this.authService.signOut().subscribe({
      next: () => {
        // this.currentUser$ = of(undefined);
      },
    });
  }
}
