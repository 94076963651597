import { Pipe, PipeTransform } from '@angular/core';
import { VALIDATION_ERROR_DETAILS, VALIDATION_ERROR_TYPE, ValidationError } from '@kfd/core';

@Pipe({
  name: 'valerr',
})
export class ValidationErrorTextPipe implements PipeTransform {
  transform(validationError: ValidationError | undefined): string {
    if (!validationError) {
      return '';
    }
    switch (validationError.type) {
      case VALIDATION_ERROR_TYPE.REQUIRED:
        return 'Dieses Feld ist ein Pflichtfeld.';
      case VALIDATION_ERROR_TYPE.INVALID:
        switch (validationError.detail) {
          case VALIDATION_ERROR_DETAILS.EMPTY:
            return 'Dieses Feld muss ausgefüllt werden.';
          case VALIDATION_ERROR_DETAILS.INV_CHR:
            return 'Dieses Feld enthält ein ungültiges Zeichen.';
          case VALIDATION_ERROR_DETAILS.INV_FORMAT:
            switch (validationError?.data?.['type']) {
              case 'email':
                return 'Bitte eine gültige E-Mail Adresse angeben (mail@example.com).';
              case 'url':
                return 'Bitte eine gültige URL oder einen gültigen Link angeben.';
              case 'telephone':
                return 'Bitte eine gültige Telefonnummer angeben.';
              case 'date':
                return 'Bitte ein gültiges Datum angeben (2000-07-30).';
            }
            return 'Das Format der Eingabe ist falsch.';
          case VALIDATION_ERROR_DETAILS.TO_SHORT:
            if (validationError.data?.['min']) {
              return `Bitte mindestens ${validationError.data['min']} Zeichen eingeben.`;
            }
            return 'Die Eingabe is zu kurz.';
          case VALIDATION_ERROR_DETAILS.TO_LONG:
            if (validationError.data?.['max']) {
              return `Bitte maximal ${validationError.data} Zeichen eingeben.`;
            }
            return 'Die Eingabe is zu lang.';
          case VALIDATION_ERROR_DETAILS.INV_NUMBER:
            if (validationError.data?.['step']) {
              return `Es sind nur Zahlen in Schrittweite ${validationError.data['step']} erlaubt.`;
            }
            return 'Die Eingabe enthält einen ungültigen Wert';
          case VALIDATION_ERROR_DETAILS.TO_SMALL:
            if (validationError.data?.['min']) {
              return `Mindestwert: ${validationError.data['min']}`;
            }
            return 'Der Wert is zu klein.';
          case VALIDATION_ERROR_DETAILS.TO_HIGH:
            if (validationError.data?.['max']) {
              return `Maximal Wert: ${validationError.data['max']}`;
            }
            return 'Der Wert is zu groß.';
          case VALIDATION_ERROR_DETAILS.UNKNOWN:
            return 'Dieses Feld enthält einen ungültigen Wert.';
        }
    }
    return 'Unbekannter Fehler';
  }
}
