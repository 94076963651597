import { catchError, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ClientInputError, ForbiddenError, UnauthorizedError, UnknownError } from '../common/exceptions';

export interface InsertResponse {
  _id: string;
}

export type UpdateResponse = boolean;

/**
 * @deprecated use WebEndpointService instead
 */
export class BaseHttpService {
  constructor(
    public httpClient: HttpClient,
    private host: string,
  ) {}

  get<T>(path: string, options: Record<string, unknown> = {}): Observable<T> {
    return this.httpClient
      .get(`${this.host}${path}`, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError)) as unknown as Observable<T>;
  }

  post<T>(path: string, body = {}, options: Record<string, unknown> = {}): Observable<T> {
    return this.httpClient
      .post<T>(`${this.host}${path}`, body, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError)) as unknown as Observable<T>;
  }

  put<T>(path: string, body = {}, options: Record<string, unknown> = {}): Observable<T> {
    return this.httpClient
      .put(`${this.host}${path}`, body, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError)) as unknown as Observable<T>;
  }

  patch<T>(path: string, body = {}, options: Record<string, unknown> = {}): Observable<T> {
    return this.httpClient
      .patch(`${this.host}${path}`, body, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError)) as unknown as Observable<T>;
  }

  delete<T>(path: string, options: Record<string, unknown> = {}): Observable<T> {
    return this.httpClient
      .delete(`${this.host}${path}`, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError)) as unknown as Observable<T>;
  }

  protected handleError(response: unknown): Observable<void> {
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 401:
          return throwError(() => new UnauthorizedError());
        case 403:
          return throwError(() => new ForbiddenError());
        case 406:
          return throwError(() => new ClientInputError(response?.error?.message, response?.error?.code));
        default:
          return throwError(() => new UnknownError(response?.error?.message, response?.error?.code));
      }
    } else {
      return throwError(() => new UnknownError());
    }
  }
}
