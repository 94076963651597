import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { WebPage } from '@kfd/core';

@Component({
  selector: 'kfd-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.scss'],
})
export class StaticContentComponent {
  @Input()
  public content = '';
  @Input()
  public showTitle = true;
  @Output()
  public contentLoaded: EventEmitter<WebPage> = new EventEmitter<WebPage>();
  @Output()
  public contentError: EventEmitter<void> = new EventEmitter<void>();
  protected error = false;
  protected loading = true;
  protected staticContent: WebPage | undefined;

  constructor(private contentService: ContentService) {}

  private _identifier = '';

  @Input()
  set identifier(value: string) {
    if (!value) {
      return;
    }

    this.staticContent = undefined;
    this._identifier = value;
    this.loadContent();
  }

  loadContent() {
    if (!this._identifier) {
      return;
    }

    //@todo test render only on server - todo: add rendered detection
    // if (isPlatformBrowser(this.platformId)) {
    //     return;
    // }

    this.contentService.getPage(this._identifier).subscribe({
      next: (staticContent: WebPage) => {
        this.staticContent = staticContent;
        this.contentLoaded.emit(staticContent);
        this.loading = false;
        this.error = false;
      },
      error: () => {
        this.contentError.emit();
        this.error = true;
        this.loading = false;
      },
    });
  }

  // markdownReady() {
  //   if (!this.staticContent) {
  //     return
  //   }
  //   this.renderComponents(this.staticContent.content)
  // }
  //
  // renderComponents(content: string) {
  //   const matches = content.match(/\$\$(.*?):(.*?):(.*?)\$\$/g)
  //   if (matches) {
  //     for (const match of matches) {
  //       const parts = match.match(/\$\$(.*?):(.*?):(.*?)\$\$/)
  //       if (parts) {
  //         window.setTimeout(() => {
  //           this.addElement(parts[1], parts[2], parts[3].split(';'))
  //         })
  //       }
  //     }
  //   }
  // }
  //
  //
  // addElement(id: string, component: string, values: string[]) {
  //   const el = document.getElementById(`cmp-${id}`)
  //   let componentRef
  //   if (el) {
  //     switch (component) {
  //       case 'kfd-configuration':
  //         if (values.length !== 2) {
  //           throw new Error('Invalid values for kfd-configuration')
  //         }
  //         componentRef = this.viewContainerRef.createComponent(ConfigurationComponent);
  //         componentRef.instance.mandant = values[0];
  //         componentRef.instance.configuration = values[1];
  //         componentRef.instance.seamless = true;
  //         el.appendChild(componentRef.location.nativeElement)
  //         break
  //       default:
  //         throw new Error(`Unknown element ${component}`)
  //     }
  //   }
  // }
}
