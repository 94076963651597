import {
  AssetType,
  CLS,
  CONDITIONAL_OPERATOR,
  DATA_TYPES,
  DATA_VALUE_TYPE,
  DATAHANDLER_TYPES,
  FORM_VALUE_TYPES,
  FunctionCommand,
  LOGICAL_OPERATOR,
  PUBLISHED_STATE,
  SETTINGS_KEYS,
  SORT_DIRECTION,
  START_DATES,
  UNIT_TYPES,
  UsageType,
  USER_SCOPES,
} from '../dtos';

import { App, Service } from '../endpoints';
import { JsonSchema, SchemaUtil, TYPES } from './schema-util';
import {
  CONFIGURATION_SHORT_CODE,
  PATTERN,
  PROP_EMAIL,
  PROP_ICON,
  PROP_LABEL,
  PROP_MONGO_ID,
  PROP_NAME,
  PROP_VERIFICATION_CODE,
  REQUEST_STATUS_CODE_VALUE,
  SAVE_TEXT,
  SIMPLE_TEXT,
  TAG_LIST,
} from './schema.global';

/**
 * @deprecated use BASE_META_SCHEMA for new entities (if possible)
 */
export const META_SCHEMA = SchemaUtil.object(undefined, {
  created: SchemaUtil.date(),
  createdBy: SchemaUtil.objectId(),
  updated: SchemaUtil.date(),
  updatedBy: SchemaUtil.objectId(),
  projectId: SchemaUtil.objectId(),
  configuratorId: SchemaUtil.objectId(),
  state: SchemaUtil.enum(PUBLISHED_STATE),
});

/**
 *
 */
export const BASE_META_SCHEMA = SchemaUtil.object(['created', 'createdBy', 'projectId'], {
  created: SchemaUtil.date(),
  createdBy: SchemaUtil.objectId(),
  updated: SchemaUtil.date(),
  updatedBy: SchemaUtil.objectId(),
  projectId: SchemaUtil.objectId(),
  state: SchemaUtil.enum(PUBLISHED_STATE),
});

export function genericProps(published = false) {
  return {
    _id: published ? undefined : PROP_MONGO_ID,
    name: PROP_NAME,
    label: SchemaUtil.string({ minLength: 3 }),
  };
}

export function genericRequired(published = false) {
  return published ? ['name', 'label'] : ['_id', 'name', 'label'];
}

export const IMAGE = SchemaUtil.object(['cls', 'url'], {
  cls: SchemaUtil.enum([CLS.IMAGE]),
  url: SchemaUtil.string({ pattern: PATTERN.URL }),
  title: SchemaUtil.string({}),
  description: SchemaUtil.string({ maxLength: 500 }),
});

export const PROJECT_INFO = SchemaUtil.object(undefined, {
  name: SIMPLE_TEXT,
  slogan: SchemaUtil.string({ maxLength: 100 }),
  imprint: SchemaUtil.string({
    pattern: PATTERN.TEXT_MULTILINE,
    maxLength: 1000,
  }),
  privacyLink: SchemaUtil.string({ maxLength: 100 }),
  domain: SchemaUtil.string({ maxLength: 100 }),
});

export const PROJECT_CI = SchemaUtil.object(undefined, {
  logo: PROP_MONGO_ID,
  mainColor: SchemaUtil.string({ pattern: PATTERN.HEX_COLOR }),
  mainTextColor: SchemaUtil.string({ pattern: PATTERN.HEX_COLOR }),
  highlightColor: SchemaUtil.string({ pattern: PATTERN.HEX_COLOR }),
  highlightTextColor: SchemaUtil.string({ pattern: PATTERN.HEX_COLOR }),
  fontName: SIMPLE_TEXT,
});

export const PROJECT_LICENCE = SchemaUtil.object(['options', 'startDate', 'endDate'], {
  startDate: SchemaUtil.date(),
  endDate: SchemaUtil.date(),
  options: {
    // todo define licence object
    bsonType: 'object',
  },
});

/**
 * @see BaseDataTemplateValue
 */
export function baseDataTemplateValueSchema(): JsonSchema {
  return SchemaUtil.object(['cls', 'name', 'label', 'inputValue'], {
    cls: SchemaUtil.enum([CLS.BASE_DATA_TEMPLATE_VALUE]),
    name: PROP_NAME,
    label: PROP_LABEL,
    inputValue: baseInputValueSchema(),
    required: SchemaUtil.bool(),
  });
}

export function baseDataTemplateSchema(): JsonSchema {
  return SchemaUtil.object(['cls', 'name', 'label'], {
    cls: SchemaUtil.enum([CLS.BASE_DATA_TEMPLATE]),
    name: PROP_NAME,
    label: PROP_LABEL,
    description: SchemaUtil.string({
      minLength: 0,
      maxLength: 1000,
      pattern: PATTERN.TEXT_MULTILINE,
    }),
    icon: PROP_ICON,
    allowedTags: TAG_LIST,
    defaultTags: TAG_LIST,
    values: SchemaUtil.array(baseDataTemplateValueSchema()),
  });
}

export const USER_SCOPE = SchemaUtil.object(['userId', 'scopes'], {
  userId: PROP_MONGO_ID,
  scopes: SchemaUtil.array(SchemaUtil.enum(USER_SCOPES)),
});

export const REQUEST_STATUS = SchemaUtil.object(['value', 'label'], {
  value: REQUEST_STATUS_CODE_VALUE,
  label: SIMPLE_TEXT,
  disabled: SchemaUtil.bool(),
});

export const PROJECT_SCHEMA = SchemaUtil.object(['_id', 'cls', 'name', 'label', 'meta', 'users', 'licence'], {
  _id: SchemaUtil.objectId(),
  cls: SchemaUtil.enum([CLS.PROJECT]),
  name: PROP_NAME,
  label: SIMPLE_TEXT,
  info: PROJECT_INFO,
  infoChanges: PROJECT_INFO,
  ci: PROJECT_CI,
  infoVerified: SchemaUtil.date(),
  pages: SchemaUtil.array(SIMPLE_TEXT),
  users: SchemaUtil.array(USER_SCOPE),
  licence: PROJECT_LICENCE,
  orderedLicence: SchemaUtil.object(['licence', 'date'], {
    licence: PROJECT_LICENCE,
    date: SchemaUtil.date(),
    approved: SchemaUtil.date(),
    approvedBy: SchemaUtil.objectId(),
  }),
  requestStatus: SchemaUtil.array(REQUEST_STATUS),
  meta: META_SCHEMA,
  baseDataTemplates: SchemaUtil.array(baseDataTemplateSchema()),
});

export const PUBLISHED_PUBLIC_VERSION = SchemaUtil.object(['current'], {
  current: SchemaUtil.numeric(),
  published: SchemaUtil.numeric(),
  drafted: SchemaUtil.numeric(),
});

export const PUBLISHED_CMS_VERSIONS = SchemaUtil.object(['current', 'published', 'drafted'], {
  current: SchemaUtil.numeric(),
  published: SchemaUtil.numeric(),
  drafted: SchemaUtil.numeric(),
});

export const INFO = SchemaUtil.object(['name', 'label', 'content', 'modal'], {
  name: PROP_NAME,
  label: SIMPLE_TEXT,
  content: SchemaUtil.array(SchemaUtil.string({ pattern: PATTERN.TEXT_MULTILINE })),
  modal: SchemaUtil.bool(),
});

export const VALUE_LABEL = SchemaUtil.object(['cls', 'text'], {
  cls: SchemaUtil.enum([CLS.VALUE_LABEL]),
  text: SIMPLE_TEXT,
  format: SIMPLE_TEXT,
});

/**
 * @type BaseInputValue
 */
export function baseInputValueSchema() {
  return SchemaUtil.object(['cls', 'type'], {
    cls: SchemaUtil.enum([CLS.INPUT_VALUE]),
    type: SchemaUtil.enum(DATA_VALUE_TYPE),
    value: SchemaUtil.type([TYPES.DOUBLE, TYPES.STRING, TYPES.INT]),
  });
}

/**
 * @see InputValue
 */
export const INPUT_VALUE = SchemaUtil.object(['cls', 'type', 'value'], {
  cls: SchemaUtil.enum([CLS.INPUT_VALUE]),
  type: SchemaUtil.enum(DATA_VALUE_TYPE),
  value: SchemaUtil.type([TYPES.DOUBLE, TYPES.STRING, TYPES.BOOL, TYPES.INT]),
  identifier: SAVE_TEXT,
  label: VALUE_LABEL,
  properties: SchemaUtil.object(undefined, {
    prefix: SIMPLE_TEXT,
    suffix: SIMPLE_TEXT,
    mode: SIMPLE_TEXT,
  }),
});

/**
 * @see
 */
export const DATA_VALUE = SchemaUtil.object(['cls', 'type', 'value'], {
  ...INPUT_VALUE.properties,
  cls: SchemaUtil.enum([CLS.INPUT_VALUE, CLS.SELECTION_VALUE]),
});

/**
 * @see FieldRef
 */
export const FIELD_REF = SchemaUtil.object(['cls', 'name'], {
  cls: SchemaUtil.enum([CLS.FIELD_REF]),
  name: PROP_NAME,
  property: SAVE_TEXT,
  label: SIMPLE_TEXT,
});

/**
 * @see DateRef
 */
export const DATE_REF = SchemaUtil.object(['cls', 'name'], {
  cls: SchemaUtil.enum([CLS.DATE_REF]),
  name: SchemaUtil.enum(START_DATES),
});

export const ALIAS_REF = SchemaUtil.object(['cls', 'name'], {
  cls: SchemaUtil.enum([CLS.ALIAS_VALUE]),
  name: PROP_NAME,
  label: SIMPLE_TEXT,
});

export const CONDITION = SchemaUtil.object(['cls', 'value1', 'op'], {
  cls: SchemaUtil.enum([CLS.CONDITION]),
  value1: FIELD_REF,
  op: SchemaUtil.enum(CONDITIONAL_OPERATOR),
  value2: DATA_VALUE,
});

export const CONDITION_GROUP = SchemaUtil.object(['cls', 'lop', 'conditions'], {
  cls: SchemaUtil.enum([CLS.CONDITION_GROUP]),
  lop: SchemaUtil.enum(LOGICAL_OPERATOR),
  conditions: SchemaUtil.array(CONDITION),
});

export function calculation(nestingLevel = 3): JsonSchema {
  const paramItems: JsonSchema[] = [FIELD_REF, DATA_VALUE, ALIAS_REF];

  if (nestingLevel > 0) {
    paramItems.push(calculation(--nestingLevel));
  }

  return SchemaUtil.object(['cls', 'op', 'params'], {
    cls: SchemaUtil.enum([CLS.CALCULATION]),
    op: {
      //todo define
    },
    params: SchemaUtil.array({ anyOf: paramItems }),
    label: VALUE_LABEL,
    alias: SIMPLE_TEXT,
    excludeFromResult: SchemaUtil.bool(),
    fn: SchemaUtil.object(['cls', 'command'], {
      cls: SchemaUtil.enum([CLS.CALCULATION_FUNCTION]),
      command: SchemaUtil.enum(FunctionCommand),
      params: SchemaUtil.array(SchemaUtil.type([TYPES.STRING, TYPES.INT])),
    }),
    condition: CONDITION,
  });
}

export const RELATIVE_DATE = SchemaUtil.object(['cls', 'start', 'manipulation', 'value', 'unit'], {
  cls: SchemaUtil.enum([CLS.RELATIVE_DATE]),
  start: {
    anyOf: [FIELD_REF, DATE_REF],
  },
  manipulation: SchemaUtil.enum(['+', '-']),
  value: SchemaUtil.numeric(),
  unit: SchemaUtil.enum(UNIT_TYPES),
});

export const PAGE_REF = SchemaUtil.object(['cls', 'name'], {
  cls: SchemaUtil.enum([CLS.PAGE_REF]),
  name: PROP_NAME,
});

export const DATA_REF = SchemaUtil.object(['cls', 'name'], {
  cls: SchemaUtil.enum([CLS.DATA_REF]),
  name: PROP_NAME,
});

export const DATA_HANDLER = SchemaUtil.object(['cls', 'type'], {
  cls: SchemaUtil.enum([CLS.DATA_HANDLER]),
  type: {
    enum: Object.values(DATAHANDLER_TYPES),
  },
  templateName: SAVE_TEXT,
  tags: TAG_LIST,
  data: SchemaUtil.array({
    anyOf: [DATA_REF],
  }),
});

export function listDataHandlerSchema(): JsonSchema {
  return SchemaUtil.object(['cls', 'type'], {
    cls: SchemaUtil.enum([CLS.DATA_HANDLER]),
    type: SchemaUtil.enum([DATAHANDLER_TYPES.LIST]),
    data: SchemaUtil.array({
      anyOf: [DATA_REF],
    }),
  });
}

export function dynamicDataHandlerSchema(): JsonSchema {
  return SchemaUtil.object(['cls', 'type'], {
    cls: SchemaUtil.enum([CLS.DATA_HANDLER]),
    type: SchemaUtil.enum([DATAHANDLER_TYPES.DYNAMIC]),
    templateName: SAVE_TEXT,
    tags: TAG_LIST,
    sort: SchemaUtil.array(
      SchemaUtil.object(['field', 'direction'], {
        field: SAVE_TEXT,
        direction: SchemaUtil.enum(SORT_DIRECTION),
      }),
    ),
  });
}

export function conditionalEntrySchema(cls: CLS | CLS[], entry: JsonSchema = { bsonType: 'objectId' }): JsonSchema {
  return SchemaUtil.object(['cls', 'entry'], {
    cls: SchemaUtil.enum(Array.isArray(cls) ? [...new Set(cls)] : [cls]),
    entry,
    condition: CONDITION_GROUP,
  });
}

/**
 * @type CmsSelectionData
 */
export function baseDataSchema(published = false): JsonSchema {
  return SchemaUtil.object([...genericRequired(published), 'cls', 'type'], {
    ...genericProps(published),
    meta: published ? undefined : BASE_META_SCHEMA,
    cls: SchemaUtil.enum([CLS.SELECTION_DATA]),
    description: SchemaUtil.string({ pattern: PATTERN.TEXT_MULTILINE }),
    type: SchemaUtil.enum(DATA_TYPES),
    templateName: SAVE_TEXT,
    // @deprecated
    value: DATA_VALUE,
    values: SchemaUtil.array(INPUT_VALUE),
    tags: TAG_LIST,
    image: PROP_MONGO_ID,
    info: INFO,
  });
}

export function assetExportSchema(): JsonSchema {
  return SchemaUtil.object(['cls', 'name', 'label', 'type', 'blob'], {
    cls: SchemaUtil.enum([CLS.ASSET_EXPORT]),
    name: PROP_NAME,
    label: SchemaUtil.string({ minLength: 3 }),
    type: SchemaUtil.enum(AssetType),
    blob: SchemaUtil.string({ maxLength: 1500000 }),
  });
}

// basic selection data (eg for saving in form values)
export const SELECTION_DATA = SchemaUtil.object(['cls', 'name', 'label', 'type'], {
  cls: SchemaUtil.enum([CLS.SELECTION_DATA]),
  name: PROP_NAME,
  label: PROP_LABEL,
  type: SchemaUtil.enum([DATA_TYPES.SELECTION]),
});

export const CACHE_SCHEMA = SchemaUtil.object(['_id', 'createdDate', 'group', 'type', 'identifier', 'data'], {
  _id: PROP_MONGO_ID,
  createdDate: SchemaUtil.date(),
  group: SIMPLE_TEXT,
  type: SIMPLE_TEXT,
  identifier: SAVE_TEXT,
  data: {
    // todo define
    bsonType: 'object',
  },
});

export const APP_LOG_SCHEMA = SchemaUtil.object(['_id', 'createdDate', 'service', 'severity', 'message'], {
  _id: PROP_MONGO_ID,
  createdDate: SchemaUtil.date(),
  service: SchemaUtil.enum([...Object.values(Service), ...Object.values(App)]),
  severity: SchemaUtil.numeric({ minimum: 0, maximum: 4 }),
  message: SchemaUtil.string({ maxLength: 1000 }),
  errorId: SchemaUtil.string({ pattern: PATTERN.ERROR_ID }),
  data: {
    bsonType: 'object',
  },
});

export const USAGE_LOG_SCHEMA = SchemaUtil.object(['_id', 'createdDate', 'projectId', 'configurationId', 'type'], {
  _id: PROP_MONGO_ID,
  createdDate: SchemaUtil.date(),
  projectId: PROP_MONGO_ID,
  configurationId: PROP_MONGO_ID,
  type: SchemaUtil.enum(UsageType),
  browser: {
    bsonType: 'object',
    //todo define
  },
});

export const AUDIT_LOG_SCHEMA = SchemaUtil.object(['_id', 'createdDate', 'method', 'context', 'action'], {
  _id: PROP_MONGO_ID,
  createdDate: SchemaUtil.date(),
  projectId: PROP_MONGO_ID,
  userId: PROP_MONGO_ID,
  requestId: SIMPLE_TEXT,
  method: SIMPLE_TEXT,
  context: SIMPLE_TEXT,
  action: SIMPLE_TEXT,
  params: {
    // unknown content
    bsonType: 'object',
  },
  body: {
    // unknown content
    bsonType: ['object', 'array'],
  },
  state: SchemaUtil.object(['type', 'time'], {
    type: SIMPLE_TEXT,
    time: SchemaUtil.date(),
    error: SchemaUtil.string({ maxLength: 1000 }),
  }),
});

export const CUSTOMER_REQUEST_FILTER = SchemaUtil.object(undefined, {
  cfgCode: SchemaUtil.array(SchemaUtil.string({ minLength: 3, maxLength: 10 })),
  text: SchemaUtil.string(),
  dateFrom: SchemaUtil.date(),
  dateTo: SchemaUtil.date(),
  state: SchemaUtil.array(SchemaUtil.string()),
  userId: SchemaUtil.array(SchemaUtil.string()),
  sortBy: {
    enum: ['createdDate+', 'createdDate-', 'updatedDate+', 'updatedDate-'],
  },
});

export const CUSTOMER_REQUEST_FILTER_SAVED = SchemaUtil.object(['cls', 'id', 'label', 'filterValues'], {
  cls: SchemaUtil.enum([CLS.FILTER]),
  id: SchemaUtil.string({ minLength: 3, pattern: PATTERN.SAVE_TEXT }),
  label: SchemaUtil.string(),
  filterValues: CUSTOMER_REQUEST_FILTER,
});

export const USER_SETTINGS_SCHEMA = SchemaUtil.object(['_id', 'userId', 'key', 'value'], {
  _id: PROP_MONGO_ID,
  userId: PROP_MONGO_ID,
  projectId: PROP_MONGO_ID,
  key: SchemaUtil.enum(SETTINGS_KEYS),
  value: {
    oneOf: [SchemaUtil.string(), SchemaUtil.array(CUSTOMER_REQUEST_FILTER_SAVED)],
  },
});

export const INVITATION_SCHEMA = SchemaUtil.object(['_id', 'email', 'projectId', 'meta', 'scopes'], {
  _id: PROP_MONGO_ID,
  email: PROP_EMAIL,
  projectId: PROP_MONGO_ID,
  mandantName: SIMPLE_TEXT,
  scopes: SchemaUtil.array(SchemaUtil.enum(USER_SCOPES)),
  meta: META_SCHEMA,
});

export const CONFIGURATION_IDENTIFIER = SchemaUtil.object(['id', 'version'], {
  id: PROP_MONGO_ID,
  version: SchemaUtil.numeric(),
});

/**
 * @see EmptyFormValue
 */
export const EMPTY_FORM_VALUE = SchemaUtil.object(['cls', 'type'], {
  cls: SchemaUtil.enum([CLS.FORM_VALUE]),
  type: SchemaUtil.enum([FORM_VALUE_TYPES.EMPTY]),
});

/**
 * @see SingleFormValue
 */
export const SINGLE_FORM_VALUE = SchemaUtil.object(['cls', 'type', 'input'], {
  cls: SchemaUtil.enum([CLS.FORM_VALUE]),
  type: SchemaUtil.enum([FORM_VALUE_TYPES.SINGLE]),
  input: DATA_VALUE,
});

/**
 * @see SingleSelectionFormValue
 */
export const SINGLE_SELECTION_FORM_VALUE = SchemaUtil.object(['cls', 'type', 'selection'], {
  cls: SchemaUtil.enum([CLS.FORM_VALUE]),
  type: SchemaUtil.enum([FORM_VALUE_TYPES.SINGLE_SELECTION]),
  key: SAVE_TEXT,
  selection: SELECTION_DATA,
});

/**
 * @see MULTI_SELECTION_FORM_VALUE
 */
export const MULTI_SELECTION_FORM_VALUE = SchemaUtil.object(['cls', 'type', 'selection'], {
  cls: SchemaUtil.enum([CLS.FORM_VALUE]),
  type: SchemaUtil.enum([FORM_VALUE_TYPES.MULTI_SELECTION]),
  key: SAVE_TEXT,
  selection: SchemaUtil.array(SELECTION_DATA),
});

export const FORM_VALUE = {
  anyOf: [EMPTY_FORM_VALUE, SINGLE_FORM_VALUE, SINGLE_SELECTION_FORM_VALUE, MULTI_SELECTION_FORM_VALUE],
};

export const FORM_VALUE_MAP = {
  bsonType: TYPES.OBJECT,
  additionalProperties: false,
  patternProperties: {
    '^[a-z0-9_-]{3,32}$': FORM_VALUE,
  },
};

export const CUSTOMER_REQUEST = SchemaUtil.object(
  [
    '_id',
    'projectId',
    'code',
    'configuration',
    'formValueMap',
    'customerCode',
    'currentState',
    'createdDate',
    'updatedDate',
    'statusLog',
  ],
  {
    _id: PROP_MONGO_ID,
    projectId: PROP_MONGO_ID,
    code: SchemaUtil.object(['cfg', 'number'], {
      cfg: CONFIGURATION_SHORT_CODE,
      number: SchemaUtil.numeric(),
    }),
    configuration: CONFIGURATION_IDENTIFIER,
    title: SchemaUtil.array(SchemaUtil.string()),
    formValueMap: FORM_VALUE_MAP,
    customerCode: SchemaUtil.string({
      pattern: PATTERN.CUSTOMER_CODE,
    }),
    currentState: REQUEST_STATUS_CODE_VALUE,
    currentUser: SchemaUtil.objectId(),
    userList: SchemaUtil.array(SchemaUtil.objectId()),
    createdDate: SchemaUtil.date(),
    updatedDate: SchemaUtil.date(),
    statusLog: SchemaUtil.array(
      SchemaUtil.object(['status', 'date', 'user'], {
        status: REQUEST_STATUS_CODE_VALUE,
        date: SchemaUtil.date(),
        user: SchemaUtil.objectId(),
        comment: SchemaUtil.string({
          minLength: 0,
          maxLength: 1000,
          pattern: PATTERN.TEXT_MULTILINE,
        }),
      }),
    ),
    customerSettings: SchemaUtil.object(undefined, {
      enableEmailNotifications: SchemaUtil.bool(),
      emailVerification: SchemaUtil.object(['email', 'date', 'code'], {
        email: PROP_EMAIL,
        date: SchemaUtil.date(),
        code: PROP_VERIFICATION_CODE,
      }),
      email: PROP_EMAIL,
    }),
  },
);
