@if (signInSuccess) {
  <p-message
    class="block mt-2"
    data-automationId="msg-login-success"
    severity="success"
    text="Sie haben sich erfolgreich angemeldet"
  ></p-message>
} @else {
  <div data-automationId="login-form">
    <form (keyup.enter)="signIn()" (ngSubmit)="signIn()">
      <div class="p-fluid">
        <div class="p-field">
          <label>E-Mail</label>
          <input [(ngModel)]="email" data-automationId="input-email" name="inputEmail" pInputText type="text" />
        </div>
        <div class="p-field">
          <label>Passwort</label>
          <input
            [(ngModel)]="password"
            data-automationId="input-password"
            name="inputPassword"
            pInputText
            type="password"
          />
        </div>
        @switch (signInFailed) {
          @case ('error') {
            <p-message
              class="block mt-4"
              data-automationId="msg-login-error"
              severity="error"
              text="Anmeldung momentan nicht möglich. Bitte versuchen Sie es später erneut."
            ></p-message>
          }
          @case ('failed') {
            <kfd-action-message
              class="block mt-4"
              data-automationId="msg-login-failed"
              severity="warn"
              text="Anmeldung fehlgeschlagen, bitte überprüfen Sie Ihre Eingaben."
              info="Achten Sie bei Ihrem Passwort auf Groß- und Kleinschreibung. Sollten Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen."
              [actionBtnIcon]="ICON.RESET"
              [link]="COMMON_WEB_APP_ROUTES.user.passwordReset.root"
              actionBtnTooltip="Passwort zurücksetzen"
            ></kfd-action-message>
          }
          @case ('inactive') {
            <kfd-action-message
              class="block mt-4"
              data-automationId="msg-login-inactive"
              severity="warn"
              text="Konto inaktiv"
              info="Ihr Konto ist inaktiv. Bitte überprüfen Sie Ihre E-Mails und aktivieren Sie Ihr Konto."
              [actionBtnIcon]="ICON.NEXT"
              [link]="COMMON_WEB_APP_ROUTES.user.activation.root"
              actionBtnTooltip="Konto aktivieren"
            ></kfd-action-message>
          }
        }
        <p-button
          class="block mt-4"
          data-automationId="submit-sign-in-form-button"
          label="Anmelden"
          (click)="signIn()"
          [disabled]="!email || !password"
        ></p-button>
      </div>
    </form>

    <div class="mt-4">
      <p-button
        icon="pi {{ ICON.PROFILE }}"
        class="mr-2"
        label="Neu registrieren"
        data-automationId="sign-up-btn"
        [rounded]="true"
        [outlined]="true"
        (click)="pageChange.emit(COMMON_WEB_APP_ROUTES.user.registration)"
        [routerLink]="COMMON_WEB_APP_ROUTES.user.registration"
      >
      </p-button>
      <p-button
        icon="pi {{ ICON.RESET }}"
        class="mr-2"
        label="Passwort vergessen"
        [rounded]="true"
        [text]="true"
        (click)="pageChange.emit(COMMON_WEB_APP_ROUTES.user.passwordReset.root)"
        [routerLink]="COMMON_WEB_APP_ROUTES.user.passwordReset.root"
      >
      </p-button>
    </div>
  </div>
}
