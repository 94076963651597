import { Component } from '@angular/core';
import { ICON, User } from '@kfd/core';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'kfd-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent {
  protected icon = ICON;
  protected loading = false;
  protected signed$: Observable<{ in: boolean }>;
  protected user$: Observable<User | undefined> | undefined;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.signed$ = authService.onSignInChange().pipe(
      tap((signedIn) => {
        if (signedIn) {
          this.loadUser();
        } else {
          this.user$ = undefined;
        }
      }),
      map((signedId) => ({ in: signedId })),
    );
  }

  loadUser() {
    this.user$ = this.authService.currentUser().pipe(take(1));
  }

  logout(): void {
    this.loading = true;
    this.authService.signOut().subscribe(() => {
      this.loading = false;
      this.user$ = undefined;
      this.router.navigate(['/auth']);
    });
  }
}
