<div class="header">
  <div class="width-container">
    <p-toolbar class="header-toolbar flex-1" styleClass="invisible fixed-height">
      <div class="p-toolbar-group-left">
        <ng-content select="[headerLeft]"></ng-content>
      </div>
      <div class="p-toolbar-group-right">
        <ng-content select="[headerRight]"></ng-content>
      </div>
    </p-toolbar>
  </div>
</div>
<div class="content">
  <div class="width-container">
    <ng-content></ng-content>
  </div>
</div>
<div class="footer">
  <div class="width-container">
    <div class="flex-1 m-2 flex justify-content-between">
      <div class="flex flex-column justify-content-center">
        <ng-content select="[footerLeft]"></ng-content>
      </div>
      <div class="flex flex-column justify-content-center">
        <ng-content select="[footerRight]"></ng-content>
      </div>
    </div>
  </div>
</div>
