import { StringUtil } from './';

// cache for 15 second
export const CACHE_XS = 15;
// cache for 1 minute
export const CACHE_S = 60;
// cache for 5 minutes
export const CACHE_M = 300;
// cache for 10 minutes
export const CACHE_L = 600;
// cache for 1 hour
export const CACHE_XL = 3600;

export interface CacheOptions {
  // time in seconds
  defaultTtl?: number;
}

export class Cache {
  protected cache = new Map<string, unknown>();
  protected timeouts = new Map<string, number>();
  protected defaultTtl = 0;

  constructor({ defaultTtl = 0 }: CacheOptions = {}) {
    if (defaultTtl) {
      this.defaultTtl = defaultTtl;
    }
  }

  destruct() {
    //nothing to do yet
  }

  /**
   * returns the number of cache entries (includes outdated)
   */
  public size(): number {
    return this.cache.size;
  }

  /**
   * sets a value in the cache
   * @param key
   * @param value
   * @param ttl time in seconds
   */
  public set(key: string, value: unknown, ttl: number | undefined = undefined) {
    key = StringUtil.toSaveString(key);
    this.cache.set(key, value);

    if (ttl === undefined) {
      ttl = this.defaultTtl;
    }
    if (ttl > 0) {
      this.timeouts.set(key, new Date().getTime() + ttl * 1000);
    } else {
      this.timeouts.delete(key);
    }
  }

  public has(key: string): boolean {
    key = StringUtil.toSaveString(key);
    if (this.timeouts.has(key)) {
      const expirationTime = this.timeouts.get(key);
      if (expirationTime && new Date().getTime() > expirationTime) {
        this.timeouts.delete(key);
        this.cache.delete(key);
        return false;
      }
    }
    return this.cache.has(key);
  }

  public get<T>(key: string): T {
    key = StringUtil.toSaveString(key);
    if (!this.has(key)) {
      throw new Error(`Key "${key}" does not exist`);
    }
    return this.cache.get(key) as T;
  }

  public delete(key: string): void {
    key = StringUtil.toSaveString(key);
    if (!this.has(key)) {
      return;
    }
    this.cache.delete(key);
  }

  /**
   * removes all outdated entries from cache to clean up memory
   */
  public clean(): void {
    if (!this.timeouts || this.timeouts.size === 0) {
      return;
    }
    const currentTime = new Date().getTime();
    for (const [key, expirationTime] of this.timeouts.entries()) {
      if (expirationTime && currentTime > expirationTime) {
        this.timeouts.delete(key);
        this.cache.delete(key);
      }
    }
  }

  /**
   * removes all entries from the cache
   */
  public clear(): void {
    this.cache.clear();
  }
}
