@if (screenSize$ | async; as screenSize) {
  <ul class="selection-list grid">
    @for (option of options; track option.id) {
      <li
        class="select-option"
        [ngClass]="{
          'col-6': screenSize === ScreenSize.XS,
          'col-4': screenSize === ScreenSize.MD,
          'col-3': screenSize === ScreenSize.LG,
          image: !!option.image
        }"
      >
        @if (option.selected) {
          <div
            class="option-item selected"
            (click)="deSelectOption(option)"
            [pTooltip]="option.label"
            tooltipPosition="bottom"
          >
            <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
          </div>
        } @else {
          <div class="option-item" (click)="selectOption(option)" [pTooltip]="option.label" tooltipPosition="bottom">
            <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
          </div>
        }
      </li>
    }
  </ul>
}

<ng-template #optionTpl let-option="option">
  @if (option.image) {
    <img [src]="option.image" />
  }
  <div class="label">{{ option.label }}</div>
</ng-template>
