import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { filter, first, Observable, of, Subject, takeUntil, zip } from 'rxjs';
import { authServiceEP, AuthToken, CACHE_L, RequiredUserAction, SelectOption, Service, User } from '@kfd/core';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseHttpService } from './base.http.service';
import { FeCache } from '../common';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { WebEndpointService } from './web-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  private isLoggedIn: boolean | undefined;
  private httpService: BaseHttpService;
  private cache = new FeCache();
  private signInChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    httpClient: HttpClient,
    private configService: ConfigService,
    private readonly webEndpointService: WebEndpointService,
  ) {
    this.httpService = new BaseHttpService(httpClient, this.configService.getService(Service.AUTH_SERVICE));
  }

  private _csrfToken = '';

  public get csrfToken(): string {
    return this._csrfToken;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  healthy(): Observable<boolean> {
    return this.httpService.get<boolean>(`/`).pipe(map(() => true));
  }

  public onSignInChange(): Observable<boolean> {
    return new Observable((subscriber) => {
      this.signInChange.subscribe((signInChange) => {
        subscriber.next(signInChange);
      });
      if (this.isLoggedIn !== undefined) {
        subscriber.next(this.isLoggedIn);
      } else {
        this.currentUser()
          .pipe(first())
          .subscribe((user) => {
            subscriber.next(user !== undefined);
          });
      }
    });
  }

  userExists(email: string): Observable<typeof authServiceEP.auth.userExists.response> {
    return this.webEndpointService.get(authServiceEP.auth.userExists, [email]);
  }

  public signIn(email: string, password: string, emit = true): Observable<AuthToken> {
    const loginUrl = this.webEndpointService.url(authServiceEP.auth.login);
    // using the native http client to avoid normal error handling
    return this.httpService.httpClient
      .post(
        loginUrl,
        {
          username: email,
          password,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        tap(() => {
          if (this.isLoggedIn !== true) {
            if (emit) {
              this.signInChange.emit(true);
            }
            this.cache.delete('currentUser');
            this.isLoggedIn = true;
          }
        }),
      ) as unknown as Observable<AuthToken>;
  }

  public signUp(email: string, password: string, name: string, privacyAccepted: boolean): Observable<void> {
    return this.httpService.post<void>(`/auth/register`, {
      email,
      password,
      name,
      privacyAccepted,
    });
  }

  public resendActivationMail(
    email: string,
    password: string,
  ): Observable<typeof authServiceEP.auth.resendActivationMail.response> {
    return this.webEndpointService.post(authServiceEP.auth.resendActivationMail, [], {
      email,
      password,
    });
  }

  refreshToken(): Observable<AuthToken> {
    return this.httpService.post<AuthToken>(`/auth/refresh`);
  }

  updateCsrfToken(): Observable<string> {
    return this.webEndpointService
      .get(authServiceEP.auth.csrfToken)
      .pipe(tap((csrfToken) => (this._csrfToken = csrfToken)));
  }

  currentUser(useCache = true): Observable<User | undefined> {
    const key = 'currentUser';
    const obs = this.httpService.get<User>(`/auth/user`).pipe(
      takeUntil(this.destroy$),
      catchError(() => {
        if (this.isLoggedIn !== false) {
          this.signInChange.emit(false);
          this.isLoggedIn = false;
        }
        return of(undefined);
      }),
      map((user) => {
        if (user) {
          if (!this.isLoggedIn) {
            this.signInChange.emit(true);
            this.isLoggedIn = true;
          }
          return user;
        } else {
          if (this.isLoggedIn) {
            this.signInChange.emit(false);
            this.isLoggedIn = false;
          }
          this.cache.delete('currentUser');
          return undefined;
        }
      }),
    );

    return useCache ? this.cache.cacheObs(obs, key, CACHE_L) : obs;
  }

  profile(useCache = true): Observable<User> {
    const key = 'user-profile';
    const obs = this.httpService.get<User>(`/auth/profile`);
    return useCache === true ? this.cache.cacheObs(obs, key, CACHE_L) : obs;
  }

  requiredUserActions(): Observable<RequiredUserAction> {
    return this.httpService.get<RequiredUserAction>(`/auth/user/email/required-actions`, {
      responseType: 'text',
    });
  }

  emailValidate(email: string, code: string): Observable<boolean> {
    return this.webEndpointService.post(authServiceEP.auth.validateEmailByCode, [email, code]);
  }

  changeProfile(name: string): Observable<User> {
    return this.httpService.put<User>(`/auth/profile`, {
      name,
    });
  }

  changeEmail(email: string): Observable<boolean> {
    return this.httpService.put<boolean>(`/auth/profile/email`, {
      email,
    });
  }

  passwordReset(email: string): Observable<boolean> {
    return this.httpService.post<boolean>(`/auth/profile/password-reset`, {
      email,
    });
  }

  setPassword(email: string, code: string, password: string): Observable<boolean> {
    return this.httpService.post<boolean>(`/auth/profile/set-password`, {
      email,
      code,
      password,
    });
  }

  changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    return this.httpService.post<boolean>(`/auth/profile/change-password`, {
      oldPassword,
      newPassword,
    });
  }

  info(userId: string, useCache = true): Observable<User | undefined> {
    if (userId === undefined) {
      return of(undefined);
    }
    const key = 'userinfo-' + userId;
    const obs = this.httpService.get<User>(`/auth/profile/${userId}`);
    return useCache === true ? this.cache.cacheObs(obs, key, CACHE_L) : obs;
  }

  infoAll(list: SelectOption[]): Observable<SelectOption[]> {
    if (list.length === 0) {
      return of([]);
    }
    const obs = list.map((option) =>
      this.info(option.value.toString()).pipe(
        filter((user) => user !== undefined),
        map((user) => ({ value: user?._id, label: user?.name })),
      ),
    );
    return zip(...obs) as Observable<SelectOption[]>;
  }

  sendValidationMail(): Observable<boolean> {
    return this.httpService.post<boolean>(`/auth/profile/email/validation`);
  }

  acceptPrivacy(): Observable<boolean> {
    return this.httpService.post<boolean>(`/auth/user/action/accept-privacy`);
  }

  signOut(): Observable<void> {
    return this.webEndpointService.post(authServiceEP.auth.logout).pipe(
      tap(() => {
        if (this.isLoggedIn !== false) {
          this.signInChange.emit(false);
          this.cache.clear();
          this.isLoggedIn = false;
        }
      }),
    );
  }
}
