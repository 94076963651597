import { Endpoint, Service } from './index';
import { Id } from '../dtos';

/**
 * Platform independent service to handle functionality around endpoints
 */
export class EndpointService {
  private services: Map<Service, string>;

  constructor(services: Partial<Record<Service, string>>) {
    this.services = new Map<Service, string>(Object.entries(services) as [Service, string][]);
  }

  public static relativeUrl(endpoint: Endpoint<unknown>, params?: (string | number)[]): string {
    return this.relativePath(endpoint.path, params);
  }

  public static relativePath(url: string, params?: (string | number | Id)[]): string {
    //get query parameters from url
    const urlParts = url.match(/^(.*?)(\?.*?)?$/);
    if (!urlParts) {
      throw new Error('Invalid URL');
    }
    const path = urlParts[1];
    const queryParams = urlParts[2] ?? '';
    const placeholder = path.match(/(\/:[a-z0-9-_]+)/gim);
    if (placeholder && placeholder.length > 0) {
      if (params?.length !== placeholder.length) {
        throw new Error(`You need to provide ${placeholder.length} params for the path "${path}"`);
      }
      const parts = path.split('/');
      const replaced: string[] = [];
      let paramPos = 0;
      for (const part of parts) {
        if (part.startsWith(':')) {
          replaced.push(params[paramPos].toString());
          paramPos++;
        } else {
          replaced.push(part);
        }
      }
      return ('/' + replaced.join('/')).replace(/\/\//g, '/') + queryParams;
    }
    return ('/' + path).replace(/\/\//g, '/') + queryParams;
  }

  /**
   * creates a URL object from an endpoint with the given params
   * @param endpoint
   * @param params
   */
  public absoluteUrl(endpoint: Endpoint<unknown>, params?: (string | number)[]): URL {
    return new URL(this.serviceUrl(endpoint.service) + EndpointService.relativeUrl(endpoint, params));
  }

  private serviceUrl(service: Service): string {
    if (!this.services.has(service)) {
      throw new Error(`Cannot get url for unknown service "${service}"`);
    }
    return this.services.get(service) as string;
  }
}
