import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { MessageService } from '../../services/message.service';
import { ICON, RequiredUserAction } from '@kfd/core';
import { DialogService } from 'primeng/dynamicdialog';
import { PrivacyAcknowledgeDialogComponent } from '../privacy-acknowledge-dialog/privacy-acknowledge-dialog.component';

@Component({
  selector: 'kfd-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss'],
})
export class UserMessagesComponent implements OnInit, OnDestroy {
  @Input()
  profileLink: string | undefined;
  protected readonly ICON = ICON;
  protected RequiredUserAction = RequiredUserAction;
  protected requiredUserAction$: Observable<RequiredUserAction> | undefined;
  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.onSignInChange().subscribe((signedIn: boolean) => {
        if (signedIn) {
          this.loadActivation();
        }
      }),
    );
  }

  loadActivation() {
    this.requiredUserAction$ = this.authService.requiredUserActions();
    // this.subscriptions.push(
    //   this.authService.requiredUserActions().subscribe((requiredUserAction) => {
    //     switch (requiredUserAction) {
    //       case RequiredUserAction.EMAIL_ACTIVATION:
    //         this.messageService.showInfo('Aktivierung', 'Ihre E-Mail Adresse wurde noch nicht bestätigt');
    //         break;
    //       case RequiredUserAction.PRIVACY_ACKNOWLEDGE:
    //         // this.messageService.showInfo("Datenschutz", "Datenschutz");
    //         return this.dialogService.open(PrivacyAcknowledgeDialogComponent, {
    //           styleClass: 'custom-dialog',
    //           closeOnEscape: false,
    //           closable: false,
    //           dismissableMask: false,
    //           showHeader: false,
    //           transitionOptions: '300ms ease-in-out',
    //           width: '90%',
    //         });
    //         break;
    //     }
    //     return;
    //   }),
    // );
  }

  acceptPrivacy() {
    this.dialogService.open(PrivacyAcknowledgeDialogComponent, {
      styleClass: 'custom-dialog',
      closeOnEscape: false,
      closable: false,
      dismissableMask: false,
      showHeader: false,
      transitionOptions: '300ms ease-in-out',
      width: '90%',
      height: '90%',
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    }
  }
}
