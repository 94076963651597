import {
  calculation,
  conditionalEntrySchema,
  CONFIGURATION_SHORT_CODE,
  DATA_VALUE,
  dynamicDataHandlerSchema,
  FIELD_REF,
  genericProps,
  INFO,
  JsonSchema,
  listDataHandlerSchema,
  PAGE_REF,
  PATTERN,
  PUBLISHED_CMS_VERSIONS,
  PUBLISHED_PUBLIC_VERSION,
  RELATIVE_DATE,
  SAVE_TEXT,
  SchemaUtil,
  TYPES,
} from './';
import {
  CLS,
  DATA_VALUE_TYPE,
  FIELD_TYPES,
  FORM_VALUE_TYPES,
  PUBLISHED_STATE,
  SELECT_FIELD_LAYOUTS,
  TEXTFIELD_VALIDATIONS,
} from '../dtos';

const CFG_META_SCHEMA = SchemaUtil.object(['created', 'createdBy', 'projectId'], {
  created: SchemaUtil.date(),
  createdBy: SchemaUtil.objectId(),
  updated: SchemaUtil.date(),
  updatedBy: SchemaUtil.objectId(),
  projectId: SchemaUtil.objectId(),
  entryId: SchemaUtil.objectId(),
  configuratorId: SchemaUtil.objectId(),
  version: SchemaUtil.numeric(),
  state: SchemaUtil.enum(PUBLISHED_STATE),
});

function generic_entry_required(published = false) {
  return published ? ['cls', 'name', 'label'] : ['_id', 'cls', 'name', 'label', 'meta'];
}

function generic_entry_properties(published = false) {
  const props = {
    ...genericProps(published),
    meta: CFG_META_SCHEMA,
  };

  if (published) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete props._id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete props.meta;
  }
  return props;
}

// using a generic config with all fields to allow keeping values from old configs by switching the type
export const FIELD_CONFIG = {
  bsonType: 'object',
  additionalProperties: false,
  required: ['cls', 'type'],
  properties: {
    cls: {
      enum: [CLS.FIELD_CONFIG],
    },
    type: SchemaUtil.enum(FIELD_TYPES),
    valueType: SchemaUtil.enum(FORM_VALUE_TYPES),
    dataType: SchemaUtil.enum(DATA_VALUE_TYPE),
    value: {
      anyOf: [DATA_VALUE, calculation(), FIELD_REF],
    },
    labels: SchemaUtil.object(undefined, {
      yes: SchemaUtil.string({}),
      no: SchemaUtil.string({}),
    }),
    unit: SchemaUtil.string({ maxLength: 15 }),
    default: {
      anyOf: [DATA_VALUE, RELATIVE_DATE],
    },
    required: SchemaUtil.bool(),
    disabled: SchemaUtil.bool(),
    min: {
      anyOf: [DATA_VALUE, RELATIVE_DATE],
    },
    max: {
      anyOf: [DATA_VALUE, RELATIVE_DATE],
    },
    layout: SchemaUtil.object(undefined, {
      type: SchemaUtil.enum(SELECT_FIELD_LAYOUTS),
    }),
    //select
    dataHandler: {
      oneOf: [dynamicDataHandlerSchema(), listDataHandlerSchema()],
    },
    emptySelection: SchemaUtil.string({}),
    //text
    validation: SchemaUtil.enum(TEXTFIELD_VALIDATIONS),
    //date
    disabledDays: SchemaUtil.array(SchemaUtil.numeric()),
    inline: SchemaUtil.bool(),
    showOnFocus: SchemaUtil.bool(),
    showIcon: SchemaUtil.bool(),
    //submit btn
    btnLabel: SchemaUtil.string({}),
    successText: SchemaUtil.string({ maxLength: 300 }),
    showRequestId: SchemaUtil.bool(),
    linkRequestId: SchemaUtil.bool(),
    resetOnSubmit: SchemaUtil.bool(),
    multiline: SchemaUtil.bool(),
    digits: SchemaUtil.int(),
    step: SchemaUtil.numeric(),
    showButtons: SchemaUtil.bool(),
    goToPage: PAGE_REF,
  },
};

export function cfgConfigurationSchema(nested = false, published = false): JsonSchema {
  const schema = {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'children', 'settings', 'versions'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.CONFIGURATION],
      },
      versions: published ? PUBLISHED_PUBLIC_VERSION : PUBLISHED_CMS_VERSIONS,
      code: CONFIGURATION_SHORT_CODE,
      description: SchemaUtil.string({
        maxLength: 500,
        pattern: PATTERN.TEXT_MULTILINE,
      }),
      children: {
        bsonType: 'array',
        items: nested
          ? conditionalEntrySchema(CLS.PAGE_WRAPPER, cfgPageSchema(true, published))
          : conditionalEntrySchema(CLS.PAGE_WRAPPER),
      },
      trash: {
        bsonType: 'date',
      },
      //public settings
      settings: SchemaUtil.object(['cls'], {
        cls: {
          enum: [CLS.CONFIGURATION_SETTINGS],
        },
        customerEmailField: FIELD_REF,
      }),
      // internal settings
      internalSettings: SchemaUtil.object(undefined, {
        titleFields: SchemaUtil.array({ bsonType: [TYPES.STRING] }),
        initialState: SAVE_TEXT,
      }),
    },
  };

  return schema;
}

export function cfgPageSchema(nested = false, published = false): JsonSchema {
  return {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'children'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.PAGE],
      },
      hideLabel: {
        bsonType: 'bool',
      },
      noNavPrev: {
        bsonType: 'bool',
      },
      noNavNext: {
        bsonType: 'bool',
      },
      children: {
        bsonType: 'array',
        items: nested
          ? conditionalEntrySchema([CLS.FIELD_GROUP_WRAPPER, CLS.FIELD_WRAPPER], {
              bsonType: 'object',
              additionalProperties: true,
              anyOf: [cfgFieldGroupSchema(true, published), cfgFieldSchema(published)],
            } as JsonSchema)
          : conditionalEntrySchema([CLS.FIELD_GROUP_WRAPPER, CLS.FIELD_WRAPPER]),
      },
      info: INFO,
      trash: {
        bsonType: 'date',
      },
    },
  };
}

export function cfgFieldGroupSchema(nested = false, published = false): JsonSchema {
  return {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'children'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.FIELD_GROUP],
      },
      hideLabel: {
        bsonType: 'bool',
      },
      info: INFO,
      children: {
        bsonType: 'array',
        items: nested
          ? conditionalEntrySchema(CLS.FIELD_WRAPPER, cfgFieldSchema(published))
          : conditionalEntrySchema(CLS.FIELD_WRAPPER),
      },
    },
  };
}

export function cfgFieldSchema(published = false): JsonSchema {
  return {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'config'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.FIELD],
      },
      info: INFO,
      hideLabel: {
        bsonType: 'bool',
      },
      hint: SchemaUtil.string({
        maxLength: 300,
      }),
      config: FIELD_CONFIG,
    },
  };
}

export const CONFIGURATION_SCHEMA = {
  bsonType: 'object',
  additionalProperties: true,
  anyOf: [cfgConfigurationSchema(), cfgPageSchema(), cfgFieldGroupSchema(), cfgFieldSchema()],
} as JsonSchema;

export const PUBLISHED_CFG_SCHEMA = {
  bsonType: 'object',
  additionalProperties: false,
  required: ['_id', 'projectId', 'configurationId', 'version', 'configuration'],
  properties: {
    _id: SchemaUtil.objectId(),
    projectId: SchemaUtil.objectId(),
    configurationId: SchemaUtil.objectId(),
    version: {
      bsonType: 'number',
    },
    configuration: cfgConfigurationSchema(true, true),
  },
} as JsonSchema;
