<kfd-dialog-layout
  (accept)="onAccept()"
  (reject)="onReject()"
  [acceptDisabled]="!privacyAccepted"
  [disableScrolling]="true"
  [loading]="loading"
  [showAcceptBtn]="!rejected"
  [showRejectBtn]="!rejected"
  acceptBtnLabel="Zustimmen"
  dialogTitle="Zustimmung erforderlich"
  rejectBtnLabel="Ablehnen"
>
  <p-message
    severity="info"
    text="Für die weitere Verwendung unseres Services müssen Sie der aktuellen Datenschutzerklärung zustimmen"
  >
  </p-message>

  @if (rejected) {
    <p>
      <strong>Sie wurden abgemeldet</strong>
    </p>
    <p>
      Ohne eine Zustimmung zu unserer Datenschutzerklärung, können Sie unseren Service nicht länger in Anspruch nehmen.
    </p>
    <p>Wenn Sie sich erneut anmelden, können Sie der Datenschutzerklärung zustimmen.</p>
    <p>Sollten Sie eine Löschung ihres Benutzerkontos wünschen, wenden Sie sich bitte im Kontaktformular an uns.</p>

    <button (click)="reload()" icon="pi pi-check" label="Fenster schließen" pButton type="button"></button>
  } @else {
    <kfd-static-content identifier="privacy" class="mt-2"></kfd-static-content>

    <p-checkbox
      class="mt-2"
      label="Ich habe die Datenschutzerklärung gelesen und stimme den Bedinungen zu."
      [value]="true"
      [(ngModel)]="privacyAccepted"
    >
    </p-checkbox>
  }
</kfd-dialog-layout>
