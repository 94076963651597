import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../service/settings.service';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  constructor(public settingsService: SettingsService) {}

  transform(value: number): string {
    const locale = this.settingsService.locale();
    return new Intl.NumberFormat(locale).format(Number(value));
  }
}
