<i *ngIf="infoIconVisible === true" class="icon pi {{ infoIcon }}"></i>

@if (infoTitle) {
  <div class="text-gray-800 text-center text-xl mt-2">{{ infoTitle }}</div>
}

@if (infoText) {
  <div class="text-gray-600 text-center mt-2">{{ infoText }}</div>
}

@if (btnVisible || btnLink) {
  @if (btnLink) {
    <p-button
      [disabled]="btnDisabled"
      [label]="btnLabel"
      [rounded]="true"
      [text]="true"
      [routerLink]="btnLink"
      data-automationId="empty-msg-add-btn"
      class="mt-2"
      icon="pi {{ btnIcon }}"
    ></p-button>
  } @else {
    <p-button
      (click)="btnClick.emit()"
      [disabled]="btnDisabled"
      [label]="btnLabel"
      [rounded]="true"
      [text]="true"
      data-automationId="empty-msg-add-btn"
      class="mt-2"
      icon="pi {{ btnIcon }}"
    ></p-button>
  }
}
