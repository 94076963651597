import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { prettyPrintJson } from 'pretty-print-json';

@Pipe({
  name: 'prettyPrintJson',
})
@Injectable()
export class PrettyPrintJsonPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: string): string {
    return prettyPrintJson
      .toHtml(JSON.parse(value), {
        lineNumbers: false,
        linkUrls: true,
        trailingCommas: true,
        indent: 3,
      })
      .replace(/ {3}/g, '\u00a0\u00a0\u00a0');
  }
}
