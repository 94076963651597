<ng-container *ngIf="validationError$ | loading | async as validationError">
  @if (field) {
    <div
      *ngIf="field?.config"
      [attr.data-automationId]="'cfg-field-' + field.name"
      [ngClass]="{ invalid: validationError.value }"
      class="p-field"
    >
      <label *ngIf="!field.hideLabel" data-automationId="cfg-field-label">
        {{ field.label }}
        <ng-container *ngIf="field.config.required"> *</ng-container>
      </label>

      <kfd-configurator-info [info]="field.info"></kfd-configurator-info>

      <ng-container *ngIf="value$ | async as inputValue">
        <ng-container [ngSwitch]="field.config.type">
          <kfd-configurator-field-bool
            (valueChange)="onValueChange($event)"
            *ngSwitchCase="fieldTypes.YESNO"
            [field]="$any(field.config)"
            [value]="$any(inputValue)"
          ></kfd-configurator-field-bool>
          <kfd-configurator-field-numeric
            (valueChange)="onValueChange($event)"
            *ngSwitchCase="fieldTypes.NUMBER"
            [field]="$any(field.config)"
            [value]="$any(inputValue)"
          ></kfd-configurator-field-numeric>
          <kfd-configurator-field-text
            (valueChange)="onValueChange($event)"
            *ngSwitchCase="fieldTypes.TEXT"
            [field]="$any(field.config)"
            [value]="$any(inputValue)"
          ></kfd-configurator-field-text>
          <kfd-configurator-field-select
            (valueChange)="onValueChange($event)"
            *ngSwitchCase="fieldTypes.SELECT"
            [field]="$any(field.config)"
            [value]="$any(inputValue)"
          ></kfd-configurator-field-select>
          <kfd-configurator-field-date
            (valueChange)="onValueChange($event)"
            *ngSwitchCase="fieldTypes.DATE"
            [field]="$any(field.config)"
            [value]="$any(inputValue)"
          ></kfd-configurator-field-date>
          <kfd-configurator-field-readonly
            (valueChange)="onValueChange($event)"
            *ngSwitchCase="fieldTypes.DISPLAY_DATA"
            [field]="$any(field.config)"
            [name]="field.name"
          >
          </kfd-configurator-field-readonly>
          <kfd-cfg-field-submit-btn
            (valueChange)="onValueChange($event)"
            *ngSwitchCase="fieldTypes.SUBMITBTN"
            [demo]="demo"
            [field]="$any(field.config)"
          >
          </kfd-cfg-field-submit-btn>
          <kfd-cfg-reset-btn *ngSwitchCase="fieldTypes.RESETBTN" [demo]="demo" [field]="$any(field.config)">
          </kfd-cfg-reset-btn>
          <kfd-cfg-field-summary *ngSwitchCase="fieldTypes.SUMMARY" [demo]="demo" [field]="$any(field.config)">
          </kfd-cfg-field-summary>
        </ng-container>
      </ng-container>

      @if (field.hint) {
        <small class="hint">
          {{ field.hint }}
        </small>
      }
      <!-- todo use helper for more detailed error messages -->
      @if (validationError?.value !== undefined) {
        <div class="color-error">
          <span>{{ $any(validationError.value) | valerr }}</span>
        </div>
      }
    </div>
  }
</ng-container>
