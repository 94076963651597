import {
  CalcOperation,
  Calculation,
  CLS,
  Condition,
  DATA_VALUE_TYPE,
  FieldRef,
  FORM_VALUE_TYPES,
  FormValue,
  InputValue,
  Is,
  SingleFormValue,
  Value,
  ValueCalculation,
} from '../../dtos/index';
import { ConditionValidator } from '../condition/condition-validator';
import { Create } from '../../dtos/cls-creator';
import { DataUtil, ObjectUtil } from '../../common';

export interface FieldRefResolver {
  resolve(fieldRef: FieldRef): Promise<FormValue | Calculation>;
}

export class CalculationResolver {
  constructor(private fieldRefResolver: FieldRefResolver) {}

  public async resolve(calculation: Calculation, relevantFields: string[] = []): Promise<ValueCalculation> {
    const valueCalculation: ValueCalculation = ObjectUtil.clone(calculation) as ValueCalculation;
    for (let i = 0; i < calculation.params.length; i++) {
      const param = calculation.params[i];

      if (Is.value(param) || Is.aliasValue(param)) {
        continue;
      }

      if (Is.calculation(param)) {
        if (param.condition) {
          const isConditionValid = await this.isConditionValid(param.condition);
          if (!isConditionValid) {
            delete valueCalculation.params[i];
            continue;
          }
        }
        const subCalculation = await this.resolve(param as Calculation, relevantFields);

        // remove empty sub calculations
        if (subCalculation.params.length === 0) {
          delete valueCalculation.params[i];
          continue;
        }

        // remove sub calculation without relevant params
        const relevantSubParams = subCalculation.params.filter((dataValue) => {
          if (!Is.value(dataValue)) {
            return true;
          }
          return (dataValue as Value).type !== DATA_VALUE_TYPE.EMPTY;
        });
        if (relevantSubParams.length === 0) {
          delete valueCalculation.params[i];
          continue;
        }

        valueCalculation.params[i] = subCalculation;
        continue;
      }

      if (Is.fieldRef(param)) {
        const resolveFieldRef = await this.resolveFieldRef(param, relevantFields);
        if (resolveFieldRef) {
          valueCalculation.params[i] = resolveFieldRef;
          if (param.label) {
            valueCalculation.params[i].label = param.label;
          }
        }
        continue;
      }

      throw new Error('Unsupported parameter cannot be resolved: ' + JSON.stringify(param));
    }

    valueCalculation.params = valueCalculation.params.filter((param) => param !== null);
    return valueCalculation;
  }

  private async resolveFieldRef(fieldRef: FieldRef, relevantFields: string[] = []): Promise<Calculation | InputValue> {
    if (relevantFields.length > 0) {
      if (relevantFields.indexOf(fieldRef.name) === -1) {
        return Create.inputValue(DATA_VALUE_TYPE.EMPTY);
      }
    }
    const resolvedValue = await this.fieldRefResolver.resolve(fieldRef);
    if (Is.calculation(resolvedValue)) {
      return await this.resolve(resolvedValue, relevantFields);
    }

    if (Is.formValue(resolvedValue)) {
      if (Is.emptyFormValue(resolvedValue)) {
        return Create.inputValue(DATA_VALUE_TYPE.EMPTY);
      }
      if (Is.singleFormValue(resolvedValue)) {
        return resolvedValue.input;
      }
      if (Is.singleSelectionFormValue(resolvedValue)) {
        return DataUtil.selectionDataValue(resolvedValue.selection, fieldRef.property);
      }

      if (Is.multiSelectionFormValue(resolvedValue)) {
        return Create.calculation(DataUtil.selectionDataValues(resolvedValue.selection), CalcOperation.ADD);
      }
      if (Is.multiFormValue(resolvedValue)) {
        throw new Error(`Type "${resolvedValue.type}" is not (yet) supported for calculations`);
      }
      throw new Error(`Type "${resolvedValue.type}" is not supported for field references`);
    }

    throw new Error(`Failed to resolve field reference: ${fieldRef.name}`);
  }

  private async isConditionValid(condition: Condition): Promise<boolean> {
    try {
      if (condition.value1 === undefined || condition.value2 === undefined) {
        return false;
      }
      const givenFormValue = await this.fieldRefResolver.resolve(condition.value1);

      if (givenFormValue.cls === CLS.CALCULATION) {
        // todo implement
        return true;
      }

      switch (givenFormValue.type) {
        case FORM_VALUE_TYPES.SINGLE:
          return ConditionValidator.evaluateSingleValueCondition(
            (givenFormValue as SingleFormValue).input,
            condition.op,
            condition.value2,
          );
        case FORM_VALUE_TYPES.MULTI:
        case FORM_VALUE_TYPES.SINGLE_SELECTION:
        case FORM_VALUE_TYPES.MULTI_SELECTION:
        case FORM_VALUE_TYPES.EMPTY:
        default:
          throw new Error(`Type "${givenFormValue.type}" is not supported for conditions`);
      }
    } catch (e) {
      return false;
    }
  }
}
