import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Data, DATA_TYPES, DATA_VALUE_TYPE, SelectionData } from '@kfd/core';
import { formatDate, formatNumber } from '@angular/common';

@Pipe({
  name: 'dataValue',
})
export class DataValuePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(data: Data | Data[]): string | number | boolean | undefined {
    if (Array.isArray(data)) {
      const result = [];
      for (const entry of data) {
        result.push(this.convertData(entry));
      }
      return result.join(',');
    }

    return this.convertData(data);
  }

  convertData(data: Data): string | number | boolean | undefined {
    if (data.type === DATA_TYPES.INPUT) {
      if (!data?.value) {
        return undefined;
      }
      switch (data.value.type) {
        case DATA_VALUE_TYPE.NUMERIC:
          return formatNumber(data?.value?.value as number, this.locale);
        case DATA_VALUE_TYPE.DATE:
          return formatDate(data?.value?.value as string, 'longDate', this.locale);
        default:
          return data?.value?.value;
      }
    }
    if (data.type === DATA_TYPES.SELECTION) {
      return (data as SelectionData).label;
    }

    return;
  }
}
