/**
 * @internal
 * @hidden
 */
export function slice(arr, start, end, step) {
    function integer(val) {
        return String(val).match(/^[0-9]+$/) ? parseInt(val) :
            Number.isFinite(val) ? parseInt(val, 10) : 0;
    }
    if (typeof start == 'string')
        throw new Error("start cannot be a string");
    if (typeof end == 'string')
        throw new Error("end cannot be a string");
    if (typeof step == 'string')
        throw new Error("step cannot be a string");
    var len = arr.length;
    if (step === 0)
        throw new Error("step cannot be zero");
    step = step ? integer(step) : 1;
    // normalize negative values
    start = start < 0 ? len + start : start;
    end = end < 0 ? len + end : end;
    // default extents to extents
    start = integer(start === 0 ? 0 : !start ? (step > 0 ? 0 : len - 1) : start);
    end = integer(end === 0 ? 0 : !end ? (step > 0 ? len : -1) : end);
    // clamp extents
    start = step > 0 ? Math.max(0, start) : Math.min(len, start);
    end = step > 0 ? Math.min(end, len) : Math.max(-1, end);
    // return empty if extents are backwards
    if (step > 0 && end <= start)
        return [];
    if (step < 0 && start <= end)
        return [];
    var result = [];
    for (var i = start; i != end; i += step) {
        if ((step < 0 && i <= end) || (step > 0 && i >= end))
            break;
        result.push(arr[i]);
    }
    return result;
}
