<div class="value">
  @if (printValue$ | async; as printValue) {
    {{ printValue.value }}
  } @else if (staticValue) {
    {{ staticValue.value }}
  } @else {
    <div class="flex justify-content-center">
      <i class="color-disabled">Keine Wert vorhanden</i>
    </div>
  }
</div>
