import { Id } from './cms.dto';

export interface User {
  _id: Id;
  email: string;
  name: string;
  roles?: USER_ROLES[];
}

export interface JwtUser extends User {
  sessionId: string;
}

export interface Scoped {
  scopes: USER_SCOPES[];
}

export interface ScopedUser extends JwtUser, Scoped {}

export interface ScopedUserRef {
  userId: Id;
  scopes: USER_SCOPES[];
}

// global user roles
export enum USER_ROLES {
  ADMIN = 'kfd-admin',
  E2E = 'e2e',
}

// mandant specific user scopes
export enum USER_SCOPES {
  OWNER = 'owner',
  ADMIN = 'admin',
  PUBLISHER = 'publisher',
  MODELLER = 'modeller',
  AUTHOR = 'author',
  CUSTOMER_SERVICE = 'customer-service',
}

export const OWNER_ADMIN = [USER_SCOPES.OWNER, USER_SCOPES.ADMIN];

export interface WebPageContent {
  id: string;
  type: string;
  title: string;
  data: string | Record<string, unknown>;
}

export interface WebPage {
  id: string;
  title: string;
  content: WebPageContent[];
}

export interface DocuStructure {
  id?: string;
  title: string;
  children?: DocuStructure[];
}

export interface ContentLink {
  type: string;
  id: string;
}

export interface FaqPagEntry {
  question: string;
  answer: string;
  links: ContentLink[];
}

export interface FaqPage {
  content: string;
  entries: FaqPagEntry[];
}

export enum RequiredUserAction {
  NONE = 'none',
  EMAIL_ACTIVATION = 'activation',
  PRIVACY_ACKNOWLEDGE = 'privacy',
}
