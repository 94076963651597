export enum CFG_ICON {
  CHECK = 'pi-check',
  TIMES = 'pi-times',
  INFO = 'pi-info-circle',
  PREV = 'pi-angle-left',
  NEXT = 'pi-angle-right',
  UNDO = 'pi-undo',
  PLUS = 'pi-plus',
  MINUS = 'pi-minus',
  // configurable icons
  PLAY = 'pi-play',
  DOLLAR = 'pi-dollar',
  EURO = 'pi-euro',
  EMAIL = 'pi-at',
  PHONE = 'pi-phone',
  CALENDAR = 'pi-calendar',
  CAMERA = 'pi-camera',
  IMAGE = 'pi-image',
  ENVELOPE = 'pi-envelope',
}
