<div *ngIf="fieldConfig" class="input-wrapper">
  <p-calendar
    #calendarInput
    (ngModelChange)="onInputValueChange($event)"
    [appendTo]="!configService.embedded ? 'body' : undefined"
    [dateFormat]="localConfig.ngDateFormat"
    [disabledDays]="fieldConfig.disabledDays ? fieldConfig.disabledDays : []"
    [disabled]="fieldConfig.disabled === true"
    [firstDayOfWeek]="1"
    [inline]="fieldConfig.inline === true ? true : false"
    [maxDate]="$any(max$ | async)"
    [minDate]="$any(min$ | async)"
    [ngModel]="dateObject"
    [numberOfMonths]="1"
    [showButtonBar]="false"
    [showClear]="false"
    [showIcon]="fieldConfig.showIcon === false ? false : true"
    [showOnFocus]="fieldConfig.showOnFocus === true ? true : false"
    [touchUI]="false"
  >
  </p-calendar>
</div>
