import { Injectable } from '@angular/core';
import { Calculation, CalculationResolver, CalculationResult, Calculator } from '@kfd/core';
import { Observable } from 'rxjs';
import { ConfigurationStateService } from './configuration-state.service';
import { ConfigurationFieldRefResolver } from './configuration-fieldref-resolver';
import { ConfigurationValidationService } from './configuration-validation.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class CalculationService {
  private readonly calculationResolver: CalculationResolver;
  private calculator: Calculator;

  constructor(
    private configurationService: ConfigurationService,
    private stateService: ConfigurationStateService,
    private fieldRefResolver: ConfigurationFieldRefResolver,
    private validationService: ConfigurationValidationService,
  ) {
    this.calculationResolver = new CalculationResolver(fieldRefResolver);
    this.calculator = new Calculator();
  }

  public calc(calculation: Calculation): Observable<CalculationResult> {
    return new Observable((observer) => {
      //todo get list of relevant fields (eg condition service??)
      const relevantFields = this.configurationService.cfgUtil.getFieldNames();
      this.calculationResolver.resolve(calculation, relevantFields).then((valueCalculation) => {
        try {
          const result = this.calculator.calc(valueCalculation);
          observer.next(result);
          observer.complete();
        } catch (e) {
          observer.error(e);
          observer.complete();
        }
      });
    });
  }
}
