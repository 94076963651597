import { Component, Input } from '@angular/core';
import { FieldGroup } from '@kfd/core';

@Component({
  selector: 'kfd-configurator-question',
  templateUrl: './configurator-question.component.html',
  styleUrls: ['./configurator-question.component.scss'],
})
export class ConfiguratorQuestionComponent {
  @Input()
  question: FieldGroup | undefined;
}
