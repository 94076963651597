<div class="m-2" data-automationId="activation-form">
  <h1>Aktivierung</h1>
  @if (success) {
    <p-card data-automationId="activation-successful-message">
      <div class="p-fluid">
        <p-message severity="success" text="Die E-Mail Adresse wurde erfolgreich aktiviert"></p-message>
        <p>Sie können sich jetzt mit ihrer E-Mail Adresse anmelden</p>
      </div>
      <p-footer>
        <p-toolbar styleClass="invisible no-padding">
          <div class="p-toolbar-group-left"></div>
          <div class="p-toolbar-group-right">
            <p-button
              icon="pi {{ ICON.NEXT }}"
              label="Zur Anmeldung"
              data-automationId="to-sign-in-button"
              [queryParams]="{ email: email }"
              [routerLink]="COMMON_WEB_APP_ROUTES.user.login"
            ></p-button>
          </div>
        </p-toolbar>
      </p-footer>
    </p-card>
  } @else {
    <p-card class="mt-2" header="E-Mail Adresse bestätigen">
      <p>
        Bitte prüfen Sie Ihr E-Mail Postfach und geben Sie den Bestätigungscode ein, den Sie von uns erhalten haben.
      </p>
      <div class="p-fluid mt-4">
        <p-message *ngIf="errorText" [text]="errorText" severity="error"></p-message>

        <div class="p-field">
          <label>E-Mail Adresse</label>
          <input [(ngModel)]="email" pInputText placeholder="" data-automationId="email-input" type="text" />
        </div>
        <div class="p-field">
          <label>Bestätigungscode</label>
          <input [(ngModel)]="code" pInputText placeholder="" data-automationId="code-input" type="text" />
        </div>
      </div>
      <p-button
        class="mt-2 block"
        icon="pi {{ ICON.REFRESH }}"
        label="Probleme? Bestätigungs E-Mail erneut versenden"
        [text]="true"
        [loading]="loading"
        [routerLink]="COMMON_WEB_APP_ROUTES.user.resendVerification.root"
      ></p-button>
      <p-footer>
        <p-toolbar styleClass="invisible no-padding">
          <div class="p-toolbar-group-left"></div>
          <div class="p-toolbar-group-right">
            <p-button
              icon="pi {{ ICON.ACCEPT }}"
              label="E-Mail Adresse aktivieren"
              data-automationId="submit-activation-form-btn"
              [rounded]="true"
              [outlined]="true"
              [disabled]="!email || !code"
              [loading]="loading"
              (click)="activate()"
            ></p-button>
          </div>
        </p-toolbar>
      </p-footer>
    </p-card>
  }
</div>
