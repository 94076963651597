import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, MessageService } from '../../lib/services';

@Component({
  selector: 'kfd-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  email = '';
  loading = false;
  success = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _authService: AuthService,
    private _messageService: MessageService,
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.has('email')) {
      this.email = <string>this.route.snapshot.paramMap.get('email');
    }
  }

  reset() {
    if (this.email.length < 3) {
      return;
    }
    this.loading = true;
    this._authService.passwordReset(this.email).subscribe(
      () => {
        this.email = '';
        this._messageService.showSuccess(
          'Bitte prüfen Sie Ihr Postfach',
          'Und folgen Sie den Anweisungen in der E-Mail',
        );
        this.loading = false;
        this.success = true;
      },
      (response) => {
        this._messageService.showError('Ups', response?.error?.message);
        this.loading = false;
      },
    );
  }
}
