import { AliasValue, Calculation, CalculationParam, CLS, FieldRef } from '../../dtos/index';

/**
 * Helper class to handle all calculation related stuff
 */
export class CalculationHandler {
  // private _calculation: Calculation;
  private _aliasses: string[] = [];
  private _fieldRefs: FieldRef[] = [];

  constructor(calculation: Calculation) {
    // this._calculation = calculation;

    this.iterateLeafs(calculation, (param: CalculationParam) => {
      switch (param.cls) {
        case CLS.FIELD_REF:
          this._fieldRefs.push(param);
          break;
        case CLS.ALIAS_VALUE:
        case CLS.CALCULATION:
          // eslint-disable-next-line no-case-declarations
          const calculation = param as Calculation;
          if (calculation.alias) {
            this._aliasses.push(calculation.alias);
          }
      }
    });
  }

  public getAliases(): string[] {
    return this._aliasses;
  }

  public getFieldRefs(): FieldRef[] {
    return this._fieldRefs;
  }

  public validate(calculation: Calculation) {
    if (!calculation.params || calculation.params.length === 0) {
      throw new Error('Empty params are not allowed');
    }

    this.iterateLeafs(calculation, (param: CalculationParam) => {
      switch (param.cls) {
        case CLS.FIELD_REF:
          // do nothing
          break;
        case CLS.ALIAS_VALUE:
          if (this._aliasses.indexOf((param as AliasValue).name) === -1) {
            throw new TypeError('Unknown alias: ' + (param as AliasValue).name);
          }
          break;
        case CLS.INPUT_VALUE:
        case CLS.SELECTION_VALUE:
          // do nothing
          break;
      }
    });
  }

  private iterateLeafs(calculation: Calculation, cbFn: CallableFunction) {
    cbFn(calculation);

    for (const param of calculation.params) {
      const cls = param.cls;
      switch (param.cls) {
        case CLS.CALCULATION:
          this.iterateLeafs(param as Calculation, cbFn);
          continue;
        case CLS.FIELD_REF:
        case CLS.ALIAS_VALUE:
        case CLS.INPUT_VALUE:
        case CLS.SELECTION_VALUE:
          cbFn(param);
          continue;
        default:
          throw new TypeError('Unknown type: ' + cls);
      }
    }
  }
}
