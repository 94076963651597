import { Component, Input } from '@angular/core';
import { Info } from '@kfd/core';

@Component({
  selector: 'kfd-configurator-info',
  templateUrl: './configurator-info.component.html',
  styleUrls: ['./configurator-info.component.scss'],
})
export class ConfiguratorInfoComponent {
  @Input()
  info: Info | undefined;
}
