export class NumberUtil {
  public static toNumber(value: string | number | boolean): number {
    const parsed = parseInt(value.toString());
    if (isNaN(parsed)) {
      throw new Error(`Cannot convert to number`);
    }
    return parsed;
  }

  public static round(value: number, digits = 2): number {
    if (isNaN(digits)) {
      throw new Error(`Round error: invalid digits value ${digits}`);
    }
    const factor = Math.pow(10, digits);
    const m = Number((Math.abs(value) * factor).toPrecision(15));
    return (Math.round(m) / factor) * Math.sign(value);
  }
}
