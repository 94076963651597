import dayjs from 'dayjs';
import { RelativeDate, SAVE_DATE_FORMAT, START_DATES } from '../dtos/index';

export class DateHandler {
  public static resolveByStartDate(relativeDate: RelativeDate, startDateString?: string): Date {
    let startDate: Date;

    if (!startDateString) {
      switch (relativeDate.start.name) {
        case START_DATES.WEEK_START:
          startDate = dayjs().startOf('week').toDate();
          break;
        case START_DATES.WEEK_END:
          startDate = dayjs().endOf('week').toDate();
          break;
        case START_DATES.MONTH_START:
          startDate = dayjs().startOf('month').toDate();
          break;
        case START_DATES.MONTH_END:
          startDate = dayjs().endOf('month').toDate();
          break;
        case START_DATES.TODAY:
          startDate = new Date();
          break;
        default:
          throw new Error(`Unsupported start date value "${relativeDate.start}"`);
      }
    } else {
      startDate = dayjs(startDateString, SAVE_DATE_FORMAT).toDate();
    }

    if (!dayjs(startDate).isValid()) {
      throw new Error(`Invalid start date "${startDate}"`);
    }

    const minDateValue =
      relativeDate.manipulation === '+'
        ? dayjs(startDate).add(relativeDate.value, relativeDate.unit)
        : dayjs(startDate).subtract(relativeDate.value, relativeDate.unit);
    return minDateValue.toDate();
  }
}
