export enum COMMON_APP_ROUTE_PARTS {
  AUTH = 'auth',
  LOGIN = 'login',
  REGISTRATION = 'registration',
  PROFILE = 'profile',
  ACTIVATION = 'activation',
  RESEND_VERIFICATION = 'resend-verification',
  PASSWORD_RESET = 'password-reset',
}

export enum COMMON_APP_ROUTE_PARAMS {
  EMAIL = 'email',
  CODE = 'code',
}

export const COMMON_WEB_APP_ROUTES = {
  user: {
    login: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.LOGIN}`,
    registration: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.REGISTRATION}`,
    profile: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.PROFILE}`,
    activation: {
      root: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.ACTIVATION}`,
      code: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.ACTIVATION}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}/:${COMMON_APP_ROUTE_PARAMS.CODE}`,
    },
    resendVerification: {
      root: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.RESEND_VERIFICATION}`,
      code: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.RESEND_VERIFICATION}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}/:${COMMON_APP_ROUTE_PARAMS.CODE}`,
    },
    passwordReset: {
      root: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.PASSWORD_RESET}`,
      code: `/${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.PASSWORD_RESET}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}/:${COMMON_APP_ROUTE_PARAMS.CODE}`,
    },
  },
};
