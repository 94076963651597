<div class="content-limiter">
  <h1>Passwort zurücksetzen</h1>

  <p-card [kfdBlock]="loading" class="mt-2" header="Passwort vergessen?">
    <form (submit)="reset()">
      <div class="p-fluid">
        <div class="p-field">
          <label>E-Mail Adresse</label>
          <input [(ngModel)]="email" name="email" pInputText placeholder="" type="text" />
          <small>
            Wenn die E-Mail Adresse einem Konto zugeordnet werden kann, bekommen Sie eine E-Mail zum Zurücksetzen Ihres
            Passworts zugesendet.
          </small>
        </div>
      </div>
    </form>
    <p-footer>
      <p-toolbar styleClass="invisible no-padding">
        <div class="p-toolbar-group-left"></div>
        <div class="p-toolbar-group-right">
          <button
            (click)="reset()"
            [disabled]="email.length < 3"
            [loading]="loading"
            icon="pi pi-check"
            label="Passwort zurücksetzen"
            pButton
            type="button"
          ></button>
        </div>
      </p-toolbar>
    </p-footer>
  </p-card>
</div>
