import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { ConfigurationConditionService } from '../../service/configuration-condition.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-configurator-condition',
  templateUrl: './configurator-condition.component.html',
  styleUrls: ['./configurator-condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorConditionComponent {
  @Output()
  public conditionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ContentChild('caseTrue', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected caseTrue: TemplateRef<any> | null = null;

  @ContentChild('caseFalse', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected caseFalse: TemplateRef<any> | null = null;

  protected conditionFulfilled$: Observable<{
    fullfilled: boolean;
  }> = of({
    fullfilled: false,
  });

  constructor(private configuratorConditionService: ConfigurationConditionService) {}

  @Input()
  public set name(name: string) {
    this.conditionFulfilled$ = this.configuratorConditionService.onEntryRelevanceChange(name).pipe(
      tap((isTrue) => this.conditionChange.emit(isTrue)),
      map((isTrue) => ({
        fullfilled: isTrue,
      })),
    );
  }
}
