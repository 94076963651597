import { Component, Input } from '@angular/core';
import { ResetButtonFieldConfig } from '@kfd/core';
import { PaginationService } from '../../service/PaginationService';
import { ConfigurationStateService } from '../../service/configuration-state.service';
import { CFG_ICON } from '../../../globals';

@Component({
  selector: 'kfd-cfg-reset-btn',
  templateUrl: './cfg-reset-btn.component.html',
  styleUrls: ['./cfg-reset-btn.component.scss'],
})
export class CfgResetBtnComponent {
  @Input()
  public demo = false;
  protected readonly CFG_ICON = CFG_ICON;

  constructor(
    private paginationService: PaginationService,
    private cfgStateService: ConfigurationStateService,
  ) {}

  private _field: ResetButtonFieldConfig | undefined;

  get field(): ResetButtonFieldConfig | undefined {
    return this._field;
  }

  @Input()
  set field(value: ResetButtonFieldConfig | undefined) {
    if (!value) {
      return;
    }
    this._field = value;
  }

  reset() {
    this.cfgStateService.removeAllValues();
    this.paginationService.goToIndex(0);
  }
}
