import { Component, Input } from '@angular/core';
import { WebPage } from '@kfd/core';

@Component({
  selector: 'kfd-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
})
export class PageContentComponent {
  @Input()
  page: WebPage | undefined;

  @Input()
  showTitle = true;

  @Input()
  sectionId: string | undefined;

  @Input()
  showToc = true;
}
