import {
  DATA_VALUE_TYPE,
  FIELD_TYPES,
  FieldConfig,
  FormValue,
  Is,
  MultiSelectFieldConfig,
  MultiSelectionFormValue,
  NumericFieldConfig,
  SelectFieldConfig,
  SingleFormValue,
  SingleSelectionFormValue,
  TEXTFIELD_VALIDATIONS,
  TextFieldConfig,
  TextInputValue,
  VALIDATION_ERROR_DETAILS,
  VALIDATION_ERROR_TYPE,
  ValidationError,
  Value,
} from '../../dtos/index';

import { DataUtil } from '../../common/data-util';
import Validator from 'validatorjs';
import { Create } from '../../dtos';

// Validator.register(
//   'telephone',
//   (value: any): boolean => {
//     //regex to test a generic phone number with international prefix
//     return String(value).match(/^\+?\d{1,4}[-\s]?\d{3}[-\s]?\d{3}[-\s]?\d{4}$/) !== null;
//   },
//   'The :attribute phone number is not in the format XXX-XXX-XXXX.',
// );

export class InputValidator {
  /**
   * validate fields based on the field config
   * form values should match the allowed types and value formats
   * @param fieldConfig
   * @param formValue
   */
  public static validateField(fieldConfig: FieldConfig, formValue: FormValue): ValidationError | undefined {
    // if(Is.displayDataField(fieldConfig)) {
    //   todo implement based on field config. the formvalue should match the field config and not the other way around
    // }

    if (Is.emptyFormValue(formValue)) {
      if (fieldConfig.required === true) {
        return Create.validationError(VALIDATION_ERROR_TYPE.REQUIRED, VALIDATION_ERROR_DETAILS.UNKNOWN);
      }
      return undefined;
    }

    if (Is.singleFormValue(formValue)) {
      return this.validateSingleValue(fieldConfig, formValue);
    }

    if (Is.singleSelectionFormValue(formValue)) {
      return this.validateSingleSelectionValues(fieldConfig as SelectFieldConfig, formValue);
    }

    if (Is.multiSelectionFormValue(formValue)) {
      return this.validateMultiSelectionValues(fieldConfig as SelectFieldConfig, formValue);
    }

    if (Is.multiFormValue(formValue)) {
      throw new Error(`Multi type is not yet supported for validations`);
    }

    throw new Error(`Unsupported form value type "${formValue.type}"`);
  }

  public static validateNumericValue(config: NumericFieldConfig, dataValue: Value): ValidationError | undefined {
    if (dataValue.type === DATA_VALUE_TYPE.EMPTY) {
      if (config.required) {
        return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.EMPTY);
      }
      return;
    }
    if (Array.isArray(dataValue)) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.UNKNOWN);
    }
    const value: number = parseInt('' + dataValue?.value);
    if (!!config.required && isNaN(value)) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.EMPTY);
    }
    if (config.min?.value !== undefined && config.min.value > value) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.TO_SMALL, {
        max: config.min.value,
      });
    }
    if (config.max?.value !== undefined && config.max.value < value) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.TO_HIGH, {
        max: config.max.value,
      });
    }
    if (config.step !== undefined) {
      const rest = value % config.step;
      if (rest > 0) {
        return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.INV_NUMBER, {
          step: config.step,
        });
      }
    }
    return;
  }

  public static validateTextValue(config: TextFieldConfig, dataValue: Value): ValidationError | undefined {
    if (dataValue.type === DATA_VALUE_TYPE.EMPTY) {
      if (config.required) {
        return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.EMPTY);
      }
      return;
    }
    if (Array.isArray(dataValue)) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.UNKNOWN);
    }
    const value: string = (dataValue as TextInputValue)?.value;
    if (config.required === true && (value.length === 0 || typeof value !== 'string')) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.EMPTY);
    }
    if (value.length === 0) {
      return;
    }
    if (config.max && config.max.value < value.length) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.TO_LONG, {
        max: config.max.value,
      });
    }
    if (config.min && config.min.value > value.length) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.TO_SHORT, {
        min: config.min.value,
      });
    }
    if (!config.multiline && value.match(/(\r\n|\n|\r)/g)) {
      return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.INV_CHR);
    }
    if (config.validation) {
      switch (config.validation) {
        case TEXTFIELD_VALIDATIONS.EMAIL:
          if (new Validator({ value }, { value: 'email' }).fails()) {
            return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.INV_FORMAT, {
              type: 'email',
            });
          }
          break;
        case TEXTFIELD_VALIDATIONS.LINK:
          if (new Validator({ value }, { value: 'url' }).fails()) {
            return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.INV_FORMAT, {
              type: 'url',
            });
          }
          break;
        case TEXTFIELD_VALIDATIONS.PHONE:
          if (!value.match(/^\+?\d{1,4}[-\s]?\d{3}[-\s]?\d{3}[-\s]?\d{4}$/)) {
            return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.INV_FORMAT, {
              type: 'telephone',
            });
          }
          break;
        case TEXTFIELD_VALIDATIONS.DATE:
          if (new Validator({ value }, { value: 'date' }).fails()) {
            return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.INV_FORMAT, {
              type: 'date',
            });
          }
          break;
        case TEXTFIELD_VALIDATIONS.TEXT:
          break;
        default:
          throw new Error('Unsupported validation: ' + config.validation);
      }
    }
    return;
  }

  private static validateSingleSelectionValues(
    fieldConfig: SelectFieldConfig,
    formValue: SingleSelectionFormValue,
  ): ValidationError | undefined {
    //validate required
    if (fieldConfig.required) {
      if (!formValue.selection || Is.emptyFormValue(formValue.selection)) {
        return Create.validationError(VALIDATION_ERROR_TYPE.REQUIRED, VALIDATION_ERROR_DETAILS.UNKNOWN);
      }
    }
    return;
  }

  private static validateMultiSelectionValues(
    fieldConfig: MultiSelectFieldConfig,
    formValue: MultiSelectionFormValue,
  ): ValidationError | undefined {
    //validate required
    if (fieldConfig.required) {
      if (formValue.selection.length === 0) {
        return Create.validationError(VALIDATION_ERROR_TYPE.REQUIRED, VALIDATION_ERROR_DETAILS.UNKNOWN);
      }
    }

    //validate min
    if (fieldConfig.min) {
      if (fieldConfig.min.value > formValue.selection.length) {
        return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.TO_SMALL, {
          min: fieldConfig.min.value,
        });
      }
    }

    //validate max
    if (fieldConfig.max) {
      if (fieldConfig.max.value < formValue.selection.length) {
        return Create.validationError(VALIDATION_ERROR_TYPE.INVALID, VALIDATION_ERROR_DETAILS.TO_HIGH, {
          min: fieldConfig.max.value,
        });
      }
    }

    return;
  }

  private static validateSingleValue(
    fieldConfig: FieldConfig,
    formValue: SingleFormValue,
  ): ValidationError | undefined {
    const singleFormValues: SingleFormValue[] = DataUtil.dataValueToArray(formValue) as SingleFormValue[];
    if (singleFormValues.length === 0) {
      if (fieldConfig.required === true) {
        return Create.validationError(VALIDATION_ERROR_TYPE.REQUIRED, VALIDATION_ERROR_DETAILS.UNKNOWN);
      }
      return;
    }

    switch (fieldConfig.type) {
      case FIELD_TYPES.SELECT:
        // should be handled in validateSelectionValues
        throw new Error('Select types are not supported for single values');
        return;
      case FIELD_TYPES.NUMBER:
        for (const singleFormValue of singleFormValues) {
          const validationError = InputValidator.validateNumericValue(
            fieldConfig as NumericFieldConfig,
            singleFormValue.input,
          );
          if (validationError) {
            return validationError;
          }
        }
        return;
      case FIELD_TYPES.TEXT:
        for (const singleFormValue of singleFormValues) {
          const validationError = InputValidator.validateTextValue(
            fieldConfig as TextFieldConfig,
            singleFormValue.input,
          );
          if (validationError) {
            return validationError;
          }
        }
        return;
      case FIELD_TYPES.DATE:
        // todo implement
        return;
      default:
        return;
    }
  }
}
