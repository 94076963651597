<div class="content-limiter">
  <h1>Passwort setzen</h1>

  <p-card class="mt-2" header="Neues Passwort">
    <div class="p-fluid">
      <div class="p-field">
        <label>E-Mail Adresse</label>
        <input [(ngModel)]="email" pInputText placeholder="" type="text" />
      </div>
      <div class="p-field">
        <label>Bestätigungscode</label>
        <input [(ngModel)]="code" pInputText placeholder="" type="text" />
      </div>
      <div class="p-field">
        <label>Passwort</label>
        <input [(ngModel)]="password1" pInputText placeholder="" type="password" />
        <small *ngIf="password1 && password1.length < 8">
          Aus Sicherheitsgründen empfehlen wir ein komplexes Passwort zu wählen
        </small>
      </div>
      <div class="p-field">
        <label>Wiederholung</label>
        <input [(ngModel)]="password2" pInputText placeholder="" type="password" />
        <small *ngIf="password2 && password2.length > 0 && password1 !== password2" class="p-error"
          >Die Passwörter stimmen nicht überein</small
        >
      </div>
    </div>
    <p-footer>
      <p-toolbar styleClass="invisible no-padding">
        <div class="p-toolbar-group-left"></div>
        <div class="p-toolbar-group-right">
          <button
            (click)="setPassword()"
            [disabled]="!password1 || password1 !== password2"
            icon="pi pi-key"
            label="Neues Passwort setzen"
            pButton
            type="button"
          ></button>
        </div>
      </p-toolbar>
    </p-footer>
  </p-card>
</div>
