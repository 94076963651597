<ng-container *ngIf="info">
  <ng-container *ngIf="info.modal">
    <div [title]="info.label" class="icon">?</div>
  </ng-container>
  <ng-container *ngIf="!info.modal">
    {{ info.label }}
    <ng-container *ngFor="let item of info.content">
      {{ item }}
    </ng-container>
  </ng-container>
</ng-container>
