import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { COMMON_WEB_APP_ROUTES, KFD_WEB_APP_ROUTES } from '@kfd/core';
import { debounceTime, map, mergeMap, startWith } from 'rxjs/operators';
import { Observable, of, tap } from 'rxjs';
import { AuthService } from '../../lib/services/auth.service';
import { ConfigService } from '../../lib/services/config.service';

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return !!control.value.password1 && control.value.password1 === control.value.password2
    ? null
    : { PasswordNoMatch: true };
};

@Component({
  selector: 'kfd-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss',
})
export class SignUpComponent {
  protected loading = false;
  protected signUpError = false;
  protected signUpSuccess = false;
  protected signUpForm: FormGroup;
  protected privacyLink = '';
  protected mailExists$: Observable<{ value: boolean }> = of({ value: false });
  protected checkingMail = false;
  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private configService: ConfigService,
  ) {
    this.signUpForm = this.formBuilder.group(
      {
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password1: new FormControl('', [Validators.required, Validators.minLength(8)]),
        password2: new FormControl('', [Validators.required, Validators.minLength(8)]),
        privacyAccepted: new FormControl(false, [Validators.required, Validators.requiredTrue]),
      },
      {
        validators: [confirmPasswordValidator],
      },
    );
    this.privacyLink = configService.webApp + KFD_WEB_APP_ROUTES.legal.privacy;

    const mailControl = this.signUpForm.get('email');
    if (!mailControl) {
      throw new Error('Mail control not found');
    }
    this.mailExists$ = mailControl.valueChanges.pipe(
      startWith(''),
      tap(() => (this.checkingMail = true)),
      debounceTime(500),
      mergeMap((email) => {
        if (mailControl.valid) {
          return this.authService.userExists(email);
        }
        return of(false);
      }),
      tap(() => (this.checkingMail = false)),
      map((exists) => ({ value: exists })),
    );
  }

  protected signUp() {
    Object.keys(this.signUpForm.controls).forEach((fieldName) => this.signUpForm.controls[fieldName].markAsDirty());

    this.signUpForm.updateValueAndValidity();

    // Object.keys(this.signUpForm.controls).forEach(key => {
    //   const controlErrors: ValidationErrors | null | undefined = this.signUpForm.get(key)?.errors;
    //   if (controlErrors != null) {
    //     Object.keys(controlErrors).forEach(keyError => {
    //       console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
    //     });
    //   }
    // });

    if (!this.signUpForm.valid) {
      return;
    }
    this.loading = true;

    const email = this.signUpForm.get<string>('email')?.value;
    const password = this.signUpForm.get<string>('password1')?.value;
    const name = this.signUpForm.get<string>('name')?.value;
    const privacyAccepted = this.signUpForm.get<string>('privacyAccepted')?.value;

    this.signUpError = false;
    this.authService.signUp(email, password, name, !!privacyAccepted).subscribe({
      next: () => {
        this.signUpError = false;
        this.signUpSuccess = true;
        this.loading = false;
      },
      error: () => {
        this.signUpError = true;
        this.signUpSuccess = false;
        this.loading = false;
      },
    });
  }
}
