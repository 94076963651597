<ng-container *ngIf="signed$ | async as signed">
  <ng-container *ngIf="signed.in">
    <span *ngIf="user$ | async as user">{{ user.name }}&nbsp;</span>
  </ng-container>
  <p-button
    (click)="op.toggle($event)"
    [loading]="loading"
    [outlined]="true"
    [rounded]="true"
    icon="pi {{ icon.USER }}"
    styleClass="white"
  ></p-button>

  <p-overlayPanel #op appendTo="body" styleClass="">
    <ng-template pTemplate>
      <div [kfdBlock]="loading" class="flex flex-column">
        <ng-container *ngIf="signed.in; else signedOut">
          <ng-container *ngIf="user$ | async as user">
            <span *ngIf="user.name">Hallo {{ user.name }}</span>
          </ng-container>
          <ng-content></ng-content>
          <button
            (click)="op.hide()"
            class="p-button-text mt-2"
            icon="pi {{ icon.USER }}"
            label="Profil"
            pButton
            routerLink="/auth/profile"
            type="button"
          ></button>
          <button
            (click)="logout(); op.hide()"
            class="p-button-text mt-2"
            icon="pi {{ icon.SIGN_OUT }}"
            label="Abmelden"
            pButton
            type="button"
          ></button>
        </ng-container>
      </div>
    </ng-template>
  </p-overlayPanel>

  <ng-template #signedOut>
    <button
      (click)="op.hide()"
      class="p-button-text mt-2"
      icon="pi {{ icon.SIGN_IN }}"
      label="Anmelden / Registrieren"
      pButton
      routerLink="/auth"
      type="button"
    ></button>
  </ng-template>
</ng-container>
