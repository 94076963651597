import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UnauthorizedError } from '../common';

const CSRF_PROTECTED_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (CSRF_PROTECTED_METHODS.includes(req.method)) {
      req = req.clone({
        headers: req.headers.set('x-csrf-token', this.authService.csrfToken),
      });
    }

    // Handle response
    return next.handle(req).pipe(
      catchError((error) => {
        if (req.url.match(/^.*auth\/refresh$/)) {
          return next.handle(req);
        }
        // return next.handle(req)
        return this.handleResponseError(error, req, next);
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleResponseError(response: HttpErrorResponse, req: HttpRequest<unknown>, next: HttpHandler) {
    switch (response.status) {
      case 401:
        return this.authService.refreshToken().pipe(
          switchMap(() => {
            return next.handle(req);
          }),
          catchError(() => {
            return throwError(() => new UnauthorizedError());
          }),
        );
        break;
      case 403:
        if (!response.error.message.includes('csrf')) {
          return throwError(response);
        }
        return this.authService.updateCsrfToken().pipe(
          switchMap((csrfToken) => {
            req = req.clone({
              headers: req.headers.set('x-csrf-token', csrfToken),
            });
            return next.handle(req);
          }),
        );
        break;
      default:
        return throwError(response);
    }
  }
}
