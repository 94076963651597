<div class="m-2">
  <h1>Aktivierung</h1>
  @if (success) {
    <p-card>
      <div class="p-fluid">
        <p-message severity="success" text="Die Bestätiguns E-Mail wurde versendet"></p-message>
        <p>Bitte prüfen Sie Ihr Postfach und gegebenfalls den Spam oder Junk Ordner.</p>
      </div>
      <p-footer>
        <p-toolbar styleClass="invisible no-padding">
          <div class="p-toolbar-group-left"></div>
          <div class="p-toolbar-group-right">
            <p-button
              icon="pi {{ ICON.ACCEPT }}"
              label="Zur Anmeldung"
              [outlined]="true"
              [rounded]="true"
              [queryParams]="{ email: email }"
              [routerLink]="COMMON_WEB_APP_ROUTES.user.activation.root"
            ></p-button>
          </div>
        </p-toolbar>
      </p-footer>
    </p-card>
  } @else {
    <p-card class="mt-2" header="Bestätiguns E-Mail erneut versenden">
      <p>
        Sollten Sie keine E-Mail zur Aktivierung erhalten haben, können Sie sich hier eine neue E-Mail zusenden lassen.
      </p>
      <div class="p-fluid mt-4">
        <div class="p-field">
          <label>E-Mail Adresse</label>
          <input [(ngModel)]="email" pInputText placeholder="" type="email" />
        </div>
        <div class="p-field">
          <label>Passwort</label>
          <input [(ngModel)]="password" pInputText placeholder="" type="password" />
        </div>
      </div>
      <p-button
        class="mt-2 block"
        icon="pi {{ ICON.NEXT }}"
        label="Zur Aktivierung"
        [text]="true"
        [routerLink]="COMMON_WEB_APP_ROUTES.user.activation.root"
      ></p-button>

      <p-message class="mt-2" *ngIf="errorText" [text]="errorText" severity="error"></p-message>

      <p-footer>
        <p-toolbar styleClass="invisible no-padding">
          <div class="p-toolbar-group-left"></div>
          <div class="p-toolbar-group-right">
            <p-button
              icon="pi {{ ICON.ACCEPT }}"
              label="E-Mail Adresse aktivieren"
              [rounded]="true"
              [outlined]="true"
              [disabled]="!email || !password"
              [loading]="loading"
              (click)="resendActivation()"
            ></p-button>
          </div>
        </p-toolbar>
      </p-footer>
    </p-card>
  }
</div>
