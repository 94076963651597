<ng-container *ngIf="calculationResult$ | async as calculationResult">
  <ng-container
    [ngTemplateOutletContext]="{ result: calculationResult, showLabel: true }"
    [ngTemplateOutlet]="resultTpl"
  >
  </ng-container>
</ng-container>

<ng-template #resultTpl let-result="result" let-showLabel="showLabel">
  <ng-container *ngIf="result.results">
    <div class="calculation-wrapper">
      <ng-container *ngFor="let result of result.results">
        <ng-container [ngTemplateOutletContext]="{ result: result }" [ngTemplateOutlet]="resultTpl"></ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div *ngIf="result.label || showLabel === true" class="calculation-step">
    {{ result.label.text }}: {{ result.value }}
    <ng-container *ngIf="result.label.format">
      {{ result.label.format }}
    </ng-container>
  </div>
</ng-template>
