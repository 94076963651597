<label *ngIf="label" [for]="inputId">{{ label }}</label>
<p-selectButton
  (ngModelChange)="onValueChange()"
  [(ngModel)]="value"
  [id]="inputId"
  [options]="boolValues"
  optionLabel="name"
  optionValue="value"
>
</p-selectButton>
