export class ArrayUtil {
  public static filterUndefined<T>(itemList: (T | undefined)[]): T[] {
    return itemList.filter((item) => item !== undefined) as T[];
  }

  public static filterUnset<T>(itemList: (T | undefined | null)[]): T[] {
    return itemList.filter((item) => item !== undefined && item !== null) as T[];
  }

  public static distinct<T>(itemList: T[]): T[] {
    return [...new Set(itemList)];
  }

  /**
   * removes duplicate objects from an array by defining one key property
   *
   * const uniqueResult = ArrayUtil.unique([{value:'name',label:'Name'}, {value:'name',label:'Name 2'}], 'name')
   *
   * By default, the latest found match will be taken setting preferFirst to true takes the first found result
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static uniqueByKey<T extends Record<any, any>>(arr: T[], key: string, preferFirst = false): T[] {
    if (preferFirst === true) {
      const result = new Map<string, unknown>();
      for (const item of arr) {
        if (item && item[key]) {
          if (!result.has(item[key])) {
            result.set(item[key], item);
          }
        }
      }
      return [...result.values()] as T[];
    }

    return [...new Map(arr.map((item) => [item[key], item])).values()] as T[];
  }

  /**
   * example usage: ArrayUtil.toMap<MyType>(arrayValues, 'my-key-name')
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static toMap<T extends Record<any, any>>(items: T[], key: string): Map<string, T> {
    return new Map(items.map((item) => [item[key], item])) as Map<string, T>;
  }

  /**
   * removes all objects with the given key and values
   * hint only the first occurrence will be removed. Use `ArrayUtil.unique` before to ensure all keys will be removed
   * example usage: `ArrayUtil.removeByKeys(arrayValues, 'my-key-name', ['key-to-remove']);`
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static removeByKeys<T extends Record<any, any>>(items: T[], keyField: string, keysToRemove: string[]): T[] {
    return items.filter((item) => keysToRemove.indexOf(item[keyField]) === -1);
  }

  /**
   * replaces an object in an array by a key field
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static replaceBy<T extends Record<any, any>>(
    items: T[],
    keyField: string,
    keyValue: string,
    replacement: T,
  ): T[] {
    return items.map((item) => (item[keyField] === keyValue ? replacement : item));
  }
}
