<div class="m-2">
  <h2>Anmeldung</h2>

  <kfd-sign-in (loginSuccess)="signedIn = $event"></kfd-sign-in>

  @if (signedIn) {
    <div class="mt-2 flex flex-column md:flex-row">
      <div class="w-full mt-2 p-fluid">
        <a [href]="projectLink">
          <p-button label="Zu meinen Projekten" [rounded]="true" [outlined]="true"></p-button>
        </a>
      </div>
      <div class="w-full mt-2 p-fluid md:ml-2">
        <a [href]="profileLink">
          <p-button label="Zu meinem Profil" [rounded]="true" [outlined]="true"></p-button>
        </a>
      </div>
      <div class="w-full mt-2 p-fluid md:ml-2">
        <p-button
          label="Abmelden"
          icon="pi {{ ICON.SIGN_OUT }}"
          [rounded]="true"
          [outlined]="true"
          severity="warning"
          (click)="signOut()"
        ></p-button>
      </div>
    </div>
  }
</div>
