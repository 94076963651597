import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Calculation,
  CalculationHandler,
  CalculationResult,
  ConfigurationValueHandler,
  DisplayDataFieldConfig,
  EmptyFormValue,
  InputValue,
  Is,
  NumericInputValue,
  PrintValue,
  SingleFormValue,
} from '@kfd/core';
import { EMPTY, mergeMap, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CalculationService, ConfigurationService, ConfigurationStateService } from '../../service';

@Component({
  selector: 'kfd-configurator-field-readonly',
  templateUrl: './configurator-field-readonly.component.html',
  styleUrls: ['./configurator-field-readonly.component.scss'],
})
export class ConfiguratorFieldReadonlyComponent {
  @Output()
  public valueChange: EventEmitter<SingleFormValue<NumericInputValue> | EmptyFormValue> = new EventEmitter();
  protected staticValue: InputValue | undefined;
  protected printValue$: Observable<PrintValue | undefined> | undefined;
  private configurationValueHandler: ConfigurationValueHandler;

  constructor(
    private readonly calculationService: CalculationService,
    private readonly configurationService: ConfigurationService,
    private readonly stateService: ConfigurationStateService,
  ) {
    this.configurationValueHandler = new ConfigurationValueHandler(this.configurationService.cfgUtil);
  }

  private _field: DisplayDataFieldConfig | undefined;

  public get field(): DisplayDataFieldConfig | undefined {
    return this._field;
  }

  @Input()
  public set field(value: DisplayDataFieldConfig | undefined) {
    if (!value) {
      return;
    }
    this._field = value;
    this.initializeField();
  }

  private _name: string | undefined;

  public get name(): string | undefined {
    return this._name;
  }

  @Input()
  set name(value: string | undefined) {
    this._name = value;
    this.initializeField();
  }

  initializeField() {
    if (!this.field || !this.name) {
      return;
    }
    if (Is.calculation(this.field.value) || Is.fieldRef(this.field.value)) {
      this.printValue$ = this.getPrintValueForField(this.name).pipe(
        map((printValue) => {
          return {
            label: printValue?.label ?? '',
            key: printValue?.key ?? '',
            //todo make empty value ("---") configurable
            value: printValue?.value && printValue?.value !== '' ? printValue.value : '---',
          };
        }),
      );
      return;
    }
    if (Is.inputValue(this.field.value)) {
      this.staticValue = this.field.value;
      return;
    }
  }

  getCalculationResult(calculation: Calculation): Observable<CalculationResult> {
    const calcHandler = new CalculationHandler(calculation);
    const fieldRefs = calcHandler.getFieldRefs();
    const keysToSubscribe = fieldRefs.map((fieldRef) => fieldRef.name);
    if (keysToSubscribe.length > 0) {
      return this.stateService.onFieldsValueChange(keysToSubscribe, true).pipe(
        switchMap(() => this.calculationService.calc(calculation).pipe(catchError(() => EMPTY))),
        catchError(() => EMPTY),
      );
    }
    return this.calculationService.calc(calculation).pipe(catchError(() => EMPTY));
  }

  protected getPrintValueForField(fieldName: string, startEmpty = true): Observable<PrintValue | undefined> {
    const mergeMapFn = mergeMap(() => {
      if (!this.configurationService.configuration) {
        return of(undefined);
      }
      this.configurationValueHandler.setValueMap(
        this.stateService.getValueMap(this.configurationService.cfgUtil.getFieldNames()),
      );
      return this.configurationValueHandler.fieldValue(fieldName);
    });

    //todo run only on relevant field changes
    if (startEmpty) {
      return this.stateService.onValueChange().pipe(startWith(undefined), mergeMapFn);
    }
    return this.stateService.onValueChange().pipe(mergeMapFn);
  }
}
