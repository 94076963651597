import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, MessageService } from '../../lib/services';
import { COMMON_WEB_APP_ROUTES } from '@kfd/core';

@Component({
  selector: 'kfd-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  email = '';
  code = '';
  password1 = '';
  password2 = '';
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _authService: AuthService,
    private _messageService: MessageService,
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.has('email')) {
      this.email = <string>this.route.snapshot.paramMap.get('email');
    }
    if (this.route.snapshot.paramMap.has('code')) {
      this.code = <string>this.route.snapshot.paramMap.get('code');
    }
  }

  setPassword() {
    if (this.password1.length < 6) {
      this._messageService.showError('Verwenden Sie mindestens 6 Zeichen für Ihr neues Passwort');
      return;
    }

    this.loading = true;
    this._authService.setPassword(this.email, this.code, this.password1).subscribe({
      next: (success) => {
        if (success) {
          this._messageService.showSuccess(
            'Das neue Passwort wurde gesetzt',
            'Bitte melden sie sich jetzt mit dem neuen Passwort an',
          );
          this.router.navigate([COMMON_WEB_APP_ROUTES.user.login, { email: this.email }]);
        } else {
          this._messageService.showError('Das Passwort konnte nicht geändert werden');
        }
        this.loading = false;
      },
      error: (response) => {
        this._messageService.showError('Ups', response?.message);
        this.loading = false;
      },
    });
  }
}
