import { Component, Input } from '@angular/core';
import { CLS, Page } from '@kfd/core';

@Component({
  selector: 'kfd-configurator-page',
  templateUrl: './configurator-page.component.html',
  styleUrls: ['./configurator-page.component.scss'],
})
export class ConfiguratorPageComponent {
  @Input()
  configuratorPage: Page | undefined;

  cls = CLS;
}
