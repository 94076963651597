<p-toolbar class="header-toolbar" styleClass="invisible">
  <div class="p-toolbar-group-left">
    <h3>{{ dialogTitle }}</h3>
  </div>
  <div class="p-toolbar-group-right">
    <kfd-btn-menu [items]="headerMenu"></kfd-btn-menu>

    @if (docuPageId) {
      <kfd-docu-btn [pageId]="docuPageId" [sectionId]="docuSectionId" class="ml-2"></kfd-docu-btn>
    }

    @if (showRejectBtn || showRejectIcon) {
      <p-button
        class="ml-2"
        icon="pi {{ icon.DECLINE }}"
        data-automationTag="dialog-reject-btn"
        [outlined]="true"
        [rounded]="true"
        (click)="reject.emit()"
      ></p-button>
    }
  </div>
</p-toolbar>

<div
  [kfdBlock]="loading"
  [ngClass]="{ 'overflow-auto': disableScrolling === false, 'overflow-hidden': disableScrolling !== false }"
  class="flex-1 flex flex-column"
>
  <div class="dialog-content my-2 mx-3 flex-1 flex flex-column">
    <ng-container *ngIf="message">
      <p-message [severity]="messageSeverity" [text]="messageText"></p-message>
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>

<p-toolbar *ngIf="hideFooter !== true" class="footer-toolbar" styleClass="invisible">
  <div class="p-toolbar-group-left">
    <p-button
      (click)="reject.emit()"
      *ngIf="showRejectBtn"
      [label]="rejectBtnLabel"
      [outlined]="true"
      [rounded]="true"
      class="p-button-secondary"
      data-automationTag="dialog-reject-btn"
      icon="pi {{ rejectBtnIcon }}"
      iconPos="left"
    ></p-button>
    <ng-content select="[footerLeft]"></ng-content>
  </div>
  <div class="p-toolbar-group-right">
    <ng-content select="[footerRight]"></ng-content>
    <p-button
      (click)="accept.emit()"
      *ngIf="showAcceptBtn"
      [disabled]="acceptDisabled"
      [label]="acceptBtnLabel"
      [loading]="loading"
      [outlined]="true"
      [rounded]="true"
      data-automationId="dialog-accept-btn"
      icon="pi {{ acceptBtnIcon }}"
    ></p-button>
  </div>
</p-toolbar>
