<kfd-sign-in-wrapper>
  <ng-template #signedIn>
    <h1>Profil</h1>
    @if (user$ | async; as user) {
      <ng-container *ngIf="user && originUser">
        <p-card class="mt-2" header="Name">
          <div class="p-fluid">
            <div class="p-field">
              <kfd-toggle-input-field
                #nameEdit
                hint="Der Name wird für andere Benutzer sichtbar angezeigt"
                errorMsg="Der Name muss mindestens 3 Zeichen lang sein"
                [(value)]="user.name"
                [icon]="ICON.USER"
                [validator]="userNameValidators"
              ></kfd-toggle-input-field>
            </div>
          </div>
          <p-footer>
            <p-toolbar styleClass="invisible no-padding">
              <div class="p-toolbar-group-left"></div>
              <div class="p-toolbar-group-right">
                @if (!nameEdit.editMode && user.name !== originUser.name) {
                  <p-button
                    class="mr-2"
                    icon="pi {{ ICON.RESET }}"
                    label="Reset"
                    severity="warning"
                    [rounded]="true"
                    [outlined]="true"
                    (click)="user.name = originUser.name"
                  ></p-button>
                  <p-button
                    [disabled]="user.name.length < 3"
                    icon="pi {{ ICON.SAVE }}"
                    label="Speichern"
                    [rounded]="true"
                    [outlined]="true"
                    (click)="saveName(user)"
                  ></p-button>
                }
              </div>
            </p-toolbar>
          </p-footer>
        </p-card>

        <p-card class="mt-2" header="E-Mail Adresse">
          <ng-container *ngIf="requiredUserAction$ | async as requiredUserAction">
            <button
              (click)="sendValidationMail()"
              *ngIf="requiredUserAction === userAction.EMAIL_ACTIVATION"
              [disabled]="validationMailSent"
              icon="pi pi-envelope"
              label="Bestätigungs E-Mail erneut versenden"
              pButton
              type="button"
            ></button>
            <br />
            <br />
          </ng-container>

          <div class="p-fluid">
            <div class="p-field">
              <kfd-toggle-input-field
                #emailEdit
                hint="Nach dem Ändern der E-Mail, senden wir einen Bestätigungscode and die neue Adresse"
                errorMsg="Die Eingabe ist keine valide E-Mail Adresse."
                [(value)]="user.email"
                [icon]="ICON.EMAIL"
                [validator]="userEmailValidators"
              ></kfd-toggle-input-field>
            </div>
          </div>
          <p-footer>
            <p-toolbar styleClass="invisible no-padding">
              <div class="p-toolbar-group-left"></div>
              <div class="p-toolbar-group-right">
                @if (!emailEdit.editMode && user.email !== originUser.email) {
                  <p-button
                    [disabled]="pendingEmailChange"
                    class="p-button-warning mr-2"
                    icon="pi {{ ICON.RESET }}"
                    label="Reset"
                    [rounded]="true"
                    [outlined]="true"
                    (click)="user.email = originUser.email"
                  ></p-button>
                  <p-button
                    icon="pi {{ ICON.SAVE }}"
                    label="Speichern"
                    [rounded]="true"
                    [outlined]="true"
                    (click)="saveEmail(user)"
                    [loading]="pendingEmailChange"
                  ></p-button>
                }
              </div>
            </p-toolbar>
          </p-footer>
        </p-card>

        <p-card class="mt-2" header="Passwort ändern">
          <div class="p-fluid">
            <div class="p-field">
              <label>Aktuelles Passwort</label>
              <input [(ngModel)]="oldPassword" pInputText placeholder="" type="password" />
              <small *ngIf="oldPassword && oldPassword.length < 6"> Bitte geben sie ihr aktuelles Passwort ein </small>
            </div>
            <div class="p-field">
              <label>Neues Passwort</label>
              <input [(ngModel)]="password1" pInputText placeholder="" type="password" />
              <small *ngIf="password1 && password1.length < 8">
                Aus Sicherheitsgründen empfehlen wir ein komplexes Passwort zu wählen
              </small>
            </div>
            <div class="p-field">
              <label>Wiederholung</label>
              <input [(ngModel)]="password2" pInputText placeholder="" type="password" />
              <small *ngIf="password2 && password2.length > 0 && password1 !== password2"
                >Die Passwörter stimmen nicht überein</small
              >
            </div>
          </div>
          <p-footer>
            <p-toolbar styleClass="invisible no-padding">
              <div class="p-toolbar-group-left"></div>
              <div class="p-toolbar-group-right">
                <button
                  (click)="changePassword(user)"
                  [disabled]="!password1 || password1 !== password2"
                  icon="pi pi-key"
                  label="Neues Passwort setzen"
                  pButton
                  type="button"
                ></button>
              </div>
            </p-toolbar>
          </p-footer>
        </p-card>
      </ng-container>
    }
  </ng-template>
</kfd-sign-in-wrapper>
