import {
  Create,
  EmptyFormValue,
  FieldConfig,
  InputValue,
  Is,
  ObjectUtil,
  SingleFormValue,
  ValueTypes,
} from '@kfd/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { first, Observable } from 'rxjs';

@Component({
  template: '',
})
export abstract class SingleInputValueFieldComponent<T extends FieldConfig, U extends InputValue> {
  @Output()
  public valueChange: EventEmitter<SingleFormValue<U> | EmptyFormValue> = new EventEmitter();
  protected fieldConfig: T | undefined;
  // indicates if a value has ever been set or not
  private noValueSet = true;
  private _formValue: SingleFormValue<U> | EmptyFormValue = Create.emptyFormValue();

  constructor() {
    this.newFormValue()
      .pipe(first())
      .subscribe((value) => {
        this.setFormValue(value);
      });
  }

  public get value(): SingleFormValue<U> | EmptyFormValue {
    if (this.getFormValue() === undefined) {
      return Create.emptyFormValue();
    }
    return this.getFormValue();
  }

  @Input()
  public set value(value: SingleFormValue<U> | EmptyFormValue) {
    if (!Is.singleFormValue(value) && !Is.emptyFormValue(value)) {
      return;
    }

    //empty type is given from outside if nothing has been stored
    if (Is.emptyFormValue(value) && this.noValueSet) {
      this.newFormValue()
        .pipe(first())
        .subscribe((value) => {
          this.setFormValue(value);
          //if the new form value has a value we expose it as default
          if (Is.singleFormValue(value)) {
            this.onValueChange();
          }
        });
      this.noValueSet = false;
      return;
    }

    if (ObjectUtil.equals(value, this.getFormValue())) {
      return;
    }
    this.setFormValue(value);
  }

  public get field(): T | undefined {
    return this.fieldConfig;
  }

  @Input()
  public set field(field: T | undefined) {
    this.fieldConfig = field;
  }

  protected getFormValue(): SingleFormValue<U> | EmptyFormValue {
    return this._formValue;
  }

  protected setFormValue(value: SingleFormValue<U> | EmptyFormValue) {
    this._formValue = value;
  }

  protected abstract newFormValue(value?: ValueTypes): Observable<SingleFormValue<U> | EmptyFormValue>;

  protected onValueChange() {
    if (Is.formValue(this.getFormValue())) {
      this.valueChange.emit(this.getFormValue());
    }
  }
}
