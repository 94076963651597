@if (value) {
  @if (editMode) {
    @if (value.type === DATA_VALUE_TYPE.EMPTY || value.value === undefined) {
      <div class="flex">
        <div class="flex flex-1 justify-content-center align-items-center">
          <i>Kein Wert</i>
        </div>
        <p-button
          icon="pi {{ ICON.ADD }}"
          label="Wert Eintragen"
          data-automationId="data-value-add-btn"
          [disabled]="type === DATA_VALUE_TYPE.EMPTY"
          [text]="true"
          (click)="createInputValueWithDefault(true)"
        ></p-button>
      </div>
    } @else {
      <div class="flex">
        @switch (type) {
          @case (DATA_VALUE_TYPE.STRING) {
            <input
              #textInput
              pInputText
              type="text"
              data-automationId="data-value-input-text"
              [ngModel]="value.value"
              (change)="onValueChange(DATA_VALUE_TYPE.STRING, textInput.value)"
            />
          }
          @case (DATA_VALUE_TYPE.BOOL) {
            <kfd-yes-no-btn
              (valueChange)="onValueChange(DATA_VALUE_TYPE.BOOL, $event)"
              [value]="!!value.value"
              inputId="fieldValue"
              data-automationId="data-value-input-bool"
            ></kfd-yes-no-btn>
          }
          @case (DATA_VALUE_TYPE.NUMERIC) {
            <p-inputNumber
              (onInput)="onValueChange(DATA_VALUE_TYPE.NUMERIC, $event.value)"
              [max]="valueProperties?.max"
              [minFractionDigits]="valueProperties?.minFractionDigits ?? 0"
              [min]="valueProperties?.min"
              [ngModel]="value.value"
              [prefix]="valueProperties?.prefix ?? ''"
              [showButtons]="valueProperties?.showButtons ?? false"
              [suffix]="valueProperties?.suffix ?? ''"
              [allowEmpty]="true"
              locale="de-DE"
              mode="decimal"
              data-automationId="data-value-input-number"
            >
            </p-inputNumber>
            <kfd-toggle-setting
              (enabledChange)="this.valueChange.emit(this.value)"
              *ngIf="settings"
              [object]="value"
              [property]="'properties'"
              class="mt-2"
              label="Weitere Einstellungen"
            >
              <ng-container *ngIf="valueProperties">
                <div class="p-inputgroup">
                  <input [(ngModel)]="valueProperties.prefix" pInputText placeholder="Prefix" type="text" />
                  <span class="p-inputgroup-addon px-4">{{ value.value }}</span>
                  <input [(ngModel)]="valueProperties.suffix" pInputText placeholder="Suffix" type="text" />
                </div>
              </ng-container>
            </kfd-toggle-setting>
          }
          @case (DATA_VALUE_TYPE.DATE) {
            <!--        <input-->
            <!--          pInputText-->
            <!--          type="date"-->
            <!--          #dateInput-->
            <!--          [ngModel]="value.value"-->
            <!--          (change)="onValueChange(DATA_VALUE_TYPE.DATE, dateInput.value)"-->
            <!--        />-->
            <p-calendar
              appendTo="body"
              data-automationId="data-value-input-date"
              [ngModel]="value.value"
              [iconDisplay]="'input'"
              [showIcon]="true"
              [dateFormat]="SAVE_DATE_FORMAT"
              (ngModelChange)="onValueChange(DATA_VALUE_TYPE.DATE, $event)"
            ></p-calendar>
          }
          @default {
            <input pInputText type="text" [disabled]="true" value="Datentyp auswählen" />
          }
        }
        @if (createDefaultInputValue) {
          <p-button icon="pi {{ ICON.DELETE }}" [text]="true" (click)="this.setEmptyValue()"></p-button>
        }
      </div>
    }
  } @else {
    @switch (type) {
      @case (DATA_VALUE_TYPE.DATE) {
        {{ value.value?.toString() | date: 'MMM d, y' }}
      }
      @default {
        <div>{{ value.value }}</div>
      }
    }
  }
}
