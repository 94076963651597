import { Component, Input } from '@angular/core';
import { BooleanFieldConfig, BoolInputValue, Create, EmptyFormValue, Is, SingleFormValue } from '@kfd/core';
import { SingleInputValueFieldComponent } from '../single-input-value-field.component';
import { Observable, of } from 'rxjs';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { CFG_ICON } from '../../../globals';

@Component({
  selector: 'kfd-configurator-field-bool',
  templateUrl: './configurator-field-bool.component.html',
  styleUrls: ['./configurator-field-bool.component.scss'],
})
export class ConfiguratorFieldBoolComponent extends SingleInputValueFieldComponent<BooleanFieldConfig, BoolInputValue> {
  public options: {
    label: string;
    icon: string;
    value: boolean;
  }[] = [];
  protected inputFieldValue: boolean | undefined;

  @Input()
  override set field(field: BooleanFieldConfig | undefined) {
    if (!field) {
      return;
    }
    this.fieldConfig = field;
    this.setOptions();
  }

  setOptions() {
    this.options = [
      {
        label: this.fieldConfig?.labels ? this.fieldConfig.labels.yes : '',
        icon: 'pi ' + CFG_ICON.CHECK,
        value: true,
      },
      {
        label: this.fieldConfig?.labels ? this.fieldConfig.labels.no : '',
        icon: 'pi ' + CFG_ICON.TIMES,
        value: false,
      },
    ];
  }

  protected override setFormValue(value: SingleFormValue<BoolInputValue> | EmptyFormValue) {
    super.setFormValue(value);
    const formValue = this.getFormValue();
    if (Is.boolFormValue(formValue)) {
      this.inputFieldValue = formValue.input.value;
    } else {
      this.inputFieldValue = undefined;
    }
  }

  protected newFormValue(value?: boolean): Observable<SingleFormValue<BoolInputValue> | EmptyFormValue> {
    if (value) {
      return of(Create.boolFormValue(value));
    }
    if (this.fieldConfig?.default) {
      return of(Create.boolFormValue(this.fieldConfig.default));
    }
    return of(Create.emptyFormValue());
  }

  protected onInputValueChange($event: SelectButtonChangeEvent) {
    this.setFormValue(Create.boolFormValue($event.value));
    this.onValueChange();
  }
}
