import {
  BaseDataTemplate,
  Create,
  CreateBaseData,
  DATA_VALUE_TYPE,
  InputValue,
  SelectionData,
  Sort,
  SORT_DIRECTION,
} from '../dtos';

/**
 * sorts selectionData based on name, label and properties in values array
 * @param selectionData
 * @param sortParams
 */
export function sortSelectionData<T extends SelectionData>(selectionData: T[], sortParams?: Sort[]): T[] {
  if (!sortParams || sortParams.length === 0) {
    return selectionData;
  }
  return selectionData.sort((a, b) => {
    for (const sortParam of sortParams) {
      const aValue = getSelectionDataValue(a, sortParam.field)?.value;
      const bValue = getSelectionDataValue(b, sortParam.field)?.value;
      if (aValue === bValue) {
        continue;
      }
      if (aValue === undefined || bValue === undefined) {
        return aValue === undefined ? 1 : -1;
      }
      return sortParam.direction === SORT_DIRECTION.ASC ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
    }
    return 0;
  });
}

/**
 * Get values in selectionData object based on an identifier. looks for name, label and keys in values array
 */
export function getSelectionDataValue(selectionData: SelectionData, identifier?: string): InputValue | undefined {
  if (identifier === undefined || identifier === 'name') {
    return Create.inputValue(DATA_VALUE_TYPE.STRING, selectionData.name);
  }
  if (identifier === 'label') {
    return Create.inputValue(DATA_VALUE_TYPE.STRING, selectionData.label);
  }
  // all other identifiers are in the values array
  if (!selectionData.values) {
    return undefined;
  }
  return selectionData.values.find((value) => value.identifier === identifier);
}

/**
 * Validate the base data against the template definitions
 */
export function validateSelectionDataTemplate(
  template: BaseDataTemplate,
  selectionData: Omit<SelectionData, 'image'>,
): string[] {
  return validateBaseDataTemplate(template, {
    name: selectionData.name,
    label: selectionData.label,
    values: selectionData.values
      ? selectionData.values.map((value) => ({
          name: value.identifier ?? '',
          label: value.label ? value.label.text : '',
          inputValue: value,
        }))
      : [],
    tags: selectionData.tags,
  });
}

/**
 * Validate the base data against the template definitions
 */
export function validateBaseDataTemplate(template: BaseDataTemplate, baseData: CreateBaseData): string[] {
  const errors = [];
  if (!template) {
    throw new Error('Template not found');
  }
  if (template.defaultTags) {
    template.defaultTags.forEach((tag) => {
      if (!baseData || baseData.tags?.includes(tag) === false) {
        errors.push(`Der "${tag}" fehlt`);
      }
    });
  }
  if (template.allowedTags && template.allowedTags.length > 0 && baseData.tags) {
    const allowedTags = [...(template.allowedTags ?? []), ...(template.defaultTags ?? [])];
    baseData.tags.forEach((tag) => {
      if (!allowedTags.includes(tag)) {
        errors.push(`Der Tag "${tag}" is nicht in der Liste der erlaubten Tags enthalten`);
      }
    });
  }
  if (template.values) {
    if (!baseData.values) {
      errors.push('Values are missing');
    } else {
      template.values.forEach((templateValue) => {
        const baseDataValue = baseData.values.find((baseDataValue) => baseDataValue.name === templateValue.name);
        if (!baseDataValue) {
          errors.push(
            `Datensatz: "${baseData.name}", Template: "${template.name}" - der Wert "${templateValue.name}" ist nicht vorhanden`,
          );
        } else if (baseDataValue.inputValue.type !== templateValue.inputValue.type) {
          errors.push(
            `Datensatz: "${baseData.name}", Template: "${template.name}" - der Wert "${templateValue.name}" hat einen falschen Typ`,
          );
        }
      });
    }
  }
  return errors;
}
