import { Injectable } from '@angular/core';
import { LocalCodes } from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  public localeCode(): string {
    switch (navigator.language) {
      case 'de':
        return LocalCodes.DE_DE;
      default:
        return LocalCodes.EN_US;
    }
  }
}
