<!--<p-messages [value]="messages"></p-messages>-->
<!--<p-message severity="warning"></p-message>-->

<p-messages *ngIf="invalidFields && invalidFields.length > 0" severity="warn">
  <ng-template pTemplate>
    <div class="flex flex-column card-container green-container">
      <strong>Folgende Felder enthalten Fehler:</strong>
      <ul>
        <li *ngFor="let field of invalidFields">{{ field }}</li>
      </ul>
    </div>
  </ng-template>
</p-messages>
