import { EventEmitter, Injectable } from '@angular/core';
import { ConfigurationStateService } from './configuration-state.service';
import { ConfigurationValidator, ValidationError, ValidationMap, ValidationResult } from '@kfd/core';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigurationValidationService {
  private validationFor = new EventEmitter<{
    fieldName: string;
    validation: ValidationResult;
    forced: boolean;
  }>();
  private configurationValidator: ConfigurationValidator | undefined;

  constructor(
    private configurationService: ConfigurationService,
    private configurationStateService: ConfigurationStateService,
  ) {
    this.configurationService.onInit().subscribe(() => this.init());
  }

  /**
   * triggers on validation change for the given field
   * @param fieldName
   */
  public onValidationFor(fieldName: string): Observable<{
    fieldName: string;
    validation: ValidationResult;
    forced: boolean;
  }> {
    return new Observable((observer) => {
      this.validationFor.subscribe((validation) => {
        if (validation.fieldName === fieldName) {
          observer.next(validation);
        }
      });
    });
  }

  public validateAll(): ValidationMap {
    if (!this.configurationValidator) {
      return new Map<string, ValidationError>();
    }
    const valueMap = this.configurationStateService.getValueMap();
    const validationMap = this.configurationValidator.validateConfiguration(valueMap);
    for (const [fieldName, validationError] of validationMap.entries()) {
      this.triggerValidationFor(fieldName, validationError, true);
    }
    return validationMap;
  }

  public validatePage(pageName: string): ValidationMap {
    if (!this.configurationValidator) {
      return new Map<string, ValidationError>();
    }
    const valueMap = this.configurationStateService.getValueMap();
    const validationMap = this.configurationValidator.validatePage(valueMap, pageName);
    for (const [fieldName, validationError] of validationMap.entries()) {
      this.triggerValidationFor(fieldName, validationError, true);
    }
    return validationMap;
  }

  public validateField(fieldName: string, forced = false): ValidationResult {
    if (!this.configurationValidator) {
      return;
    }
    const valueMap = this.configurationStateService.getValueMap();
    const validationResult = this.configurationValidator.validateFieldByName(valueMap, fieldName);
    this.triggerValidationFor(fieldName, validationResult, forced);
    return validationResult;
  }

  private init() {
    this.configurationValidator = new ConfigurationValidator(this.configurationService.cfgUtil);
  }

  private triggerValidationFor(fieldName: string, validation: ValidationResult, forced: boolean): void {
    this.validationFor.emit({ fieldName, validation, forced });
  }
}
