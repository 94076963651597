import { Component, Input, OnInit } from '@angular/core';
import { Calculation, CalculationResult } from '@kfd/core';
import { CalculationService } from '../../service/calculation-service';
import { EMPTY, Observable } from 'rxjs';
import { ConfigurationStateService } from '../../service/configuration-state.service';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'kfd-configurator-calculation',
  templateUrl: './configurator-calculation.component.html',
  styleUrls: ['./configurator-calculation.component.scss'],
})
export class ConfiguratorCalculationComponent implements OnInit {
  calculationResult$: Observable<CalculationResult> | undefined;

  constructor(
    private calculationService: CalculationService,
    private stateService: ConfigurationStateService,
  ) {}

  private _calculation: Calculation | undefined;

  get calculation(): Calculation | undefined {
    return this._calculation;
  }

  @Input()
  set calculation(value: Calculation | undefined) {
    if (!value) {
      return;
    }
    this._calculation = value;
  }

  ngOnInit(): void {
    this.updateCalculation();
  }

  updateCalculation() {
    if (!this.calculation) {
      return;
    }

    this.calculationResult$ = this.getCalculationResult(this.calculation);
    // .pipe(tap(console.log))
  }

  getCalculationResult(calculation: Calculation): Observable<CalculationResult> {
    return this.stateService.onValueChange(undefined, true).pipe(
      switchMap(() => this.calculationService.calc(calculation).pipe(catchError(() => EMPTY))),
      catchError(() => EMPTY),
    );
  }
}
