import { Component, Input } from '@angular/core';
import {
  Create,
  DATA_VALUE_TYPE,
  EmptyFormValue,
  ICON,
  Is,
  SingleFormValue,
  TEXTFIELD_VALIDATIONS,
  TextFieldConfig,
  TextInputValue,
} from '@kfd/core';
import { SingleInputValueFieldComponent } from '../single-input-value-field.component';
import { first, Observable, of } from 'rxjs';

@Component({
  selector: 'kfd-configurator-field-text',
  templateUrl: './configurator-field-text.component.html',
  styleUrls: ['./configurator-field-text.component.scss'],
})
export class ConfiguratorFieldTextComponent extends SingleInputValueFieldComponent<TextFieldConfig, TextInputValue> {
  protected fieldType: string | undefined;

  protected inputFieldValue = '';

  protected icon = '';

  @Input()
  override set field(value: TextFieldConfig | undefined) {
    if (!value) {
      return;
    }

    this.fieldConfig = value;
    if (Is.emptyFormValue(this.getFormValue())) {
      this.newFormValue()
        .pipe(first())
        .subscribe((value) => {
          this.setFormValue(value);
        });
    }

    if (!value?.validation) {
      value.validation = TEXTFIELD_VALIDATIONS.TEXT;
    }

    if (!this.fieldConfig.min) {
      this.fieldConfig.min = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 0);
    }
    if (!this.fieldConfig.max) {
      this.fieldConfig.max = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 99999);
    }

    switch (value.validation) {
      case TEXTFIELD_VALIDATIONS.EMAIL:
        this.fieldType = 'email';
        this.fieldConfig.min = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 5);
        this.fieldConfig.max = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 99);
        this.icon = ICON.EMAIL;
        break;
      case TEXTFIELD_VALIDATIONS.LINK:
        this.fieldType = 'url';
        this.fieldConfig.min = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 5);
        this.fieldConfig.max = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 9999);
        this.icon = ICON.LINK;
        break;
      case TEXTFIELD_VALIDATIONS.PHONE:
        this.fieldType = 'tel';
        this.fieldConfig.min = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 5);
        this.fieldConfig.max = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 99);
        this.icon = ICON.PHONE;
        break;
      case TEXTFIELD_VALIDATIONS.DATE:
        this.fieldType = 'date';
        break;
      default:
        this.fieldType = 'text';
    }
  }

  protected onInputValueChange(value: string) {
    this.setFormValue(Create.textFormValue(value));
    this.onValueChange();
  }

  protected override setFormValue(value: SingleFormValue<TextInputValue> | EmptyFormValue) {
    super.setFormValue(value);
    const formValue = this.getFormValue();
    if (Is.textFormValue(formValue)) {
      this.inputFieldValue = formValue.input.value;
    } else {
      this.inputFieldValue = '';
    }
  }

  protected newFormValue(): Observable<SingleFormValue<TextInputValue> | EmptyFormValue> {
    if (this.fieldConfig?.default) {
      return of(Create.textFormValue(this.fieldConfig.default));
    }
    return of(Create.textFormValue());
  }
}
