import { GetEndpoint, PostEndpoint, Service } from './endpoints';
import {
  CustomerRequestInfo,
  PublicProject,
  REQ_PARAMS,
  RequestForm,
  RequestSubmissionBody,
  RequestSubmissionResponse,
  SelectionData,
} from '../dtos';

export enum CFG_CTRL_EP {
  REQUEST = 'request',
  PROJECT = 'project',
  PUBLISHED = 'published',
}

export const cfgServiceEP = {
  request: {
    submit: new PostEndpoint<RequestSubmissionBody, RequestSubmissionResponse>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.REQUEST}/submit/:${REQ_PARAMS.REQUEST_ID}`,
      null as unknown as RequestSubmissionBody,
      null as unknown as RequestSubmissionResponse,
    ),
    info: new GetEndpoint<CustomerRequestInfo | undefined>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PROJECT}/:${REQ_PARAMS.PROJECT_ID}/request-info/:${REQ_PARAMS.REQUEST_CODE}/:${REQ_PARAMS.CUSTOMER_CODE}`,
      null as unknown as CustomerRequestInfo | undefined,
    ),
    e2eCustomerCode: new GetEndpoint<string>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PROJECT}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.REQUEST_CODE}/customer-code`,
      null as unknown as string,
    ),
  },
  project: {
    info: new GetEndpoint<PublicProject>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PROJECT}/:${REQ_PARAMS.PROJECT_ID}/info`,
      null as unknown as PublicProject,
    ),
    cfgRequestForm: new GetEndpoint<RequestForm>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PROJECT}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.CONFIGURATION_ID}/request-form`,
      null as unknown as RequestForm,
    ),
  },
  published: {
    dataByFilter: new GetEndpoint<SelectionData[]>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PUBLISHED}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.CONFIGURATION_ID}/data/filter`,
      null as unknown as SelectionData[],
    ),
    // @deprecated use dataByFilter instead
    dataByTags: new GetEndpoint<SelectionData[]>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PUBLISHED}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.CONFIGURATION_ID}/data/tags/:${REQ_PARAMS.KEY}`,
      null as unknown as SelectionData[],
    ),
    dataByKey: new GetEndpoint<SelectionData[]>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PUBLISHED}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.CONFIGURATION_ID}/data/identifier/:${REQ_PARAMS.KEY}`,
      null as unknown as SelectionData[],
    ),
    loadAsset: new GetEndpoint<void>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PUBLISHED}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.CONFIGURATION_ID}/asset/load/:${REQ_PARAMS.KEY}`,
      null as unknown as void,
    ),
    loadThumbnail: new GetEndpoint<void>(
      Service.CFG_SERVICE,
      `/${CFG_CTRL_EP.PUBLISHED}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.CONFIGURATION_ID}/asset/thumbnail/:${REQ_PARAMS.KEY}`,
      null as unknown as void,
    ),
  },
};
