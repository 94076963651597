<div class="p-fluid">
  <div class="flex align-items-center">
    <p-inputSwitch
      (onChange)="onEnabledChange($event)"
      [ngModel]="enabled"
      data-automationId="toggle-switch"
      styleClass="sm secondary"
    ></p-inputSwitch>

    <div *ngIf="label" class="label">
      {{ label }}
    </div>
    <div *ngIf="onLabel && enabled" class="label">
      {{ onLabel }}
    </div>
    <div *ngIf="offLabel && !enabled" class="label">
      {{ offLabel }}
    </div>
  </div>
  <div *ngIf="enabled" class="sub-settings">
    <ng-content></ng-content>
  </div>
</div>
