import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'kfd-privacy-acknowledge-dialog',
  templateUrl: './privacy-acknowledge-dialog.component.html',
  styleUrls: ['./privacy-acknowledge-dialog.component.scss'],
})
export class PrivacyAcknowledgeDialogComponent {
  protected privacyAccepted = false;
  protected loading = false;
  protected rejected = false;

  constructor(private authService: AuthService) {}

  onReject() {
    this.loading = true;
    this.authService.signOut().subscribe(() => {
      this.rejected = true;
      this.loading = false;
    });
  }

  onAccept() {
    this.loading = true;
    this.authService.acceptPrivacy().subscribe({
      next: () => {
        this.loading = false;
        this.reload();
      },
      error: () => {
        this.loading = false;
        this.reload();
      },
    });
  }

  reload() {
    window.location.reload();
  }
}
