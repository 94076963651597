import { Injectable } from '@angular/core';
import { MessageService as PrimeMessageService } from 'primeng/api';
import { Cache } from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  cache: Cache;

  constructor(private messageService: PrimeMessageService) {
    this.cache = new Cache();
  }

  public showSuccess(msg: string, detail?: string): void {
    this.messageService.add({
      severity: 'success',
      summary: msg,
      detail,
      key: 'info',
      sticky: !!detail,
    });
  }

  public showInfo(msg: string, detail?: string): void {
    this.messageService.add({
      severity: 'info',
      summary: msg,
      detail,
      key: 'info',
      sticky: true,
    });
  }

  public showError(msg: string, detail?: string, keep = false): void {
    if (this.cache.has(msg + detail)) {
      return;
    }
    this.cache.set(msg + detail, null, 10);

    this.messageService.add({
      severity: 'error',
      summary: msg,
      detail,
      closable: !keep,
      sticky: keep,
      key: 'error',
    });
  }
}
