import { Component } from '@angular/core';
import { ConfigService } from '../../lib/services/config.service';
import { App, CMS_APP_ROUTES, COMMON_WEB_APP_ROUTES, ICON } from '@kfd/core';
import { AuthService } from '../../lib/services/auth.service';

@Component({
  selector: 'kfd-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrl: './sign-in-page.component.scss',
})
export class SignInPageComponent {
  protected signedIn: boolean | undefined;
  protected projectLink = '';
  protected profileLink = '';
  protected readonly ICON = ICON;

  constructor(
    configService: ConfigService,
    protected authService: AuthService,
  ) {
    this.projectLink = configService.getApp(App.CMS) + CMS_APP_ROUTES.root;
    this.profileLink = COMMON_WEB_APP_ROUTES.user.profile;
  }

  protected signOut() {
    this.authService.signOut().subscribe(() => {
      window.location.reload();
    });
  }
}
