export enum FILE_TYPE {
  JPG = 'JPG',
  PNG = 'PNG',
  BMP = 'BMP',
  TIFF = 'TIFF',
  PDF = 'PDF',
  OFD = 'OFD',
  SVG = 'SVG',
  GIF = 'GIF',
}

/**
 * returns file type based on base64 string
 * @param base64Str
 */
export function base64FileType(base64Str: string): FILE_TYPE | undefined {
  const startStr = base64Str.slice(0, 3);
  switch (startStr) {
    case '/9j':
      return FILE_TYPE.JPG;
    case 'iVB':
      return FILE_TYPE.PNG;
    case 'Qk0':
      return FILE_TYPE.BMP;
    case 'SUk':
      return FILE_TYPE.TIFF;
    case 'JVB':
      return FILE_TYPE.PDF;
    case 'UEs':
      return FILE_TYPE.OFD;
    case 'R0l':
      return FILE_TYPE.OFD;
    case 'PHN':
      return FILE_TYPE.SVG;
  }
  return;
}

/**
 * returns file size in kb based on base64 string
 * @param base64Str
 */
export function base64FileSize(base64Str: string): number {
  const size = (base64Str.length * (3 / 4)) / 1024;
  return Math.round(size);
}
