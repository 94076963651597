import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/config.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MessageService as PrimeMessageService } from 'primeng/api';
import { UserMessagesComponent } from './components/user-messages/user-messages.component';
import { TwoStepInputComponent } from './components/two-step-input/two-step-input.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { ReversePipe } from './pipes/reverse.pipe';
import { DataValueComponent } from './components/data-value/data-value.component';
import { YesNoBtnComponent } from './components/yes-no-btn/yes-no-btn.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToggleSettingComponent } from './components/toggle-setting/toggle-setting.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StaticContentComponent } from './components/static-content/static-content.component';
import { MessagesModule } from 'primeng/messages';
import { PageContentComponent } from './components/page-content/page-content.component';
import { DialogLayoutComponent } from './components/dialog-layout/dialog-layout.component';
import { ToolbarModule } from 'primeng/toolbar';
import { DocuBtnComponent } from './components/docu-btn/docu-btn.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PrivacyAcknowledgeDialogComponent } from './components/privacy-acknowledge-dialog/privacy-acknowledge-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { BlockerDirective } from './directives/blocker.directive';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { RouterModule } from '@angular/router';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { KeyValueListComponent } from './components/key-value-list/key-value-list.component';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { LoaderComponent } from './components/loader/loader.component';
import { App, Service } from '@kfd/core';
import { FilterPipe, PrettyPrintJsonPipe } from './pipes';
import { DynamicValueListComponent } from './components/dynamic-value-list/dynamic-value-list.component';
import { CfgModule } from '@kfd/cfg-core';
import { ActionMessageComponent } from './components/action-message/action-message.component';
import { BtnMenuComponent } from './components/btn-menu/btn-menu.component';
import { MenuModule } from 'primeng/menu';
import { LoadingPipe } from './pipes/loading.pipe';
import { ToggleInputFieldComponent } from './components/toggle-input-field/toggle-input-field.component';
import { PlaceholderMessageComponent } from './components/placeholder-message/placeholder-message.component';
import { ENDPOINT_SERVICE_TOKEN } from './services';
import { CalendarModule } from 'primeng/calendar';
import { FilterByListPipe } from './pipes/filter-by-list.pipe';
import { SingleValuePipe } from './pipes/single-value.pipe';

const WEB_CORE_MOD_OPTIONS = new InjectionToken<ModuleOptions>('forRoot() configuration.');

const primeInExports = [
  ButtonModule,
  CheckboxModule,
  CardModule,
  InputNumberModule,
  InputSwitchModule,
  InputTextModule,
  MenuModule,
  MessageModule,
  MessagesModule,
  OverlayPanelModule,
  SelectButtonModule,
  ToolbarModule,
  TooltipModule,
];

const componentsDeclaration = [
  BtnMenuComponent,
  DialogLayoutComponent,
  ToggleInputFieldComponent,
  PlaceholderMessageComponent,
];

const pipesDeclaration = [LoadingPipe, ReversePipe, PrettyPrintJsonPipe, FilterPipe, FilterByListPipe, SingleValuePipe];

export interface ModuleOptions {
  config: {
    services: Partial<Record<Service, string>>;
    apps: Partial<Record<App, string>>;
  };
}

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, ...primeInExports, CfgModule, ReactiveFormsModule, CalendarModule],
  declarations: [
    ActionMessageComponent,
    UserMessagesComponent,
    TwoStepInputComponent,

    DataValueComponent,
    DocuBtnComponent,
    YesNoBtnComponent,
    StaticContentComponent,
    ToggleSettingComponent,
    UserIconComponent,
    PageContentComponent,
    PrivacyAcknowledgeDialogComponent,
    BlockerDirective,
    SidebarComponent,
    PageLayoutComponent,
    KeyValueListComponent,
    LoaderComponent,
    DynamicValueListComponent,
    ...pipesDeclaration,
    ...componentsDeclaration,
  ],
  providers: [
    {
      provide: ConfigService,
      useFactory: (options: ModuleOptions) => {
        const configService = new ConfigService();
        configService.services = options.config.services;
        configService.webApp = options.config.apps[App.WEB] ?? '';
        configService.apps = options.config.apps;
        return configService;
      },
      deps: [WEB_CORE_MOD_OPTIONS],
    },
    {
      provide: ENDPOINT_SERVICE_TOKEN,
      useFactory: (configService: ConfigService) => ({
        services: configService.services,
      }),
      deps: [ConfigService],
    },
    PrimeMessageService,
    DialogService,
  ],
  exports: [
    ActionMessageComponent,
    UserMessagesComponent,
    TwoStepInputComponent,
    DataValueComponent,
    DocuBtnComponent,
    YesNoBtnComponent,
    StaticContentComponent,
    ToggleSettingComponent,
    UserIconComponent,
    PageContentComponent,
    BlockerDirective,
    SidebarComponent,
    PageLayoutComponent,
    KeyValueListComponent,
    LoaderComponent,
    DynamicValueListComponent,
    ...pipesDeclaration,
    ...componentsDeclaration,
    ...primeInExports,
  ],
})
export class WebCoreModule {
  static forRoot(moduleOptions: ModuleOptions): ModuleWithProviders<WebCoreModule> {
    return {
      ngModule: WebCoreModule,
      providers: [
        {
          provide: WEB_CORE_MOD_OPTIONS,
          useValue: moduleOptions,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
