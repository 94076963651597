import { Injectable } from '@angular/core';
import { ContextService } from '@kfd/core';

@Injectable()
export class CfgContextService implements ContextService {
  private _projectId = '';

  get projectId(): string {
    if (!this._projectId) {
      throw new Error('No mandant id available');
    }
    return this._projectId;
  }

  set projectId(value: string) {
    this._projectId = value;
  }

  private _configuratorId = '';

  get configuratorId(): string {
    if (!this._configuratorId) {
      throw new Error('No configuration id available');
    }
    return this._configuratorId;
  }

  set configuratorId(value: string) {
    this._configuratorId = value;
  }

  private _requestId = '';

  get requestId(): string {
    if (!this._requestId) {
      throw new Error('No request id available');
    }
    return this._requestId;
  }

  set requestId(value: string) {
    this._requestId = value;
  }

  hasMandantId(): boolean {
    return !!this._projectId;
  }

  hasConfiguratorId(): boolean {
    return !!this._configuratorId;
  }
}
