@if (isLoggedIn$ | async) {
  @if (currentUser$ | async; as currentUser) {
    Hallo {{ currentUser.name }}

    <div class="block mt-4">
      <p-button
        class="m-2"
        icon="pi {{ ICON.SIGN_OUT }}"
        label="Abmelden"
        severity="warning"
        [rounded]="true"
        [outlined]="true"
        (click)="signOut()"
      >
      </p-button>

      <p-button
        icon="pi {{ ICON.PROFILE }}"
        class="m-2"
        label="Mein Profil"
        [rounded]="true"
        [outlined]="true"
        [routerLink]="COMMON_WEB_APP_ROUTES.user.profile"
      >
      </p-button>
    </div>
  }

  <ng-container *ngTemplateOutlet="signedIn"></ng-container>
} @else {
  <h2>Anmelden</h2>
  <kfd-sign-in class="block mt-4"></kfd-sign-in>
}
