import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivationComponent } from './activation/activation.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ModuleOptions, WebCoreModule } from '../lib/web-core.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { App, COMMON_APP_ROUTE_PARAMS, COMMON_APP_ROUTE_PARTS } from '@kfd/core';
import { AuthComponent } from './auth.component';
import { SignInWrapperComponent } from './sign-in-wrapper/sign-in-wrapper.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';
import { ConfigService } from '../lib/services';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { SendVerificationComponent } from './send-verification/send-verification.component';

const AUTH_MOD_OPTIONS = new InjectionToken<ModuleOptions>('forRoot() configuration.');

const ROUTES: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: COMMON_APP_ROUTE_PARTS.PROFILE,
      },
      {
        path: COMMON_APP_ROUTE_PARTS.PROFILE,
        component: UserProfileComponent,
      },
      {
        path: COMMON_APP_ROUTE_PARTS.LOGIN,
        component: SignInPageComponent,
      },
      {
        path: COMMON_APP_ROUTE_PARTS.REGISTRATION,
        component: SignUpComponent,
      },
      {
        path: COMMON_APP_ROUTE_PARTS.ACTIVATION,
        component: ActivationComponent,
      },
      {
        path: `${COMMON_APP_ROUTE_PARTS.ACTIVATION}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}`,
        component: ActivationComponent,
      },
      {
        path: `${COMMON_APP_ROUTE_PARTS.ACTIVATION}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}/:${COMMON_APP_ROUTE_PARAMS.CODE}`,
        component: ActivationComponent,
      },
      {
        path: COMMON_APP_ROUTE_PARTS.RESEND_VERIFICATION,
        component: SendVerificationComponent,
      },
      {
        path: `${COMMON_APP_ROUTE_PARTS.RESEND_VERIFICATION}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}`,
        component: SendVerificationComponent,
      },
      {
        path: `${COMMON_APP_ROUTE_PARTS.RESEND_VERIFICATION}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}/:${COMMON_APP_ROUTE_PARAMS.CODE}`,
        component: SendVerificationComponent,
      },
      {
        path: COMMON_APP_ROUTE_PARTS.PASSWORD_RESET,
        component: PasswordResetComponent,
      },
      {
        path: `${COMMON_APP_ROUTE_PARTS.PASSWORD_RESET}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}`,
        component: SetPasswordComponent,
      },
      {
        path: `${COMMON_APP_ROUTE_PARTS.PASSWORD_RESET}/:${COMMON_APP_ROUTE_PARAMS.EMAIL}/:${COMMON_APP_ROUTE_PARAMS.CODE}`,
        component: SetPasswordComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    AuthComponent,
    SignInComponent,
    SignInPageComponent,
    SignInWrapperComponent,
    SignUpComponent,
    ActivationComponent,
    SendVerificationComponent,
    PasswordResetComponent,
    SetPasswordComponent,
    UserProfileComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    ReactiveFormsModule,

    //PRIMENG
    ButtonModule,
    CardModule,
    ToolbarModule,
    WebCoreModule,
  ],
  exports: [SignInComponent],
  providers: [
    {
      provide: ConfigService,
      useFactory: (options: ModuleOptions) => {
        const configService = new ConfigService();
        configService.services = options.config.services;
        configService.webApp = options.config.apps[App.WEB] ?? '';
        configService.apps = options.config.apps;
        return configService;
      },
      deps: [AUTH_MOD_OPTIONS],
    },
  ],
})
export class AuthModule {
  static forRoot(moduleOptions: ModuleOptions): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_MOD_OPTIONS,
          useValue: moduleOptions,
        },
      ],
    };
  }
}
