<div class="kfd-container p-fluid">
  <div *ngIf="preview && hidePreviewFlag === false" class="preview">Preview</div>
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'loading'">
      <div class="lds-dual-ring"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <div class="error-message">
        <div class="container">
          <div class="title">Formular kann nicht angezeigt werden</div>
          <div *ngIf="cfgError" class="message">
            <span data-automationId="error-code">Code {{ cfgError.code }}: </span>
            <span>{{ cfgError.msg }}</span>
          </div>
          <div class="subline">Bitte wenden Sie sich an den Seitenbetreiber.</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'ready'">
      <ng-container [ngTemplateOutlet]="tplForm"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #tplForm>
    <kfd-cfg-layout-page [ngClass]="{ seamless: _seamless === true }"></kfd-cfg-layout-page>
  </ng-template>
</div>
