import { Component, Input, OnInit } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { ICON, WebPage } from '@kfd/core';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'kfd-docu-btn',
  templateUrl: './docu-btn.component.html',
  styleUrls: ['./docu-btn.component.scss'],
})
export class DocuBtnComponent implements OnInit {
  @Input()
  pageId = '';

  @Input()
  sectionId = '';

  webApp = '';
  webPage$: Observable<WebPage> | undefined;
  icon = ICON;

  constructor(
    private contentService: ContentService,
    configService: ConfigService,
  ) {
    this.webApp = configService.webApp;
  }

  ngOnInit(): void {
    if (this.pageId) {
      this.webPage$ = this.contentService.getDocuPage(this.pageId).pipe(
        catchError(() => {
          return of({
            title: 'Ups',
            content: [
              {
                id: this.sectionId,
                type: 'html',
                data: `Den Inhalt konnten wir leider nicht finden.<br><br>
                     <a href="${this.webApp}/support/documentation" target="_blank">
                       > Zur Dokumentation
                     </a>`,
              },
            ],
          } as WebPage);
        }),
      );
    }
  }
}
