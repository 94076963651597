import { Injectable } from '@angular/core';
import { filter, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

interface Message {
  type: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventCallback = (payload: any) => void;

@Injectable()
export class EventService {
  private handler = new Subject<Message>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  broadcast(type: string | number, payload?: any) {
    this.handler.next({ type, payload });
  }

  subscribe(type: string | number, callback: EventCallback): Subscription {
    return this.handler
      .pipe(
        filter((message: Message) => message.type === type),
        map((message: Message) => message.payload),
      )
      .subscribe(callback);
  }
}
