export type UnvTimeoutValue = number | NodeJS.Timeout;

export function unvTimeout(cb: CallableFunction, time = 0): UnvTimeoutValue {
  if (typeof window === 'undefined') {
    return setTimeout(cb, time);
  } else {
    return window.setTimeout(cb, time);
  }
}

export function clearUnvTimeout(timeout: UnvTimeoutValue): void {
  if (typeof window === 'undefined') {
    clearTimeout(timeout as NodeJS.Timeout);
  } else {
    window.clearTimeout(timeout as number);
  }
}
