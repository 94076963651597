import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByList',
})
@Injectable()
export class FilterByListPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(items: any[], filterList: any[], itemsKey?: string, filterKey?: string): any[] {
    if (!items) {
      return [];
    }
    if (filterList === undefined || filterList.length === 0) {
      return items;
    }
    const filterListString = filterKey ? filterList.map((item) => item[filterKey]) : filterList;
    return items.filter((item) => {
      return !filterListString.includes(itemsKey ? item[itemsKey] : item);
    });
  }
}
