import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  ConfigurationValidator,
  Create,
  DATA_VALUE_TYPE,
  EmptyFormValue,
  Is,
  SingleFormValue,
  SUBMISSION_ERROR_CODES,
  SubmitButtonFieldConfig,
  ValidationMap,
  ValidationResult,
} from '@kfd/core';
import { PaginationService } from '../../service/PaginationService';
import { ConfigurationValidationService } from '../../service/configuration-validation.service';
import { ConfigurationStateService } from '../../service/configuration-state.service';
import { ConfigurationService } from '../../service/configuration.service';
import { DATA_PROVIDER, DataProvider } from '../../service/data-provider';
import { CfgContextService } from '../../service/cfg-context.service';
import { ConfigService } from '../../service/config.service';
import { EventService } from '../../service/event.service';
import { CFG_ICON } from '../../../globals';

export const EVENT_SUBMITTED = 'submitted';

@Component({
  selector: 'kfd-cfg-field-submit-btn',
  templateUrl: './cfg-field-submit-btn.component.html',
  styleUrls: ['./cfg-field-submit-btn.component.scss'],
})
export class CfgFieldSubmitBtnComponent {
  @Output()
  public valueChange: EventEmitter<SingleFormValue | EmptyFormValue> = new EventEmitter();

  @Input()
  public demo = false;

  protected validationResults: ValidationMap = new Map<string, ValidationResult>();
  protected loading = false;
  protected submitted: boolean | undefined;
  protected error = '';
  protected requestCode: string | undefined;
  protected requestLink = '';
  protected privacyStmt = false;
  protected privacyHint = false;
  protected privacyLink: string | undefined;
  protected readonly CFG_ICON = CFG_ICON;

  constructor(
    private ctx: CfgContextService,
    private paginationService: PaginationService,
    private configurationValidationService: ConfigurationValidationService,
    private configurationService: ConfigurationService,
    private configurationStateService: ConfigurationStateService,
    private eventService: EventService,
    protected configService: ConfigService,
    @Inject(DATA_PROVIDER) private _dataProvider: DataProvider,
  ) {
    this.privacyLink = this.configService.privacyLink;
  }

  private _field: SubmitButtonFieldConfig | undefined;

  get field(): SubmitButtonFieldConfig | undefined {
    return this._field;
  }

  @Input()
  set field(value: SubmitButtonFieldConfig | undefined) {
    if (!value) {
      return;
    }
    if (!value.btnLabel) {
      value.btnLabel = 'Absenden';
    }
    this._field = value;
  }

  refresh() {
    window.location.reload();
  }

  sendData() {
    this.validationResults = this.configurationValidationService.validateAll();
    if (ConfigurationValidator.errorCount(this.validationResults) > 0) {
      return;
    }

    if (!this.privacyStmt) {
      this.privacyHint = true;
      return;
    }
    this.privacyHint = false;

    const fieldNames = this.configurationService.cfgUtil.getFieldNames();
    const valueMap = this.configurationStateService.getValueMap(fieldNames);

    this.loading = true;
    this._dataProvider.submitData(this.ctx.requestId, valueMap).subscribe({
      next: (response) => {
        this.loading = false;

        if (Is.successfulRequestSubmission(response)) {
          this.submitted = true;
          this.requestCode = response.requestCode;
          if (response.link) {
            this.requestLink = response.link;
            if (response.customerCode) {
              this.requestLink += '/' + response.customerCode;
            }
          }

          this.valueChange.emit(Create.singleFormValue(Create.inputValue(DATA_VALUE_TYPE.STRING, this.requestCode)));
          this.eventService.broadcast(EVENT_SUBMITTED, this.requestCode);

          if (this._field?.resetOnSubmit) {
            this.configurationStateService.removeAllValues(true);
          }

          if (this._field?.goToPage) {
            this.paginationService.goToPage(this._field.goToPage.name);
          }
        } else {
          this.submitted = false;
          switch (response.code) {
            case SUBMISSION_ERROR_CODES.INVALID_ID:
              this.error = 'Das Formular ist nicht mehr gültig, bitte laden Sie neu. Es gehen keine Daten verloren';
              break;
            case SUBMISSION_ERROR_CODES.VALIDATION_ERROR:
              this.error = 'Die Übermittelten Werte enthalten Fehler';
              break;
            default:
              this.error = 'Leider ist ein unerwarteter Fehler aufgetreten';
          }
        }
      },
      error: (response) => {
        this.submitted = false;
        this.error = response.error.message ? response.error.message : 'Ups, an unknown error occurred.';
        this.loading = false;
      },
    });
  }
}
