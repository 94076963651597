import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CACHE_M,
  cfgServiceEP,
  FormValue,
  FormValueMap,
  RequestForm,
  RequestSubmissionResponse,
  SelectionData,
} from '@kfd/core';
import { DataFilterOptions, DataProvider } from '@kfd/cfg-core';
import { FeCache } from '../common';
import { WebEndpointService } from './web-endpoint.service';

@Injectable()
export class ConfigurationDataService implements DataProvider {
  private cache = new FeCache();

  constructor(private readonly webEndpointService: WebEndpointService) {}

  private _preview = false;

  get preview(): boolean {
    return this._preview;
  }

  set preview(value: boolean) {
    this._preview = value;
  }

  private _embedded = false;

  get embedded(): boolean {
    return this._embedded;
  }

  set embedded(value: boolean) {
    this._embedded = value;
  }

  getProjectInfo(projectId: string): Observable<typeof cfgServiceEP.project.info.response> {
    const key = 'projectinfo-' + projectId;

    const obs = this.webEndpointService.get(cfgServiceEP.project.info, [projectId], {
      noErrorHandling: true,
      withCredentials: !this._embedded,
    });

    return this.cache.cacheObs(obs, key, CACHE_M);
  }

  getForm(projectId: string, configurationId: string): Observable<RequestForm> {
    return this.webEndpointService.get(cfgServiceEP.project.cfgRequestForm, [projectId, configurationId], {
      noErrorHandling: true,
      withCredentials: !this._embedded,
      params: {
        preview: this.preview,
      },
    });
  }

  getData(
    projectId: string,
    configurationId: string,
    dataFilterOptions: DataFilterOptions,
  ): Observable<SelectionData[]> {
    const params: Record<string, string | boolean> = {
      preview: this.preview,
      templateName: dataFilterOptions.templateName ?? '',
      tags: dataFilterOptions.tags ? dataFilterOptions.tags.map((t) => t).join('+') : '',
      sort: dataFilterOptions.sort ? dataFilterOptions.sort.map((s) => `${s.field}:${s.direction}`).join('+') : '',
    };
    const key = 'data-by-filter-' + projectId + JSON.stringify(params);
    const obs = this.webEndpointService.get(cfgServiceEP.published.dataByFilter, [projectId, configurationId], {
      withCredentials: !this._embedded,
      params,
    });
    return this.cache.cacheObs(obs, key, CACHE_M);
  }

  getDataByIdentifier(projectId: string, configurationId: string, identifier: string[]): Observable<SelectionData[]> {
    const identifierQueryParam = identifier.map((t) => t).join('+');
    const key = 'data-by-identifier-' + projectId + identifierQueryParam;
    const obs = this.webEndpointService.get(
      cfgServiceEP.published.dataByKey,
      [projectId, configurationId, identifierQueryParam],
      {
        withCredentials: !this._embedded,
        params: {
          preview: this.preview,
        },
      },
    );
    return this.cache.cacheObs(obs, key, CACHE_M);
  }

  submitData(requestId: string, valueMap: FormValueMap): Observable<RequestSubmissionResponse> {
    const convertedMap: Record<string, FormValue> = {};
    valueMap.forEach((formValue, key) => {
      convertedMap[key] = formValue;
    });
    return this.webEndpointService.post(cfgServiceEP.request.submit, [requestId], convertedMap, {
      withCredentials: !this._embedded,
      params: {
        preview: this.preview,
      },
    });
  }
}
