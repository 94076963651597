<textarea
  #textAreaElement
  (ngModelChange)="onModelChange($event)"
  [cols]="30"
  [disabled]="disabled"
  [maxLength]="maxLength"
  [minLength]="minLength"
  [ngModel]="value"
  [rows]="minLines"
  pInputTextarea
></textarea>
