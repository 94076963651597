import {
  BooleanFieldConfig,
  Calculation,
  Configuration,
  DateFieldConfig,
  DisplayDataFieldConfig,
  Field,
  FIELD_TYPES,
  FormValueMap,
  Is,
  LOCAL_CODE_CONFIG,
  LocalCodes,
  LocalConfig,
  NumericFieldConfig,
  PrintValue,
  SingleFormValue,
  USERDATA_FIELD_TYPES,
} from '../dtos/index';
import { ConditionValidator } from './condition';
import dayjs from 'dayjs';
import { CalculationResolver, Calculator } from './calculation';
import { ConfigurationFieldRefResolver } from './configuration-field-ref.resolver';
import { FormValueUtil } from './form-value.util';
import { CfgUtil } from './cfg-util';

/**
 * responsible for all tasks which requires the combination of configuration and values
 */
export class ConfigurationValueHandler {
  private cfgUtil: CfgUtil;
  private relevantFields: string[] = [];
  private formValueMap: FormValueMap | undefined;
  private localCode: LocalCodes = LocalCodes.DE_DE;
  private localConfig: LocalConfig = LOCAL_CODE_CONFIG[LocalCodes.DE_DE];

  constructor(cfgUtil: CfgUtil<Configuration>, formValueMap?: FormValueMap) {
    this.cfgUtil = cfgUtil;
    if (formValueMap) {
      this.setValueMap(formValueMap);
    }
  }

  set l10n(value: LocalCodes) {
    if (Object.values(LocalCodes).indexOf(value) === -1) {
      throw new Error(`Invalid local code "${value}"`);
    }
    this.localCode = value;
    this.localConfig = LOCAL_CODE_CONFIG[value];
  }

  public setCfgUtil(cfgUtil: CfgUtil): void {
    this.cfgUtil = cfgUtil;
  }

  public setValueMap(formValueMap: FormValueMap): void {
    this.formValueMap = formValueMap;

    this.relevantFields = [];
    const fields = this.cfgUtil.getFields();
    for (const field of fields) {
      if (field.config.type !== FIELD_TYPES.DISPLAY_DATA && USERDATA_FIELD_TYPES.indexOf(field.config.type) === -1) {
        continue;
      }
      // const conditions = this.configurationHandler.getEntryCondition(field.name);

      const conditions = this.cfgUtil.getEntryConditions(field.name);
      const isRelevant = ConditionValidator.evaluateGroups(conditions, this.formValueMap);
      if (isRelevant) {
        this.relevantFields.push(field.name);
      }
    }
  }

  public getValueMap(): FormValueMap {
    if (!this.formValueMap) {
      throw new Error('formValueMap not initialized');
    }
    return this.formValueMap;
  }

  /**
   * returns the print value for a specific field
   */
  public async fieldValue(fieldName: string): Promise<PrintValue> {
    const field = this.cfgUtil.getFieldByName(fieldName, true);
    return {
      key: field.name,
      label: field.label ? field.label : field.name,
      value: await this.getFieldValue(field),
    };
  }

  public async printValueList(skipFieldsWithoutValue = false, placeholderValue?: string): Promise<PrintValue[]> {
    const printValueList: PrintValue[] = [];
    for (const fieldName of this.relevantFields) {
      const field = this.cfgUtil.getFieldByName(fieldName, true);
      const valueAvailable = this.getValueMap().has(field.name);
      if (!valueAvailable && skipFieldsWithoutValue) {
        continue;
      }
      const fieldValue = await this.getFieldValue(field);
      printValueList.push({
        key: field.name,
        label: field.label ? field.label : field.name,
        value: !valueAvailable && placeholderValue ? placeholderValue : fieldValue,
        style: !valueAvailable && placeholderValue ? 'disabled' : undefined,
      });
    }
    return printValueList;
  }

  protected async getFieldValue(field: Field): Promise<string> {
    if (Is.displayDataField(field)) {
      return this.getReadOnlyFieldValue(field);
    }
    if (Is.dateField(field)) {
      return this.getDateFieldValue(field);
    }
    if (Is.numberField(field)) {
      return this.getNumericFieldValue(field);
    }
    if (Is.boolField(field)) {
      return this.getBooleanFieldValue(field);
    }
    return FormValueUtil.toString(this.getValueMap().get(field.name));
  }

  protected getDateFieldValue(field: Field<DateFieldConfig>): string {
    const date = dayjs(FormValueUtil.toString(this.getValueMap().get(field.name)));
    if (!date.isValid()) {
      return '';
    }
    return date.format(this.localConfig.dateFormat);
  }

  protected getNumericFieldValue(field: Field<NumericFieldConfig>): string {
    const value = this.getValueMap().get(field.name);
    if (value === undefined) {
      return '';
    }
    const numberValue = FormValueUtil.toNumber(value);
    if (numberValue === undefined) {
      return '';
    }
    const unit = field.config?.unit ? field.config?.unit?.toString() : '';
    return this.formatNumber(numberValue, field.config.digits) + unit ?? '';
  }

  protected async getReadOnlyFieldValue(field: Field<DisplayDataFieldConfig>): Promise<string> {
    if (field.config.value === undefined) {
      return '';
    }
    if (Is.calculation(field.config.value)) {
      return await this.getCalculationResult(field.config.value);
    }
    if (Is.fieldRef(field.config.value)) {
      return FormValueUtil.toString(this.getValueMap().get(field.config.value.name), field.config.value.property);
    }
    if (Is.inputValue(field.config.value)) {
      return '' + field.config.value;
    }
    throw new Error(`Unsupported value type "${JSON.stringify(field.config.value)}"`);
  }

  protected async getCalculationResult(calculation: Calculation): Promise<string> {
    const fieldRefResolver = new ConfigurationFieldRefResolver(this.cfgUtil, this.getValueMap());
    const calculationResolver = new CalculationResolver(fieldRefResolver);
    return await calculationResolver.resolve(calculation, this.relevantFields).then((valueCalculation) => {
      const calculator = new Calculator();
      const result = calculator.calc(valueCalculation);

      if (result.value === undefined) {
        return '';
      }

      return valueCalculation.label
        ? `${valueCalculation.label.text} ${result.value} ${valueCalculation.label.format}`
        : '' + result.value;
    });
  }

  protected getBooleanFieldValue(field: Field<BooleanFieldConfig>): string {
    const value = this.getValueMap().get(field.name) as SingleFormValue;
    if (!value?.input) {
      return '';
    }
    if (field.config.labels) {
      if (value.input.value === true) {
        return field.config.labels.yes;
      } else {
        return field.config.labels.no;
      }
    }
    if (value.input.value === true) {
      return 'Ja';
    } else {
      return 'Nein';
    }
  }

  protected formatNumber(number: number, fractionDigits = 0): string {
    return (
      number
        // set decimal digits
        .toFixed(fractionDigits)
        // replace decimal point character
        .replace('.', this.localConfig.decimalSeparator)
        // add a thousand separator
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${this.localConfig.thousandSeparator}`)
    );
  }
}
