const specialCharReplacement: Record<string, string> = {
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
  ß: 'ss',
  ' ': '_',
};

export class StringUtil {
  public static toSaveString(input: string, maxLength = 50): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    input = input.trim().toLocaleLowerCase();
    input = StringUtil.replaceSpecial(input);
    input = input.replace(/[^a-z0-9_-]/g, '');
    return input.replace(/__/g, '_').substring(0, maxLength);
  }

  public static toReadableString(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    input = StringUtil.revertSpecial(input.trim());
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  public static toDomainName(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }

    input = input.trim().toLocaleLowerCase();
    input = StringUtil.replaceSpecial(input);
    input = input.replace(/^https?:\/\/(.*)$/, '$1');
    input = input.replace(/[^a-z0-9.-]/g, '');
    return input;
  }

  public static toValidUrl(input: string, protocol = 'https://'): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }

    input = input.trim().toLocaleLowerCase();
    input = protocol + input.replace(/^(https?:\/\/)?(.*?)$/, '$2');
    return input;
  }

  public static toEmail(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }

    input = input.trim().toLocaleLowerCase();
    input = StringUtil.replaceSpecial(input);
    input = input.replace(/[^a-z0-9!#$%&'*+-/=?^_`{|}~.@]/g, '');
    return input;
  }

  public static singleLineString(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    return input.replace(/[\r\n]/g, ' ');
  }

  public static secureString(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    return input.replace(/[{}:<>$]/g, '');
  }

  public static replaceSpecial(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    input = input.toLowerCase();
    for (const key in specialCharReplacement) {
      input = input.replace(new RegExp(key, 'g'), specialCharReplacement[key]);
    }
    return input;
  }

  public static revertSpecial(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    for (const key in specialCharReplacement) {
      input = input.replace(new RegExp(specialCharReplacement[key], 'g'), key);
    }
    return input;
  }

  public static rand(length: number, uppercase = true): string {
    const value1 = Math.random().toString(36).substring(2);
    const value2 = Math.random().toString(36).substring(2);
    const result = (value1 + value2).substring(0, length);
    return uppercase ? result.toUpperCase() : result.toLowerCase();
  }

  public static shorten(input: string, length: number, entropy = 1): string {
    let res = '';
    input = StringUtil.toSaveString(input);

    let pos = 0;
    for (let i = 0; i < length; i++) {
      pos = i === 0 ? 0 : pos + entropy;
      if (pos > input.length - 1) {
        pos = pos - input.length;
      }
      res += input.charAt(pos);
    }

    return res.toUpperCase();
  }
}
