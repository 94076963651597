<div class="m-2" data-automationId="sign-up-form">
  <h2>Registrierung</h2>

  @if (signUpSuccess) {
    <p-card class="mt-2" header="Registrierung Abgeschlossen" data-automationId="sign-up-success-message">
      <p-message class="block mt-4" severity="success" text="Die Registierung wurde erfolgreich Abgeschlossen.">
      </p-message>
      <p class="mt-2">
        Bitte prüfen Sie Ihre E-Mails um ihr Konto zu bestätigen. Anschließend können Sie direkt loslegen und sich mit
        Ihren Zugangsdaten anmelden.
      </p>
      <p-footer class="flex justify-content-end">
        <p-button
          label="Zur Aktivierung"
          data-automationId="to-activation-button"
          [routerLink]="COMMON_WEB_APP_ROUTES.user.activation.root"
          [outlined]="true"
          [rounded]="true"
        >
        </p-button>
        <p-button
          class="ml-2"
          label="Zur Anmeldung"
          data-automationId="to-sign-in-button"
          [routerLink]="COMMON_WEB_APP_ROUTES.user.login"
          [outlined]="true"
          [rounded]="true"
        >
        </p-button>
      </p-footer>
    </p-card>
  } @else {
    @if (mailExists$ | async; as mailExists) {
      <form (keyup.enter)="signUp()" (ngSubmit)="signUp()" [formGroup]="signUpForm" [kfdBlock]="loading">
        <div class="p-fluid">
          <div
            class="p-field"
            data-automationId="name-field"
            [ngClass]="{
              valid: signUpForm.get('name')?.valid,
              invalid: signUpForm.get('name')?.dirty && signUpForm.get('name')?.invalid
            }"
          >
            <label>Name*</label>
            <input data-automationId="input-name" formControlName="name" pInputText required type="text" />

            @if (signUpForm.get('name')?.invalid && signUpForm.get('name')?.dirty) {
              @if (signUpForm.get('name')?.value === '') {
                <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
              } @else {
                <small class="p-error">Bitte geben Sie einen Namen mit mindestens drei Zeichen ein.</small>
              }
            }
          </div>

          <div
            class="p-field"
            data-automationId="email-field"
            [ngClass]="{
              valid: signUpForm.get('email')?.valid && !mailExists.value,
              invalid: signUpForm.get('email')?.invalid && signUpForm.get('email')?.dirty
            }"
          >
            <label>Email*</label>
            <div class="p-input-icon-right">
              @if (signUpForm.get('email')?.valid && checkingMail) {
                <i class="pi pi-spin pi-spinner"></i>
              }
              <input data-automationId="input-email" formControlName="email" pInputText required type="email" />
            </div>
            @if (signUpForm.get('email')?.invalid && signUpForm.get('email')?.dirty) {
              @if (signUpForm.get('email')?.value === '') {
                <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
              } @else {
                <small class="p-error">Bitte geben Sie eine valide E-Mail Adresse ein.</small>
              }
            }
            @if (mailExists.value) {
              <p-message class="mt-1" severity="warn" text="Die E-Mail Adresse wurde bereits registriert"></p-message>
            }
          </div>

          <div
            data-automationId="password-field"
            class="p-field grid"
            [ngClass]="{
              valid: signUpForm.get('password2')?.valid && !this.signUpForm.getError('PasswordNoMatch'),
              invalid: signUpForm.get('password2')?.dirty && this.signUpForm.getError('PasswordNoMatch')
            }"
          >
            <div class="col-12 md:col-6">
              <label>Passwort*</label>
              <input
                data-automationId="input-password1"
                formControlName="password1"
                pInputText
                required
                type="password"
              />
              @if (signUpForm.get('password1')?.invalid && signUpForm.get('password1')?.dirty) {
                @if (signUpForm.get('password1')?.value === '') {
                  <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
                } @else {
                  <small class="p-error">Die Passwortlänge muss mindestens 8 Zeichen betragen.</small>
                }
              }
            </div>
            <div class="col-12 md:col-6">
              <label>Bestätigung*</label>
              <input
                data-automationId="input-password2"
                formControlName="password2"
                pInputText
                required
                type="password"
              />
              @if (signUpForm.get('password2')?.dirty) {
                @if (this.signUpForm.getError('PasswordNoMatch')) {
                  <small class="p-error">Die Passwörter stimmen nicht überein.</small>
                }
              }
            </div>
          </div>

          <div
            class="p-field"
            data-automationId="privacy-accepted-field"
            [ngClass]="{
              valid: signUpForm.get('privacyAccepted')?.valid,
              invalid: signUpForm.get('privacyAccepted')?.invalid && signUpForm.get('privacyAccepted')?.dirty
            }"
          >
            <div>
              <p-checkbox
                formControlName="privacyAccepted"
                [binary]="true"
                data-automationId="input-privacy-accepted"
                id="privacyAccepted"
              ></p-checkbox>
              <label for="privacyAccepted">
                Ich habe die
                <a class="color-primary" [href]="privacyLink" target="_blank">
                  <i class="pi pi-link"></i> Datenschutzerklärung
                </a>
                gelesen und bin mit der Verarbeitung meiner Daten einverstanden.
              </label>
            </div>
            @if (signUpForm.get('privacyAccepted')?.invalid && signUpForm.get('privacyAccepted')?.dirty) {
              <small class="p-error">Die Datenschutzerklärung wurde noch nicht akzeptiert.</small>
            }
          </div>

          @if (signUpError) {
            <p-message
              class="mt-2"
              severity="error"
              text="Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
            >
            </p-message>
          }

          @if (signUpForm.dirty && signUpForm.invalid) {
            <p-message
              class="mt-4"
              severity="warn"
              data-automationId="form-invalid-message"
              text="Bitte überprüfen Sie Ihre Eingaben"
            >
            </p-message>
          }

          <p-button
            class="block mt-4"
            data-automationId="submit-sign-up-form"
            label="Konto anlegen"
            [loading]="loading"
            [disabled]="!!mailExists.value"
            (click)="signUp()"
          ></p-button>
        </div>
      </form>
    }
  }
</div>
