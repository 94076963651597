import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  CLS,
  Create,
  DATA_TYPES,
  DataRef,
  EmptyFormValue,
  Is,
  ListUtil,
  MultiSelectFieldConfig,
  MultiSelectionFormValue,
  ObjectUtil,
  SELECT_FIELD_LAYOUTS,
  SelectFieldConfig,
  SelectionData,
  SingleSelectionFormValue,
} from '@kfd/core';
import { DATA_PROVIDER, DataProvider } from '../../service/data-provider';
import { lastValueFrom, Observable, of, toArray } from 'rxjs';
import { CfgContextService } from '../../service/cfg-context.service';
import { ConfigService } from '../../service/config.service';
import { OverlayOptions } from 'primeng/api';

@Component({
  selector: 'kfd-configurator-field-select',
  templateUrl: './configurator-field-select.component.html',
  styleUrls: ['./configurator-field-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorFieldSelectComponent {
  @Output()
  public valueChange: EventEmitter<MultiSelectionFormValue | SingleSelectionFormValue | EmptyFormValue> =
    new EventEmitter();
  protected selection: string[] = [];
  protected layouts = SELECT_FIELD_LAYOUTS;
  protected selectionDataList: SelectionData[] = [];
  protected singleSelection = true;
  protected loading = false;
  protected readonly overlayOptions: OverlayOptions = {
    baseZIndex: 1000,
    autoZIndex: true,
    hideOnEscape: true,
    //todo switch to modal on mobile devices
    // mode: 'modal',
    appendTo: !this.configService.embedded ? 'body' : undefined,
  };
  protected readonly toArray = toArray;
  protected emptyName = '--empty--';
  private _fieldConfig: SelectFieldConfig | MultiSelectFieldConfig | undefined;

  constructor(
    private ref: ChangeDetectorRef,
    private ctx: CfgContextService,
    protected configService: ConfigService,
    @Inject(DATA_PROVIDER) private dataProvider: DataProvider,
  ) {}

  get field(): SelectFieldConfig | MultiSelectFieldConfig | undefined {
    return this._fieldConfig;
  }

  @Input()
  set field(fieldConfig: SelectFieldConfig | MultiSelectFieldConfig | undefined) {
    if (!fieldConfig) {
      return;
    }

    this._fieldConfig = ObjectUtil.clone(fieldConfig);

    this.singleSelection = !Is.multiSelectFieldConfig(fieldConfig);

    this.updateData().then(() => {
      this.loading = false;
      this.ref.markForCheck();
    });
  }

  @Input()
  public set value(selectionFormValue: MultiSelectionFormValue | SingleSelectionFormValue | undefined) {
    if (Is.singleSelectionFormValue(selectionFormValue)) {
      this.selection = selectionFormValue.selection ? [selectionFormValue.selection.name] : [];
    }
    if (Is.multiSelectionFormValue(selectionFormValue)) {
      if (selectionFormValue?.selection.length === 0) {
        this.selection = [];
        return;
      }
      this.selection = selectionFormValue.selection.map((selectionData) => selectionData.name);
    }
  }

  onSelectionChange(values: string | string[]) {
    const selectionValues = Array.isArray(values) ? values : [values];

    this.selection = selectionValues.filter((value) => value !== this.emptyName);

    if (this.selection.length === 0) {
      this.valueChange.emit(Create.emptyFormValue());
      return;
    }

    if (this.singleSelection) {
      const selectionName = this.selection[0];
      const selectionData = this.selectionDataList.find((data) => data.name === selectionName);
      if (selectionData) {
        this.valueChange.emit(Create.singleSelectionFormValue(selectionData));
      } else {
        this.valueChange.emit(Create.emptyFormValue());
      }
    } else {
      if (this.selection.length > 0) {
        const selectionValues: SelectionData[] = ListUtil.filterEmpty<SelectionData>(
          this.selection.map((selectionName) => this.selectionDataList.find((data) => data.name === selectionName)),
        );
        this.valueChange.emit(Create.multiSelectionFormValue(selectionValues));
      } else {
        this.valueChange.emit(Create.emptyFormValue());
      }
    }
  }

  async updateData() {
    if (!this.field?.dataHandler) {
      return;
    }
    this.loading = true;
    const dataHandler = this.field.dataHandler;

    if (Is.listDataHandler(dataHandler)) {
      this.selectionDataList = await lastValueFrom(this.handleSimpleListData(dataHandler.data));
      this.addNoValueSelection();
    } else if (Is.dynamicDataHandler(dataHandler)) {
      this.loading = true;
      this.selectionDataList = await lastValueFrom(
        this.dataProvider.getData(this.ctx.projectId, this.ctx.configuratorId, {
          templateName: dataHandler.templateName,
          tags: dataHandler.tags,
          sort: dataHandler.sort,
        }),
      );
      this.addNoValueSelection();
    } else {
      throw new Error('Unsupported data handler');
    }
  }

  addNoValueSelection() {
    // add empty selection option
    if (this.field?.emptySelection) {
      this.selectionDataList.unshift({
        cls: CLS.SELECTION_DATA,
        type: DATA_TYPES.SELECTION,
        name: this.emptyName,
        label: this.field?.emptySelection,
      } as SelectionData);
      if (this.selection.length === 0 && this.selectionDataList.length > 0) {
        this.selection = [];
      }
      return;
    }

    if (this.field?.required) {
      //set first option as selection
      if (this.selection.length === 0 && this.selectionDataList.length > 0) {
        this.onSelectionChange(this.selectionDataList[0].name);
      }
      return;
    }
  }

  handleSimpleListData(dataEntries: SelectionData[] | DataRef[]): Observable<SelectionData[]> {
    if (!dataEntries || dataEntries.length === 0) {
      return of([]);
    }
    //we expect that all entries has the same cls
    if (dataEntries[0].cls === CLS.SELECTION_DATA) {
      return of(dataEntries as unknown as SelectionData[]);
    }
    if (dataEntries[0].cls === CLS.DATA_REF) {
      const dataRefEntries = dataEntries as DataRef[];
      return this.dataProvider.getDataByIdentifier(
        this.ctx.projectId,
        this.ctx.configuratorId,
        dataRefEntries.map((dataRef) => dataRef.name),
      );
    }
    throw new Error(`Invalid cls found for data entries`);
  }

  protected getMin(): number {
    return Is.multiSelectFieldConfig(this._fieldConfig) ? this._fieldConfig?.min?.value ?? 1 : 1;
  }

  protected getMax(): number {
    return Is.multiSelectFieldConfig(this._fieldConfig) ? this._fieldConfig?.max?.value ?? 1 : 1;
  }
}
