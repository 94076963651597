import * as jsonpatch from 'fast-json-patch';
import { AddOperation, Operation, RemoveOperation, ReplaceOperation } from 'fast-json-patch';
import { Path } from './jsonpath';

export class JsonPatch {
  public static applyMultiple<T>(entry: T, operations: Operation[]): T {
    const entryToPatch = JSON.parse(JSON.stringify(entry));
    for (const operation of operations) {
      try {
        jsonpatch.applyOperation(entryToPatch, operation);
      } catch (e) {
        throw new Error(`Failed to apply patch operation` + JSON.stringify([entryToPatch, operation]));
      }
    }
    return entryToPatch;
  }

  public static apply<T>(entry: T, operation: Operation): T {
    return JsonPatch.applyMultiple(entry, [operation]);
  }

  public static addOp<T>(path: Path, value: T): AddOperation<T> {
    return {
      path: JsonPatch.pathToString(path),
      op: 'add',
      value,
    };
  }

  public static replaceOperation<T>(path: Path, value: T): ReplaceOperation<T> {
    return {
      path: JsonPatch.pathToString(path),
      op: 'replace',
      value,
    };
  }

  public static removeOperation(path: Path): RemoveOperation {
    return {
      path: JsonPatch.pathToString(path),
      op: 'remove',
    };
  }

  public static compare(obj1: Record<string, unknown>, obj2: Record<string, unknown>): Operation[] {
    return jsonpatch.compare(obj1, obj2);
  }

  public static pathToString(path: Path): string {
    if (!Array.isArray(path)) {
      return path;
    }
    if (path[0] === '$') {
      path.shift();
    }
    return '/' + path.join('/');
  }

  public static stringToPath(path: string): Path {
    return path.split('/').filter((part) => !!part);
  }
}
