import { Component, OnInit } from '@angular/core';
import { COMMON_WEB_APP_ROUTES, ICON, ObjectUtil, RequiredUserAction, User } from '@kfd/core';
import { Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { AuthService, MessageService } from '../../lib/services';
import { Validators } from '@angular/forms';

@Component({
  selector: 'kfd-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  user$: Observable<User | undefined> = of(undefined);
  originUser: User | undefined;
  oldPassword = '';
  password1 = '';
  password2 = '';
  validationMailSent = false;
  requiredUserAction$: Observable<RequiredUserAction | undefined> = of(undefined);
  userAction = RequiredUserAction;

  protected pendingEmailChange = false;
  protected readonly ICON = ICON;
  protected userNameValidators = [Validators.required, Validators.minLength(3), Validators.maxLength(30)];
  protected userEmailValidators = [Validators.required, Validators.email];

  constructor(
    private readonly _router: Router,
    private readonly authService: AuthService,
    private readonly _messageService: MessageService,
  ) {}

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    this.user$ = this.authService.profile().pipe(
      tap((user) => {
        this.originUser = ObjectUtil.clone(user);
      }),
    );
    this.requiredUserAction$ = this.authService.requiredUserActions();
  }

  saveName(user: User) {
    if (!user || !this.originUser) {
      return;
    }
    this.authService.changeProfile(user.name).subscribe({
      next: (success) => {
        if (success) {
          this._messageService.showSuccess('Der Name wurde gespeichert');
          if (this.originUser && user) {
            this.originUser.name = user.name;
          }
        } else {
          this._messageService.showError('Der Name konnte nicht geändert werden');
        }
      },
      error: () => {
        this._messageService.showError('Ups, leider ist ein Fehler aufgetreten.');
      },
    });
  }

  saveEmail(user: User) {
    if (!user || !this.originUser) {
      return;
    }
    this.pendingEmailChange = true;
    this.authService.changeEmail(user.email).subscribe((success) => {
      if (success) {
        this._messageService.showSuccess(
          'Die E-Mail Adresse wurde gespeichert',
          'Bitte aktivieren sie die neue Adresse mit dem Code in der Aktivierungsmail',
        );
        if (this.originUser && user) {
          this.originUser.email = user.email;
        }
        this.pendingEmailChange = false;
      } else {
        this._messageService.showError('Die E-Mail Adresse konnte nicht geändert werden');
        this.pendingEmailChange = false;
      }
    });
  }

  changePassword(user: User) {
    this.authService.changePassword(this.oldPassword, this.password1).subscribe(
      (success) => {
        if (success) {
          this._messageService.showSuccess(
            'Das neue Passwort wurde gesetzt',
            'Bitte melden sie sich jetzt mit dem neuen Passwort an',
          );
          if (user) {
            this._router.navigate([COMMON_WEB_APP_ROUTES.user.login, { email: user.email }]);
          }
        } else {
          this._messageService.showError('Das Passwort konnte nicht geändert werden');
        }
      },
      (response) => {
        this._messageService.showError('Ups', response?.error?.message);
      },
    );
  }

  sendValidationMail() {
    this.authService.sendValidationMail().subscribe({
      next: (success) => {
        if (success) {
          this.validationMailSent = true;
          this._messageService.showSuccess('Die Aktivierungsmail wurde versendet.');
        } else {
          this._messageService.showError('Ups, leider konnten wir die E-Mail nicht versenden.');
        }
      },
      error: () => {
        this._messageService.showError('Ups, leider ist ein Fehler aufgetreten.');
      },
    });
  }
}
