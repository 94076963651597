export class HttpError extends Error {}

export class UnauthorizedError extends HttpError {}

export class ForbiddenError extends HttpError {}

export class NotFoundError extends HttpError {}

export class ClientInputError extends HttpError {
  constructor(
    public override message = '',
    public code = '',
  ) {
    super();
  }
}

export class UnknownError extends HttpError {
  constructor(
    public override message = '',
    public code = '',
  ) {
    super();
  }
}
