import { Persistence } from './persistence';
import { Key } from './key';
import { FormValue } from '@kfd/core';

export class SessionPersistence implements Persistence {
  private _parentKey: Key | undefined;
  private disabledKeys: string[] = [];

  useKey(key: string): void {
    this._parentKey = new Key(key);
  }

  public get(key: Key): FormValue {
    if (this._parentKey) {
      key = key.prepend(this._parentKey);
    }
    if (this.disabledKeys.indexOf(key.toString()) >= 0) {
      throw new Error(`Key "${key.toString()}" not found`);
    }
    return JSON.parse(<string>this.storage().getItem(key.toString()));
  }

  public has(key: Key): boolean {
    if (this._parentKey) {
      key = key.prepend(this._parentKey);
    }
    if (this.disabledKeys.indexOf(key.toString()) >= 0) {
      return false;
    }
    return this.storage().getItem(key.toString()) !== null;
  }

  public set(key: Key, value: FormValue): void {
    if (this._parentKey) {
      key = key.prepend(this._parentKey);
    }
    this.storage().setItem(key.toString(), JSON.stringify(value));
  }

  public enable(key: Key): void {
    if (this._parentKey) {
      key = key.prepend(this._parentKey);
    }
    if (this.disabledKeys.indexOf(key.toString()) >= 0) {
      this.disabledKeys.splice(this.disabledKeys.indexOf(key.toString()), 1);
      // console.log(this.disabledKeys);
    }
  }

  public disable(key: Key): void {
    if (this._parentKey) {
      key = key.prepend(this._parentKey);
    }
    if (this.disabledKeys.indexOf(key.toString()) === -1) {
      this.disabledKeys.push(key.toString());
    }
    // console.log(this.disabledKeys);
  }

  public list(): Key[] {
    const keys: Key[] = [];

    for (let i = 0; i < this.storage().length; i++) {
      const currentKey = Key.fromString(this.storage().key(i));
      if (this._parentKey && currentKey.match(this._parentKey)) {
        keys.push(currentKey.removeFirst());
      } else {
        keys.push(currentKey);
      }
    }
    return keys.filter((key) => this.disabledKeys.indexOf(key.toString()) === -1);
  }

  public clear(): void {
    this.storage().clear();
  }

  public static available(): boolean {
    return !!sessionStorage;
  }

  private storage(): Storage {
    if (!sessionStorage) {
      throw new Error('Session storage not available');
    }
    return sessionStorage;
  }
}
