import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { booleanAttribute, Directive, Input, NgModule } from '@angular/core';
import { DomHandler } from 'primeng/dom';

/**
 * AutoFocus manages focus on focusable element on load.
 * @group Components
 */
class AutoFocus {
  host;
  constructor(host) {
    this.host = host;
  }
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus;
  focused = false;
  ngAfterContentChecked() {
    if (!this.focused) {
      if (this.autofocus) {
        setTimeout(() => {
          const focusableElements = DomHandler.getFocusableElements(this.host.nativeElement);
          if (focusableElements.length === 0) {
            this.host.nativeElement.focus();
          }
          if (focusableElements.length > 0) {
            focusableElements[0].focus();
          }
          this.focused = true;
        });
      }
    }
  }
  static ɵfac = function AutoFocus_Factory(t) {
    return new (t || AutoFocus)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: AutoFocus,
    selectors: [["", "pAutoFocus", ""]],
    hostAttrs: [1, "p-element"],
    inputs: {
      autofocus: ["autofocus", "autofocus", booleanAttribute]
    },
    features: [i0.ɵɵInputTransformsFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocus, [{
    type: Directive,
    args: [{
      selector: '[pAutoFocus]',
      host: {
        class: 'p-element'
      }
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }]
  });
})();
class AutoFocusModule {
  static ɵfac = function AutoFocusModule_Factory(t) {
    return new (t || AutoFocusModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AutoFocusModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocusModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [AutoFocus],
      declarations: [AutoFocus]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AutoFocus, AutoFocusModule };
