import { COMMON_APP_ROUTE_PARTS } from './common-web-app-routes';

export enum CMS_APP_ROUTE_PARAMS {
  PROJECT_ID = 'projectId',
  CONFIGURATION_ID = 'configurationId',
  ENTRY_ID = 'entryId',
}

export enum CMS_APP_ROUTE_PARTS_ROOT {
  PROJECT = 'project',
}

export enum CMS_APP_ROUTE_PARTS_PROJECT {
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  BASE_DATA = 'base-data',
  BASE_DATA_DATA = 'data',
  BASE_DATA_TEMPLATES = 'templates',
  ASSETS = 'assets',
  STATISTIC = 'statistics',
  CUSTOMER_REQUESTS = 'customer-requests',
  CONFIGURATIONS = 'forms',
}

export enum CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS {
  PERSONAL = 'user-settings',
  BUSINESS_CARD = 'business-card',
  MEMBERSHIP = 'membership',
  USERS = 'users',
  PROJECT = 'project-settings',
}

const PROJECT_ROOT = `/${CMS_APP_ROUTE_PARTS_ROOT.PROJECT}/:${CMS_APP_ROUTE_PARAMS.PROJECT_ID}`;
const SETTINGS_ROOT = `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.SETTINGS}`;
const CUSTOMER_REQUESTS_ROOT = `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CUSTOMER_REQUESTS}`;
export const CMS_APP_ROUTES = {
  root: `/`,
  user: {
    profile: `${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.PROFILE}`,
  },
  project: {
    root: PROJECT_ROOT,
    dashboard: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.DASHBOARD}`,
    configurations: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}`,
      show: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}/edit/:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}`,
      edit: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}/edit/:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}?edit=true`,
      editEntry: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}/edit/:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}?edit=true`,
    },
    // settings: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.SETTINGS}`,
    settings: {
      root: SETTINGS_ROOT,
      businessCard: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.BUSINESS_CARD}`,
      users: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.USERS}`,
      project: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.PROJECT}`,
      membership: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.MEMBERSHIP}`,
    },
    statistic: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.STATISTIC}`,
    },
    customerRequests: {
      root: CUSTOMER_REQUESTS_ROOT,
      details: `${CUSTOMER_REQUESTS_ROOT}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}`,
    },
    baseData: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}`,
      data: {
        root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA_DATA}`,
        show: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA_DATA}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}`,
      },
      template: {
        root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA_TEMPLATES}`,
      },
    },
    assets: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.ASSETS}`,
      show: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.ASSETS}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}`,
    },
  },
};
