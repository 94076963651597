import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigService, ScreenSize } from '@kfd/cfg-core';
import { Observable } from 'rxjs';
import { ArrayUtil, ObjectUtil } from '@kfd/core';
import { TooltipModule } from 'primeng/tooltip';

interface SelectOption<T extends Record<string, unknown>> {
  id: string;
  label: string;
  selected: boolean;
  image?: string;
  value: T;
}

@Component({
  selector: 'kfd-web-inline-selection',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './inline-selection.component.html',
  styleUrl: './inline-selection.component.scss',
})
export class InlineSelectionComponent<T extends Record<string, unknown>> {
  @Input()
  public minSelections = 1;
  @Input()
  public maxSelections = 1;
  @Input()
  public optionId = 'id';
  @Input()
  public optionLabel = 'label';
  @Input()
  public optionValue = 'value';
  @Input()
  public optionImage = 'image';
  @Output()
  public selectionChange = new EventEmitter<string[]>();
  protected screenSize$: Observable<ScreenSize>;
  protected readonly ScreenSize = ScreenSize;
  private _selectedIds: string[] = [];

  constructor(protected readonly configService: ConfigService) {
    this.screenSize$ = this.configService.onScreenSizeChange();
  }

  private _options: SelectOption<T>[] | undefined;

  get options(): SelectOption<T>[] | undefined {
    return this._options;
  }

  @Input()
  set options(values: T[] | undefined) {
    if (!values) {
      this._options = [];
      return;
    }
    this._options = values.map((value) => {
      return {
        id: ObjectUtil.getValue(value, this.optionId) as string,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: (ObjectUtil.getValue(value, this.optionLabel) as string) ?? '',
        image: (ObjectUtil.getValue(value, this.optionImage) as string) ?? undefined,
        selected: false,
        value,
      };
    });
    this.updateSelections();
  }

  public get selection(): SelectOption<T>[] {
    return this._options?.filter((option) => option.selected) ?? [];
  }

  @Input()
  public set selection(values: string[]) {
    this._selectedIds = values;
  }

  protected selectOption(option: SelectOption<T>): void {
    //skip if max selections reached (but only for multiselect)
    if (this.maxSelections > 1 && this.selection.length >= this.maxSelections) {
      return;
    }

    if (this.maxSelections <= 1) {
      this._selectedIds = [option.id];
    } else {
      this._selectedIds = ArrayUtil.distinct([...this._selectedIds, option.id]);
    }
    this.updateSelections();

    this.emitChanges();
  }

  protected deSelectOption(option: SelectOption<T>): void {
    if (this._selectedIds.includes(option.id)) {
      this._selectedIds = this._selectedIds.filter((o) => o !== option.id);
    }
    this.updateSelections();
    this.emitChanges();
  }

  protected updateSelections(): void {
    this._options = this._options?.map((o) => {
      return {
        ...o,
        selected: this._selectedIds.includes(o.id),
      };
    });
  }

  protected emitChanges(): void {
    this.selectionChange.emit(this.selection.map((option) => option.id as string));
  }
}
