<div *ngIf="configuratorPage; else notInitialized" [attr.data-automationId]="'cfg-page-' + configuratorPage.name">
  <h3 *ngIf="!configuratorPage.hideLabel" data-automationId="cfg-page-label">{{ configuratorPage.label }}</h3>

  <kfd-configurator-info [info]="configuratorPage.info"></kfd-configurator-info>

  <ng-container *ngFor="let child of configuratorPage.children">
    <kfd-configurator-condition [name]="child.entry.name">
      <ng-template #caseTrue>
        <ng-container [ngSwitch]="child.entry.cls">
          <kfd-configurator-question
            *ngSwitchCase="cls.FIELD_GROUP"
            [question]="$any(child.entry)"
          ></kfd-configurator-question>
          <kfd-configurator-field *ngSwitchCase="cls.FIELD" [field]="$any(child.entry)"></kfd-configurator-field>
        </ng-container>
      </ng-template>
    </kfd-configurator-condition>
  </ng-container>
</div>

<ng-template #notInitialized> ---</ng-template>
